import Vue from 'vue'
import Router from "vue-router";

Vue.use(Router)

function refresh(promise) {
  return promise.catch(e => {
    window.location.reload();
  })
}


const useView = component => () => refresh(import(`@/pages/${component}.vue`));
const routes = [{
    path: '/',
    component: useView('home/index/index_page'),
    children: [{
        path: '/',
        component: useView('home/home/home_page'),
      },
      {
        path: '/authorization',
        component: useView('home/authorization'),
        meta: {
          title: 'Authorization App',
          requiresAuth: true,
        }
      },

      {
        path: '/messages',
        component: useView('home/messages_page'),
        meta: {
          title: 'Messages',
        }
      },
      {
        path: '/terms-&-conditions',
        component: useView('home/terms_conditions_page'),
        meta: {
          title: 'Terms and conditions',
        }
      },
      {
        path: '/privacy-policy',
        component: useView('home/privacy_policy_page'),
        meta: {
          title: 'Privacy policy',
        }
      },
      {
        path: '/faqs',
        component: useView('home/faqs_page'),
        meta: {
          title: "FAQ's",
        }
      },
      {
        path: '/returns-and-cancellations',
        component: useView('home/returns_page'),
        meta: {
          title: "Returns & Cancellations",
        }
      },
      {
        path: '/contact',
        component: useView('home/contact_page'),
        meta: {
          title: 'Contact',
        }
      },
      {
        path: '/about',
        component: useView('home/about_page'),
        meta: {
          title: 'About',
        }
      },
      {
        path: '/support',
        component: useView('home/support_page'),
        meta: {
          title: 'Support',
        }
      },
      {
        path: '/recovery-password',
        component: useView('home/recovery_password_email_page'),
        meta: {
          title: 'Recovery Password',
        }
      },
      {
        path: '/recovery-password/:id',
        component: useView('home/recovery_password_page'),
      },
      {
        path: '/account-confirmation/:id',
        component: useView('home/account_confirmation_page'),
      },
      {
        path: '/logout',
        component: useView('logout_page'),
        meta: {
          title: 'Logout',
        }
      },
      {
        path: '/promotions',
        component: useView('home/promotions_page'),
        meta: {
          title: "Promotions",
        }
      },
      // Error intregration
      {
        path: '/error-integration',
        component: useView('error_integration'),
        meta: {
          title: 'Error Integration',
        }
      },
    ],
  },
  {
    path: '/signup',
    component: useView('home/signup_page'),
    meta: {
      newUser: true,
      title: 'Signup',
    }
  },
  {
    path: '/login',
    component: useView('home/login/login_page'),
    meta: {
      newUser: true,
      title: 'Login',
    }
  },
  {
    path: '/session',
    component: useView('home/session_page'),
    meta: {
      newUser: true,
      title: 'Session',
    }
  },

  // Accounts
  {
    path: '/accounts',
    component: useView('accounts/index_page'),
    children: [{
        path: '/',
        name: 'accounts',
        component: useView('accounts/landing/landing_page'),
        meta: {
          title: 'Accounts',
        }
      },
      {
        path: 'login',
        name: 'accountsLogin',
        component: useView('accounts/login/login_page'),
        meta: {
          title: 'Accounts Login',
        }
      },
      {
        path: 'signup',
        name: 'accountsSignup',
        component: useView('accounts/signup/signup_page'),
        meta: {
          title: 'Accounts Signup',
        }
      },
      {
        path: 'account-confirmation/:id',
        name: 'accountsConfirmation',
        component: useView('accounts/account_confirmaiton_page'),
        meta: {
          title: 'Accounts Confirmation',
        }
      },
      {
        path: 'account-authorization',
        name: 'accountAuthorization',
        component: useView('accounts/account_authorization_page'),
        meta: {
          title: 'Accounts Confirmation',
        }
      },
      {
        path: 'phone-verification',
        name: 'phoneVerification',
        component: useView('accounts/phone_verification/phone_verification_page'),
        meta: {
          title: 'Accounts Phone Verification',
        }
      },
      {
        path: 'new-user',
        name: 'accountNewUser',
        component: useView('accounts/new_user_page'),
        meta: {
          title: 'Accounts New User',
        }
      },
      {
        path: 'help',
        name: 'accountsHelp',
        component: useView('accounts/help_page'),
        meta: {
          title: 'Accounts help',
        }
      },
      {
        path: 'privacy',
        name: 'accountsPrivacy',
        component: useView('accounts/privacy_page'),
        meta: {
          title: 'Accounts privacy',
        }
      },
      {
        path: 'conditions',
        name: 'accountsConditions',
        component: useView('accounts/conditions_page'),
        meta: {
          title: 'Accounts conditions',
        }
      },
      {
        path: 'info',
        name: 'accountsInfo',
        component: useView('accounts/info_page'),
        meta: {
          title: 'Accounts info',
        }
      },
      {
        path: 'recovery-password/:id',
        component: useView('accounts/recovery_password_page'),
      },

      {
        path: '*',
        component: useView('accounts/404'),
        meta: {
          title: 'Error',
        }
      },
    ],

  },

  //User active and complete 
  {
    path: '/dashboard',
    component: useView('dashboard/index/index_page'),
    meta: {
      requiresAuth: true,
      active: true,
      complete: false,
    },
    children: [{
        path: '/',
        name: 'default',
        component: useView('dashboard/dashboard/dashboard_page'),
        meta: {
          title: 'Dashboard',
        }
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: useView('dashboard/dashboard/dashboard_page'),
        meta: {
          title: 'Dashboard',
        }
      },
      {
        path: 'welcome',
        name: 'welcome',
        component: useView('dashboard/welcome_page'),
        meta: {
          title: 'Welcome',
        }
      },
      {
        path: 'stores',
        name: 'stores',
        component: useView('dashboard/stores/stores_page'),
        meta: {
          title: 'Stores',
        }
      },
      {
        path: 'stores/:id',
        name: 'store',
        component: useView('dashboard/store/store_page'),
        meta: {
          title: 'Store',
        }
      },
      {
        path: 'products',
        name: 'products',
        component: useView('dashboard/products/products_page'),
        meta: {
          title: 'Products',
        }
      },
      {
        path: 'products/:id',
        name: 'product',
        component: useView('dashboard/product/product_page'),
        meta: {
          title: 'Product',
        }
      },
      // New product view
      {
        path: 'new-product/:storeId',
        name: 'newProduct',
        component: useView('dashboard/product/product_page'),
        meta: {
          title: 'Product',
        }
      },
      {
        path: 'new-product-new/:storeId',
        name: 'newProductNew',
        component: useView('dashboard/product/product_page'),
        meta: {
          title: 'New product',
        }
      },
      {
        path: 'discounts',
        name: 'discounts',
        component: useView('dashboard/discounts/discounts_page'),
        meta: {
          title: 'Discounts',
        }
      },
      {
        path: 'discounts/new',
        name: 'discountNew',
        component: useView('dashboard/discount/discount_poge'),
        meta: {
          title: 'New discount',
        }
      },
      {
        path: 'discounts/:id',
        name: 'discount',
        component: useView('dashboard/discount/discount_poge'),
        meta: {
          title: 'Discount',
        }
      },
      {
        path: 'orders',
        name: 'orders',
        component: useView('dashboard/orders/orders_page'),
        meta: {
          title: 'Orders',
        }
      },
      {
        path: 'orders/:id',
        name: 'order',
        component: useView('dashboard/order/order_page'),
        meta: {
          title: 'Order',
        }
      },
      {
        path: 'pickups/:id',
        name: 'pickup',
        component: useView('dashboard/pickup_page'),
        meta: {
          title: 'Pickup',
        }
      },
      {
        path: 'pickups',
        name: 'pickups',
        component: useView('dashboard/pickups/pickups_page'),
        meta: {
          title: 'Pickups',
        }
      },
      {
        path: 'shipping',
        name: 'shipping',
        component: useView('dashboard/shipping/shipping_page'),
        meta: {
          title: 'Shipping',
        }
      },
      {
        path: 'payments',
        name: 'payments',
        component: useView('dashboard/payments/payments_page'),
        meta: {
          title: 'Payments',
        }
      },
      {
        path: 'payments/:id',
        name: 'payment',
        component: useView('dashboard/payment_page'),
        meta: {
          title: 'Payment',
        }
      },
      {
        path: 'questions',
        name: 'questions',
        component: useView('dashboard/questions/questions_page'),
        meta: {
          title: 'Questions',
        }
      },
      {
        path: 'questions/:id',
        name: 'question',
        component: useView('dashboard/question/question_page'),
        meta: {
          title: 'Products question',
        }
      },
      {
        path: 'reviews/:id',
        name: 'review',
        component: useView('dashboard/review/review_page'),
        meta: {
          title: 'Products review',
        }
      },
      {
        path: 'new-cart/:cartId',
        name: 'updateOrder',
        component: useView('dashboard/new-cart/new_cart_page'),
        meta: {
          title: 'Update cart',
        }
      },
      {
        path: 'new-cart',
        name: 'newOrder',
        component: useView('dashboard/new-cart/new_cart_page'),
        meta: {
          title: 'New cart',
        }
      },
      // {
      //   path: 'settings',
      //   name: 'settings',
      //   component: useView('dashboard/settings_page'),
      //   meta: {
      //     title: 'Settings',
      //   }
      // },
      {
        path: 'account',
        name: 'account',
        component: useView('dashboard/account/account_page'),
        meta: {
          title: 'Account',
        }
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: useView('dashboard/notifications/notifications_page'),
        meta: {
          title: 'Notifications',
        }
      },

      {
        path: 'terms-&-conditions',
        component: useView('home/terms_conditions_page'),
        meta: {
          title: 'Terms and conditions',
        }
      },
      {
        path: 'privacy-policy',
        component: useView('home/privacy_policy_page'),
        meta: {
          title: 'Privacy policy',
        }
      },
      {
        path: 'faqs',
        component: useView('home/faqs_page'),
        meta: {
          title: "FAQ's",
        }
      },
      {
        path: 'returns-and-cancellations',
        component: useView('home/returns_page'),
        meta: {
          title: "Returns & Cancellations",
        }
      },
      {
        path: 'promotions',
        component: useView('home/promotions_page'),
        meta: {
          title: "Promotions",
        }
      },
    ]
  },
  // Shpify
  {
    path: '/shopify',
    component: useView('shopify/index_page'),
    children: [{
        path: '',
        name: 'ShopifyHome',
        component: useView('shopify/home_page'),
        meta: {
          title: 'Shopify Integration',
          newUser: true,
        }
      },
      {
        path: 'account',
        name: 'ShopifyAccount',
        component: useView('shopify/new_account_page'),
        meta: {
          requiresAuth: true,
          complete: false,
          title: 'Shopify Integration',
        }
      },
      {
        path: 'new-account',
        name: 'ShopifyNewAccount',
        component: useView('shopify/new_account_page'),
        meta: {
          requiresAuth: true,
          complete: false,
          title: 'Shopify Integration',
        }
      },
      {
        path: 'settings',
        name: 'ShopifySettings',
        component: useView('shopify/settings_page'),
        meta: {
          requiresAuth: true,
          complete: false,
          title: 'Shopify Settings',
        }
      },
      {
        path: 'products',
        name: 'ShopifyProducts',
        component: useView('shopify/products/shopify_products_page'),
        meta: {
          requiresAuth: true,
          title: 'Shopify Products',
        }
      },
      {
        path: 'orders',
        name: 'ShopifyOrders',
        component: useView('shopify/orders/shopify_orders_page'),
        meta: {
          requiresAuth: true,
          title: 'Shopify Orders',
        }
      },
      {
        path: 'orders/:id',
        name: 'ShopifyOrder',
        component: useView('shopify/order/shopify_order_page'),
        meta: {
          requiresAuth: true,
          title: 'Shopify Order',
        }
      },
      // integration error 
      {
        path: 'integration-error',
        name: 'ShopifyIntegrationError',
        component: useView('shopify/shopify_integration_error_page'),
        meta: {
          title: 'Integration Error',
        }
      },
    ]
  },
  {
    path: '/web-editor/:storeId',
    component: useView('dashboard/web-editor/web_editor_page'),
    meta: {
      title: "Web editor",
    }
  },
  {
    path: '*',
    component: useView('error_404'),
    meta: {
      title: 'Error',
    }
  },

];


const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    document.getElementById("app").scrollIntoView({
      block: 'start',
      behavior: "smooth"
    });
  }
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem('token', to.query.token);
  }
  if (to.query.device) {
    localStorage.setItem('device', to.query.device);
  }

  if (to.query.device_token) {
    localStorage.setItem('device_token', to.query.device_token);
  }
  if (to.query.type == "authentication" && (to.query.next == null || to.query.next == undefined)) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return next({
      path: '/login',
      query: {
        ...to.query,
        next: true
      },
    })
  }

  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  let pathShopify = to.matched.some(record => record.path == '/shopify');

  if (pathShopify) {
    if (requiresAuth) {
      if (to.query.token && window.location.pathname != '/shopify') {
        localStorage.setItem('tokenShopify', to.query.token);
      }
      if (localStorage.getItem('tokenShopify') == null) {
        next({
          path: `/shopify`,
          query: to.query,
        })
      } else {
        if (!hasQueryParams(to) && hasQueryParams(from)) {
          next({
            path: to.path,
            query: from.query
          });
        } else {
          next()
        }
      }
    } else {
      if (to.matched.some(record => record.meta.newUser)) {
        if (localStorage.getItem('tokenShopify') == null) {
          if (!hasQueryParams(to) && hasQueryParams(from)) {
            next({
              path: to.path,
              query: from.query
            });
          } else {
            next()
          }
        } else {
          next({
            path: '/shopify/products',
            query: to.query,
          })
        }
      } else {
        if ((hasQueryParams(to) || hasQueryParams(from)) && !to.query.scope) {
          next({
            path: to.path,
            query: {
              ...from.query,
              ...to.query,
              scope: true
            }
          });
        } else {
          next()
        };
      }
    }

  } else {
    if (requiresAuth) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (localStorage.getItem('token') == null) {
        if (to.query.integration && to.query.token != null) {
          localStorage.setItem('token', to.query.token);
          next();
        } else {
          // TODO: Remove this line if is necessary => next({ path: '/login' });
          next({ path: '/' });
        }
      } else {
        if (user && user.complete == false) {
          if (to.query.integration) {
            next();
          } else {
            next({
              path: '/dashboard/account',
              query: {
                integration: true
              },
            })
          }
        } else {
          next()
        }
      }
    } else {
      next()
    }
    if (to.matched.some(record => record.meta.newUser)) {
      if (localStorage.getItem('token') == null) {
        next()
      } else {
        next({
          path: '/dashboard'
        })
      }
    }
  }

})


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = 'Ecart seller | ' + nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router