import Vue from 'vue';
import i18n from '@/i18n';

const state = {
  questionsPagination: {
    productId: null,
    answered: false,
    page: 1,
    limit: 10,
    total: null,
  },
  loading: {
    data: false,
  },
  questionsList: [],
  tempIndex: null,
}
// getters
const getters = {}
// mutations
const mutations = {

  SOCKET_products(state, data) {
    if (data.event_type == 'question' && (state.questionsPagination.answered == false || state.questionsPagination.answered == null)) {
      data.question.product = null;
      if (state.questionsPagination.productId == null) {
        state.questionsList.unshift(data.question);
      } else if (state.questionsPagination.productId == data.reference_id) {
        state.questionsList.unshift(data.question);
      } else {}
    }
  },

  setQuestionsList(state, questionsList) {
    state.questionsList = questionsList;
  },
  removeQuestion(state, questionId) {
    state.questionsList.splice(state.questionsList.findIndex(question => question.id == questionId), 1);
  },
  setTempQuestionId(state, questionId) {
    state.tempIndex = questionId;
  },
  setProductId(state, productId) {
    state.questionsPagination.productId = productId;
  },
  resetPagination(state) {
    state.questionsPagination.productId = null;
    state.questionsPagination.answered = false;
    state.questionsPagination.page = 1;
    state.questionsPagination.total = null;
  },
  firstPagination(state) {
    state.questionsPagination.page = 1;
    state.questionsPagination.total = null;
  },
  setTotal(state, total) {
    state.questionsPagination.total = total;
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
};

// actions
const actions = {
  async fnFirstPagination(context) {
    context.commit('firstPagination');
    context.commit('setQuestionsList', []);
    context.dispatch('fnApiGetQuestions');
    context.dispatch('fnApiGetCountQuestions');
  },
  async fnResetPagination(context, productId) {
    context.commit('resetPagination');
    productId != null ? context.commit('setProductId', productId) : null;
    context.dispatch('fnApiGetQuestions');
    context.dispatch('fnApiGetCountQuestions');
  },
  async fnApiGetQuestions(context) {
    let {
      productId,
      answered,
      limit,
      page
    } = {
      ...context.state.questionsPagination
    };
    let tempPagination = {
      page,
      limit
    };
    (productId != null && productId != '') ? tempPagination.product_id = productId: null;
    (answered !== null && answered !== '') ? tempPagination.answered = answered: null;
    context.commit('changeLoading', {
      data: true
    });
    context.commit('setQuestionsList', []);
    await axios.get(`products/questions`, {
      params: tempPagination
    }).then((response) => {
      response.data.map(question => {
        question.tempAnswer = null;
        question.product = null;
      })
      context.commit('setQuestionsList', response.data);
      setTimeout(() => {
        document.getElementById("app").scrollIntoView({
          block: 'start',
          behavior: "smooth"
        });
      }, 500);
    }).catch((error) => {});

    context.commit('changeLoading', {
      data: false
    });
  },
  fnApiGetCountQuestions(context) {
    let {
      productId,
      answered,
    } = {
      ...context.state.questionsPagination
    };
    let tempPagination = {};
    (productId != null && productId != '') ? tempPagination.product_id = productId: null;
    (answered !== null && answered !== '') ? tempPagination.answered = answered: null;
    axios.get('products/questions/count', {
      params: tempPagination
    }).then((response) => {
      context.commit('setTotal', response.data.count);
    }).catch((error) => {});
  },
  async fnApiAnswer(context, questionId) {
    context.commit('setTempQuestionId', questionId);
    let tempQuestion = context.state.questionsList.find(question => question.id == questionId);
    await axios.put(`products/questions/${tempQuestion.id}/answer`, {
      answer: tempQuestion.tempAnswer,
    }).then((response) => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.informationUpdated'));
      context.commit('setTotal', context.state.questionsPagination.total - 1);
      context.commit('removeQuestion', questionId);

    }).catch((error) => {});
    context.commit('setTempQuestionId', null);
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}