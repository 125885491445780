import Vue from 'vue';
import router from "@/router";
import i18n from '@/i18n';

import {
    EcProductModel,
    EcVariantModel,
    EcStoreModel
} from "@/plugins/beans.js"

const defaultState = () => {
    return {
        productInformation: EcProductModel(),
        newTranslation: EcProductModel(),
        storeInformation: EcStoreModel(),
        newVariant: EcVariantModel(),
        newDiscount: {
            id: null,
            percent: null,
            is_amount: false,
            from: null,
            to: null,
            unlimit: false,
            variant_ids: [],
        },
        productInformationTranslations: [],
        availableLanguages: [],
        productError: false,
        modalSellOutOfStock: false,
        createVariants: false,
        loading: {
            product: false,
            newImage: false,
            deleteImage: null,
            sync: false,
            data: true,
            update: false,
            variants: false,
            storeData: false,
            deleteDiscount: null,
            defaultDiscount: null,
        },
        productLanguage: null,
        questionsList: {
            questions: null,
            total: null,
        },
        modals: {
            questions: false,
            reviews: false,
            newVariant: false,
            images: false,
            dimensions: false,
            translation: false,
            discounts: false,
            category: false,
        },
        newProduct: null,
        optionsSelectedList: [],
        newVariants: [],
        newOptions: [],
        categoriesList: [],
        optionsNameList: [{
            name: 'color',
            index: null,
        }, {
            name: 'size',
            index: null,
        }, {
            name: 'material',
            index: null,
        }, {
            name: 'style',
            index: null,
        }],
        variantId: null,
        attributesList: [],
    }
}

const state = defaultState();

const getters = {
    getVariantsList(state) {
        return state.productInformation.variants;
    },
    getAvailableLanguages(state) {
        let tempLanguages = [...state.availableLanguages];
        state.productInformationTranslations.map(translation => {
            let tempIndex = tempLanguages.findIndex(language => (language.language == translation.language));
            if (tempIndex >= 0) {
                tempLanguages.splice(tempIndex, 1);
            }
        });
        return tempLanguages;
    },

    getProductDiscount(state) {
        if (state.productInformation.discounts.length > 0) {
            return state.productInformation.discounts.find(desc => desc.active);
        } else {
            null
        }
    }
}

const mutations = {
    changeModal(state, status) {
        state.modals = {
            ...state.modals,
            ...status
        };
    },
    async setProduct(state, productInformation) {
        // new, used, refurbished
        if (productInformation.condition !== null && (typeof (productInformation.condition) == 'string')) {
            if (productInformation.condition == 'new') {
                productInformation.condition = true;
            } else {
                productInformation.condition = false;
            }
        }
        if(productInformation.condition == null){
            productInformation.condition = true;
        }
        // Discounts
        if (productInformation.discounts.length > 0) {
            productInformation.discounts.map(discount => {
                if (!discount.unlimit && (new Date().getTime() >= new Date(discount.to).getTime())) {
                    discount.disabled = true;
                }
            });
        }

        // Move default image to top
        if (productInformation.images.length > 0) {
            let index = productInformation.images.findIndex(image => image.url == productInformation.imageUrl);
            productInformation.images.unshift({
                ...productInformation.images[index]
            });
            productInformation.images.splice(index + 1, 1);
        }

        let tempVariants = [];
        if (productInformation.variants.length > 0) {
            for await (let variant of productInformation.variants) {
                if (variant.image_id == undefined) {
                    variant.image_id = productInformation.imageUrl || null;
                }
                if (variant.name != "Default Title") {
                    tempVariants.push(variant);
                }
            }
        } else {
            tempVariants = [];
        }
        productInformation.variants = tempVariants;
        state.productInformation = {
            ...state.productInformation,
            ...productInformation
        };
    },
    setNewVariants(state, [optionsList, variantsList]) {
        let tempVariants = [];
        for (let variant of variantsList) {
            if (variant.image_id == undefined) {
                variant.image_id = state.productInformation.imageUrl;
            }
            if (variant.name != "Default Title") {
                tempVariants.push(variant);
            }
        }
        state.productInformation.variants = tempVariants;
        state.productInformation.options = optionsList;
    },
    setProductTranslations: (state, translations) => {
        state.productInformationTranslations = translations;
    },
    setProductLanguage(state, language) {
        state.productInformationLanguage = language;
    },
    deleteTransalation(state, language) {
        let tempData = state.productInformationTranslations.filter(translation => (translation.language != language));
        state.productInformationTranslations = [...tempData];
    },
    setAvailableLanguages: (state, languages) => {
        languages = languages.filter(language => (language.language != state.productInformation.language));
        state.availableLanguages = Vue.prototype.$lodash.sortBy(languages, ['language']);
    },

    setProductError: (state, payload) => {
        state.productError = payload;
    },
    setModalSellOutOfStock: (state, payload) => {
        state.modalSellOutOfStock = payload;
    },
    updateProductVariants(state, payload) {
        state.productInformation.dimensions = payload.dimensions;
        state.productInformation.variants = payload.variants;
    },
    updateProductDimensions(state, payload) {
        state.productInformation.dimensions = payload.dimensions;
    },

    updateProductVariantsDimensions(state, payload) {
        for (let id of payload.variantList) {
            let tempIndex = state.productInformation.variants.findIndex((variant) => {
                return variant.id == id
            });
            if (tempIndex >= 0) {
                state.productInformation.variants[tempIndex].dimensions = payload.dimensions;
            }
        }
    },

    updateProductVariantsDiscounts(state, payload) {
        if (payload.variantList) {
            for (let index in state.productInformation.variants) {
                state.productInformation.variants[index].discount_percent = 0;
            }
            for (let id of payload.variantList) {
                let tempIndex = state.productInformation.variants.findIndex((variant) => {
                    return variant.id == id
                });
                if (tempIndex >= 0) {
                    state.productInformation.variants[tempIndex].discount_percent = payload.discount.percent;
                }
            }
        }
        state.productInformation.discounts.unshift(payload.discount);
    },

    deleteProductImage(state, index) {
        state.productInformation.images.splice(index, 1);
    },

    addProductImage(state, images) {
        state.productInformation.images = state.productInformation.images.concat(images);
    },

    changeLoading(state, status) {
        state.loading = {
            ...state.loading,
            ...status
        };
    },

    updateGeneralInformation(state, productInformation) {
        state.productInformation.name = productInformation.name;
        state.productInformation.sku = productInformation.sku;
        state.productInformation.price = productInformation.price;
        state.productInformation.quantity = productInformation.quantity;
        state.productInformation.description = productInformation.description;
        state.productInformation.category = productInformation.category;
        state.productInformation.dimensions = productInformation.dimensions;
        state.productInformation.completed = productInformation.completed;
        state.productInformation.tips = productInformation.tips;
    },
    setNewProduct(state, status) {
        state.newProduct = status;
    },
    setStoreInformation(state, storeInformation) {
        state.storeInformation = {
            ...storeInformation
        };
    },
    resetState(state) {
        Object.assign(state, defaultState())
    },

    // VARIANTS
    setVariants(state, variants) {
        state.newVariants = variants;
    },
    setOptions(state, options) {
        state.newOptions = options;
    },
    variantsAddNewOption(state) {
        for (let option of state.optionsNameList) {
            if (option.index === null) {
                state.optionsSelectedList.push({
                    ...option,
                    options: []
                })
                if (state.optionsSelectedList.length > 0) {
                    option.index = state.optionsSelectedList.length - 1;
                }
                break;
            }
        }
    },
    deleteNewVariant(state, index) {
        state.newVariants.splice(index, 1);
    },
    variantsChangeOption(state, [index, optionName]) {
        state.optionsNameList.map(option => {
            if (option.index == index) {
                option.index = null;
            }
            if (option.name == optionName) {
                option.index = index;
            }
        });
    },
    resetVariants(state) {
        state.optionsSelectedList = [];
        state.newVariants = [];
        state.newOptions = [];
        state.optionsNameList = [{
            name: 'color',
            index: null,
        }, {
            name: 'size',
            index: null,
        }, {
            name: 'material',
            index: null,
        }, {
            name: 'style',
            index: null,
        }];

    },
    editVariants(state) {
        state.productInformation.options.map(productOption => {
            for (let option of state.optionsNameList) {
                if (option.name === productOption.name) {
                    state.optionsSelectedList.push({
                        ...option,
                        options: productOption.values,
                    })
                    if (state.optionsSelectedList.length > 0) {
                        option.index = state.optionsSelectedList.length - 1;
                    }
                    break;
                }
            }
        });
        setTimeout(() => {
            state.newVariants = [];
            state.productInformation.variants.map(variant => state.newVariants.push({
                ...variant
            }));
            state.modals.newVariant = true;
        }, 100);
    },
    removeOption(state, index) {
        for (let option of state.optionsNameList) {
            if (option.name === state.optionsSelectedList[index].name) {
                option.index = null;
                break;
            }
        }
        state.optionsSelectedList.splice(index, 1);
    },

    deleteVariant(state, index) {
        state.productInformation.variants.splice(index, 1);
    },

    updateVariant(state, variantInfo) {
        let index = state.productInformation.variants.findIndex(variant => variant.id == state.variantId);
        state.productInformation.variants.splice(index, 1, {
            ...state.productInformation.variants[index],
            ...variantInfo
        });
        state.modals.images = false;
        state.variantId = null;
    },

    setVariantId(state, variantId) {
        if (variantId) {
            state.modals.images = true;
        }
        state.variantId = variantId;
    },

    resetTranslation(state) {
        state.newTranslation = EcProductModel();
    },
    setCreateVariants(state, status) {
        state.createVariants = status;
    },
    editTranslation(state, index) {
        state.newTranslation = {
            ...state.productInformationTranslations[index],
            id: 'EditTrasnlation'
        };
        state.modals.translation = true;
    },
    // Discounts
    editDiscount(state, index) {
        state.newDiscount = {
            ...state.productInformation.discounts[index]
        };
        state.newDiscount.from = Vue.prototype.$moment(state.newDiscount.from).format('x');
        state.newDiscount.to = Vue.prototype.$moment(state.newDiscount.to).format('x');

        if (state.newDiscount.variant_ids) {
            let tempVarList = [];
            state.newDiscount.variant_ids.map(varId => {
                if (state.productInformation.variants.find(variant => variant.id == varId)) {
                    tempVarList.push(varId);
                }
            });
            state.newDiscount.variant_ids = tempVarList;
        }
        state.modals.discounts = true;
    },
    addDiscount(state, discount) {
        state.productInformation.discounts.map(disc => disc.active = false);
        state.productInformation.discounts.push(discount);
    },
    updateDiscount(state, discount) {
        let tempIndex = state.productInformation.discounts.findIndex(disc => disc.id == discount.id);
        state.productInformation.discounts.splice(tempIndex, 1);
        state.productInformation.discounts.unshift(discount);
    },
    deleteDiscount(state, index) {
        state.productInformation.discounts.splice(index, 1);
    },
    setDefaultDiscount(state, discountId) {
        state.productInformation.discounts.map(disc => (discountId == disc.id) ? (disc.active = true) : (disc.active = false));
    },
    setProductCategory(state, category) {
        state.productInformation.category = {...category};
    },


};

// actions
const actions = {
    async fnApiGetProduct(context, productId) {
        context.commit('changeLoading', {
            product: true
        });
        context.commit('setProductTranslations', []);
        context.commit('setProductError', false);
        await axios.get(`products/${productId}`).then(async (response) => {
            context.commit('setProduct', response.data);
            context.commit('setProductTranslations', response.data.translations);
            context.dispatch('fnApiGetStore', response.data.store_id);
            context.dispatch('fnApiGetLanguages');
            if (response.data.category.category_id) {
                await context.dispatch('fnApiGetAttributes', response.data.category.category_id);
            }
        }).catch(error => {
            context.commit('setProductError', true);
        });
        setTimeout(() => {
            context.commit('changeLoading', {
                product: false
            });
        }, 200);
    },

    async fnApiSyncProduct(context) {
        context.commit('changeLoading', {
            sync: true
        });
        context.commit('setProductLanguage', null);
        let lang = null;
        switch (context.state.productInformation.language) {
            case 'spanish':
                lang = 'es-ES';
                break;
            default:
                lang = 'en-US';
                break;
        }
        await axios.get(`products/synchronize/ecommerce/${context.state.productInformation.id}?lang=${lang}`).then((response) => {
            context.commit('setProduct', response.data);
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.productSync'));
        }).catch(error => {});
        context.commit('changeLoading', {
            sync: false
        });
    },

    async fnApiGetLanguages(context) {
        await axios.get(`languages`).then((response) => {
            context.commit('setAvailableLanguages', response.data);
        }).catch(error => {});
    },

    fnApiChangeStatusProduct(context) {
        return axios.put(`products/${ context.state.productInformation.id }`, {
            active: !context.state.productInformation.active
        }).then(ressponse => {
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
        }).catch(error => {
            if (error.response.data.message = 'The product is out stock.') {
                context.commit('setModalSellOutOfStock', true);
            }
            context.state.productInformation.active = !context.state.productInformation.active;
        });
    },

    fnApiSellOutOfStock: async (context) => {
        let sell_out_stock = !context.getters.getSellOutOfStock;
        axios.put(`products/${ context.state.productInformation.id }`, {
            sell_out_stock,
        }).then((response) => {
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
            context.state.modalSellOutOfStock = false;
            context.state.productInformation.sell_out_stock = sell_out_stock;
            context.state.productInformation.active = sell_out_stock;
            if (context.state.productInformation.sell_out_stock) {
                for (let index in context.state.productInformation.variants) {
                    context.state.productInformation.variants[index].active = true;
                }
            }
        }).catch((error) => {
            context.state.productInformation.sell_out_stock = !context.state.productInformation.sell_out_stock;
        });
    },

    fnApiChangeStatusVariant(context, index) {
        axios.put(`products/${ context.state.productInformation.id }/variants/${ context.state.productInformation.variants[index].id }`, {
            active: !context.state.productInformation.variants[index].active
        }).then((response) => {
            context.state.productInformation.variants[index].active = !context.state.productInformation.variants[index].active;
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
        }).catch((error) => {
            if (error.response.data.message = 'The product is out stock.') {
                context.commit('setModalSellOutOfStock', true);
            }
        });
    },

    async fnApiUpdateImage(context, imagesList) {
        context.commit('changeLoading', {
            newImage: true
        });
        await axios.put(`products/images/${ context.state.productInformation.id }`, {
            images: imagesList
        }).then(response => {
            context.commit('addProductImage', response.data);
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.productImagesUpdated'));
        }).catch(error => {});
        context.commit('changeLoading', {
            newImage: false,
        });
    },

    async fnApiDeleteImage(context, index) {
        context.commit('changeLoading', {
            deleteImage: index
        });
        await axios.delete(`products/${ context.state.productInformation.id }/images/${ context.state.productInformation.images[index].id }`).then(response => {
            context.commit('deleteProductImage', index);
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.productImagesUpdated'));
        }).catch(error => {});
        context.commit('changeLoading', {
            deleteImage: null
        });
    },

    async fnApiDeleteProduct(context) {
        context.commit('changeLoading', {
            deleteProduct: true
        });
        await axios.delete(`products/delete/${ context.state.productInformation.id }`).then(response => {
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.productDelete'));
            router.push('/dashboard/products');
        }).catch(error => {});
        context.commit('changeLoading', {
            deleteProduct: false
        });
    },

    async fnApiGetStore(context, storeId) {
        context.commit('changeLoading', {
            storeData: true
        });
        await axios.get(`stores/${storeId}`).then((response) => {
            context.commit('setStoreInformation', response.data);
        }).catch(error => {});
        context.commit('changeLoading', {
            storeData: false
        });
    },

    async fnApiDeleteVariant(context, index) {
        await axios.delete(`products/variants/${context.state.productInformation.id}`, {
            data: {
                variant_id: context.state.productInformation.variants[index].id
            }
        }).then(() => {
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.variantDeleted'));
            context.commit('deleteVariant', index);
        }).catch(error => error);
    },

    async fnApiUpdateVariant(context, variant) {
        context.commit('changeLoading', {
            variants: true
        });
        let tempVariant = {};
        ['name', 'price', 'dimensions', 'sku', 'image_id', 'quantity'].map(attr => {
            tempVariant[attr] = variant[attr];
        })
        await axios.put(`products/${context.state.productInformation.id}/variants/${context.state.variantId}`, tempVariant).then((response) => {
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.variantUpdated'));
            context.commit('updateVariant', response.data);
        }).catch(error => error);
        context.commit('changeLoading', {
            variants: false
        });
    },

    async fnApiUpdateDefaultImage(context, index) {
        context.commit('changeLoading', {
            deleteImage: index
        });
        await axios.put(`products/${ context.state.productInformation.id }`, {
            imageUrl: context.state.productInformation.images[index].url
        }).then(response => {
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
            context.commit('setProduct', response.data);
        }).catch(error => error);
        context.commit('changeLoading', {
            deleteImage: null
        });
    },

    async fnApiGetAttributes(context, categoryId) {
        let language = ''
        switch (context.state.productInformation.language) {
            case 'spanish':
                language = 'es-ES';
                break;
            default:
                language = 'en-US';
                break;
        }
        if (categoryId == undefined) {
            return null;
        }
        await axios.get('categories/attributes', {
            params: {
                lang: language,
                category_id: categoryId,
            }
        }).then((response) => {
            response.data.map(attribute => {
                attribute.value = false;
                attribute.attribSelected = null;
                if (attribute.values) {
                    attribute.values.map(attrVal => {
                        attrVal.optionsSelected = [];
                        if (attrVal.options && attrVal.options.length > 0) {
                            let tempOptions = [];
                            attrVal.options.map(attrOpt => {
                                tempOptions.push({
                                    name: attrOpt,
                                })
                            });
                            attrVal.options = tempOptions;
                        }
                    })
                }
            });
            context.state.attributesList = response.data;

            if (context.state.productInformation.category.category_id) {
                if (context.state.productInformation.attributes.length > 0) {
                    context.state.productInformation.attributes.map(productAttrib => {
                        let attribIndex = context.state.attributesList.findIndex(itemAttrib => itemAttrib.id == productAttrib.attribute_id);
                        context.state.attributesList[attribIndex].value = true;
                        productAttrib.values.map(value => {
                            context.state.attributesList[attribIndex].attribSelected = value.id;
                            let valueIndex = context.state.attributesList[attribIndex].values.findIndex(itemVal => itemVal.id == value.id);
                            if (context.state.attributesList[attribIndex].values[valueIndex].options) {
                                context.state.attributesList[attribIndex].values[valueIndex].optionsSelected.push({
                                    name: value.value
                                });
                            }
                        });
                    });
                }
            }
        }).catch(error => {});
    },

    async fnApiUpdateProduct(context) {
        let {
            name,
            description,
            tags,
            dimensions,
            category,
            preparation_time,
            quantity,
            sku,
            price,
            imageUrl,
            tempImages,
            units,
            sell_out_stock,
            condition,
        } = context.state.productInformation;
        let tempProduct = {
            name,
            description,
            tags,
            dimensions,
            preparation_time,
            quantity,
            price,
            condition,

        };
        if (sku) {
            tempProduct.sku = sku;
        }
        if (sell_out_stock) {
            tempProduct.sell_out_stock = sell_out_stock;
        }
        if (category.id) {
            tempProduct.category = {
                id: category.id,
            }
        }

        // attributes
        let tempAttribsList = [];
        context.state.attributesList.map(attrib => {
            if (attrib.value) {
                let tempAttrib = {
                    attribute_id: attrib.id,
                    values: []
                };
                let valueSelected = attrib.values.find(value => value.id == attrib.attribSelected);
                if (valueSelected) {
                    if (valueSelected.options) {
                        if (valueSelected.optionsSelected.length > 0) {
                            valueSelected.optionsSelected.map(optSelected => {
                                tempAttrib.values.push({
                                    id: valueSelected.id,
                                    value: optSelected.name
                                });
                            });
                        }
                    } else {
                        tempAttrib.values.push({
                            id: valueSelected.id,
                            value: valueSelected.name
                        });
                    }
                }
                if (tempAttrib.values.length > 0) {
                    tempAttribsList.push(tempAttrib);
                }
            }
        });
        if (tempAttribsList.length > 0) {
            tempProduct.attributes = tempAttribsList;
        }

        context.commit('changeLoading', {
            update: true,
        });
        if (context.state.productInformation.id) {
            // Update product
            if (context.state.newVariants.length > 0) {
                await context.dispatch('fnApiCreateVaraints');
            }
            await axios.put(`products/${context.state.productInformation.id}`, tempProduct).then((response) => {
                context.commit('setProduct', response.data);
                Vue.toasted.global.infoMessage(i18n.t('toasted.info.productUpdated'));
            }).catch(error => {});
        } else {
            if (context.state.newVariants.length > 0) {
                context.state.newVariants.map(variant => {
                    variant.image_id == variant.image_id || context.state.productInformation.imageUrl;
                })
                let tempVairants = [...context.state.newVariants];
                tempVairants.map(variant => {
                    for (let index in variant.options) {
                        variant['option' + (Number(index) + 1)] = variant.options[index];
                    }
                    delete variant.options;
                })
                let options = [];
                context.state.optionsSelectedList.map(opt => {
                    if (opt.options.length > 0) {
                        options.push({
                            name: opt.name,
                            values: opt.options
                        });
                    }
                })
                tempProduct.options = options;
                tempProduct.variants = tempVairants;
            }
            tempProduct.images = tempImages;
            tempProduct.units = units;
            // Create new product
            await axios.post(`products/${context.state.storeInformation.id}`, tempProduct).then((response) => {
                Vue.toasted.global.infoMessage(i18n.t('toasted.info.productCreated'));
                router.push(`/dashboard/products?store_id=${context.state.storeInformation.id}`);
            }).catch(error => {});
        }
        context.commit('changeLoading', {
            update: false,
        });
    },

    // New variants
    async fnApiCreateVaraints({
        state,
        commit
    }, notification) {
        commit('changeLoading', {
            variants: true,
        });
        state.newVariants.map(variant => {
            variant.image_id == variant.image_id || state.productInformation.imageUrl;
        })

        let tempVairants = [...state.newVariants];
        tempVairants.map(variant => {
            for (let index in variant.options) {
                variant['option' + (Number(index) + 1)] = variant.options[index];
            }
            delete variant.options;
        })
        let options = [];
        state.optionsSelectedList.map(opt => {
            if (opt.options.length > 0) {
                options.push({
                    name: opt.name,
                    values: opt.options
                });
            }
        })
        if (state.productInformation.variants.length > 0) {
            await axios.put(`products/variants/${state.productInformation.id}`, {
                options: options,
                variants: tempVairants,
            }).then(response => {
                commit('setNewVariants', [response.data.options, response.data.variants]);
                commit('resetVariants');
                commit('setCreateVariants', false);
            }).catch((error) => error);
        } else {
            await axios.post(`products/variants/${state.productInformation.id}`, {
                options: options,
                variants: tempVairants,
            }).then(response => {
                commit('setNewVariants', [response.data.options, response.data.variants]);
                commit('resetVariants');
                commit('setCreateVariants', false);
            }).catch((error) => error);
        }
        commit('changeLoading', {
            variants: false,
        });
    },

    async fnApiDeleteDiscount(context, index) {
        context.commit('changeLoading', {
            deleteDiscount: index,
        });
        await axios.delete(`products/remove/discount/${ context.state.productInformation.id }/${ context.state.productInformation.discounts[index].id }`).then(response => {
            context.commit('deleteDiscount', index);
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.productDiscountDeleted'));
        }).catch(error => {});
        context.commit('changeLoading', {
            deleteDiscount: null,
        });
    },

    async fnApiDefaultDiscount(context, index) {
        if (!context.state.productInformation.discounts[index].active) {
            context.commit('changeLoading', {
                defaultDiscount: index,
            });
            await axios.put(`products/discounts/available/${ context.state.productInformation.id }/${ context.state.productInformation.discounts[index].id }`).then(response => {
                context.commit('setDefaultDiscount', context.state.productInformation.discounts[index].id);
                Vue.toasted.global.infoMessage(i18n.t('toasted.info.productDiscountUpdated'));
            }).catch(error => {});
            context.commit('changeLoading', {
                defaultDiscount: null,
            });
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}