import router from "@/router";

const state = {
    modals: {
        recoveryPassword: false,
    },
    loading: {
        data: false,
    },
    showAlert: {
        pasword: false,
    },
    userData: {
        email: null,
    },
};

// getters
const getters = {};
// mutations
const mutations = {
    changeLoading(state, status) {
        state.loading = {
            ...state.loading,
            ...status
        };
    },
    changeModal(state, status) {
        state.modals = {
            ...state.modals,
            ...status
        };
    },
};
// actions
const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};