import Vue from 'vue';

const state = {
  productsList: [],
  loading: {
    productList: true,
  },
  pagination: {
    name: null,
    active: null,
    page: 1,
    limit: 255,
    total: null,
  },
}
// getters
const getters = {
  getSyncStores(state) {
    return state.syncStoresList;
  }
}
// mutations
const mutations = {
  changeLoadingProductList(state, status) {
    state.loading.productList = status;
  },
  setProductsList(state, productsList) {
    state.productsList = productsList;
  },
};
// actions
const actions = {
  async fnApiGetProducts(context,storeId) {
    context.commit('changeLoadingProductList', true);
    await axiosShopify.get(`shopify/products/${ storeId }`).then((response) => {
      context.commit('setProductsList', response.data.listings);
    }).catch(error => {});
    context.commit('changeLoadingProductList', false);
  },
  fnApiChangeStatusProduct(context, index) {
    axiosShopify.put(`products/${ context.state.productsList[index].id }`, {
      active: !context.state.productsList[index].active
    }).then((response) => {
      context.state.productsList[index].active = !context.state.productsList[index].active;
    }).catch((error) => {});
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}