
const state = {
    ordersList: [],
    isLoading: false,
    pagination: {
        number: null,
        country: null,
        status: null,
        store_id: null,
        page: 1,
        limit: 200,
        total: null,
    },
    loading:{
        getOrders: false,
    }
}

// getters
const getters = {
}

// mutations
const mutations = {
    setOrderList: (state, list) => {
        state.ordersList = list;
    },
    changeLoading(state, status) {
        state.loading = {
            ...state.loading,
            ...status
        };
    },
    setProductError: (state, payload) => {
        state.productInformationError = payload;
    },
    setModalSellOutOfStock: (state, payload) => {
        state.modalSellOutOfStock = payload;
    },
    updateProductVariants( state, payload ){
        state.productInformation.dimensions =  payload.dimensions;
        state.productInformation.variants =  payload.variants;
    },
    updateProductDimensions(state, payload){
        state.productInformation.dimensions =  payload.dimensions;
    },
    updateProductVariantsDimensions( state, payload ){
        for( let id of payload.variantList ){
            let tempIndex = state.productInformation.variants.findIndex( (variant) => { return variant.id == id }  );
            if( tempIndex >= 0 ){
                state.productInformation.variants[tempIndex].dimensions = payload.dimensions;
            }
        }
    },
    updateProductVariantsDiscounts( state, payload ){
        if( payload.variantList ){
            for( let index in state.productInformation.variants  ){
                state.productInformation.variants[index].discount_percent = 0;
            }
            for( let id of payload.variantList ){
                let tempIndex = state.productInformation.variants.findIndex( (variant) => { return variant.id == id }  );
                if (tempIndex >= 0) {
                    state.productInformation.variants[tempIndex].discount_percent = payload.discount.percent;
                }
            }
        }
        state.productInformation.discounts.unshift(payload.discount);
    },
};

// actions
const actions = {
    fnApiGetProduct: async (context, productId) => {
        await axiosShopify.get(`products/${productId}`).then((response) => {
            let tempVariants = [];
            if (response.data.variants.length > 0) {
                for (let variant of response.data.variants) {
                    if (variant.name != "Default Title") {
                        tempVariants.push(variant);
                    }
                }
            } else {
                tempVariants = [];
            }
            response.data.variants = tempVariants;
            context.commit('setProduct', response.data);
        }).catch(error => {
            context.commit('setProductError', true);
        });
    },
    fnApiGetOrders:  async (context, productId) => {
        // this.isLoading = true;
        context.commit('changeLoading', {
            getOrders: true
        });

        let {
            store_id,
            number,
            country,
            status,
            limit,
            page,
        } = {...context.state.pagination} ;
        let tempPagination = {
            page,
            limit
        };
        if (store_id !== null && store_id !== '') {
            tempPagination.store_id = store_id
        };
        if (number != null && number != '') {
            tempPagination.number = number
        };
        if (country != null && country != '') {
            tempPagination['shipping[country]'] = country
        };
        if (status !== null && status !== '') {
            tempPagination.status = status
        };

        await axiosShopify.get('orders', {
            params: tempPagination
        }).then((response) => {
            context.commit('setOrderList', response.data);
        }).catch((error) => {});

        context.commit('changeLoading', {
            getOrders: false
        });
    },
    async fnApiGetCountOrders() {
        let {
            number,
            country,
            status
        } = this.$lodash.cloneDeep(this.pagination);
        let tempPagination = {};
        if (number != null && number != '') {
            tempPagination.number = number
        };
        if (country != null && country != '') {
            tempPagination['shipping[country]'] = country
        };
        if (status !== null && status !== '') {
            tempPagination.status = status
        };
        await axiosShopify.get('orders/count', {
            params: tempPagination
        }).then((response) => {
            this.pagination.total = response.data.count;
        }).catch((error) => {});
    },
    fnResetPagination() {
        this.pagination.page = 1;
        this.pagination.limit = this.CONST.PAGINATION[0];
        this.pagination.country = null;
        this.fnApiGetCountOrders();
        this.fnApiGetOrders();
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}