import Vue from 'vue';
import i18n from '@/i18n';
import {
  EcDiscountModel,
} from "@/plugins/beans.js"

const state = {
  modal: {
    coupons: false,
    shipping: false,
    products: false,
    discountDetails: false,
  },
  loading: {
    discountList: true,
    stores: false,
    newDiscount: false,
  },
  newDiscount: EcDiscountModel(),
  discountDetails: EcDiscountModel(),
  storesList: [],
  carriersList: [],
  productsList: [],
  discountsList: [],
  discountsCarriersList: [],
}
// getters
const getters = {
  getCarriers(state) {
    if (state.discountDetails.carriers.length > 0) {
      let carriersListAllowed = [];
      state.discountDetails.carriers.map(carrier => {
        let carrierAllowed = state.carriersList.find(tempCarrier => tempCarrier.name == carrier.name);
        carrierAllowed.services.map(service => {
          if (carrier.services.includes(service.name)) {
            service.active = true;
          } else {
            service.active = false;
          }
        });
        carriersListAllowed.push(carrierAllowed);
      })
      return carriersListAllowed;
    } else {
      return [];
    }
  }
}
// mutations
const mutations = {
  setStoresList(state, stores) {
    state.storesList = stores;
  },
  setCarriersList(state, carriers) {
    state.carriersList = carriers;
  },
  setProductsList(state, productsList) {
    state.productsList = productsList;
  },
  setDiscountsList(state, discountsList) {
    state.discountsList = discountsList;
  },
  updateDiscount(state, discount) {
    let index = state.discountsList.findIndex(tempDiscount => tempDiscount.id == discount.id);
    state.discountsList[index] = discount;
  },

  addDiscount(state, discount) {
    state.discountsList.unshift(discount);
  },
  setDiscountDetails(state, discountInformation) {
    state.discountDetails = discountInformation;
  },

  setDiscountInformation(state, discountInformation){
    state.newDiscount = {...state.newDiscount, ...discountInformation };
  },

  setDiscount(state, index) {

    state.newDiscount.productsList = state.discountsList[index].productsList;
    state.newDiscount.productsListExcluded = state.discountsList[index].productsListExcluded;

    state.newDiscount.id = state.discountsList[index].id;
    state.newDiscount.storeId = state.discountsList[index].store_id;
    state.newDiscount.name = state.discountsList[index].name;

    state.newDiscount.disabledDates = state.discountsList[index].disabledDates || false;
    state.discountsList[index].currency ? state.newDiscount.currency = state.discountsList[index].currency : null;
    state.discountsList[index].amount ? state.newDiscount.amount = state.discountsList[index].amount : null;
    state.newDiscount.isPercent = state.discountsList[index].is_percent;
    (state.discountsList[index].amount_cap > 0) ? state.newDiscount.maxPurchase = state.discountsList[index].amount_cap: null;
    (state.discountsList[index].min_amount > 0) ? state.newDiscount.minPurchase = state.discountsList[index].min_amount: null;
    (state.discountsList[index].available > 0) ? (state.newDiscount.available = state.discountsList[index].available) : (state.newDiscount.isUnlimited = true);

    if (state.discountsList[index].date_from) {
      state.newDiscount.dates.initial = state.discountsList[index].date_from;
      state.newDiscount.dates.finish = state.discountsList[index].date_to;
      state.newDiscount.applyDates = true;
    }

    switch (state.discountsList[index].type) {
      case 'coupons':
        state.newDiscount.coupon = state.discountsList[index].coupon;
        state.modal.coupons = true;
        break;
      case 'shipping':
        state.modal.shipping = true;
        state.discountsCarriersList = state.discountsList[index].carriers;
        break;
      case 'products':
        state.newDiscount.products = state.discountsList[index].products;
        state.newDiscount.excludeProducts = state.discountsList[index].exclude_products;
        (state.discountsList[index].products.length == 0) ? (state.newDiscount.toAllStore = true) : (state.newDiscount.toAllStore = false);
        (state.discountsList[index].exclude_products.length > 0) ? (state.newDiscount.exclude = true) : (state.newDiscount.exclude = false);

        state.modal.products = true;
        break;
    }

  },
  changeModal(state, status) {
    state.modal = {
      ...state.modal,
      ...status
    };
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  resetDiscount(state) {
    state.newDiscount = EcDiscountModel();
    state.discountsCarriersList = [];
  }
};
// actions
const actions = {

  async fnApiGetDiscountProducts(context, index) {
    context.commit('changeLoading', {
      discountList: true,
    });
    let productsList = [];
    let productsListExcluded = [];

    context.state.discountsList[index].products.map(product => {
      productsList.push(product.id);
    });

    context.state.discountsList[index].exclude_products.map(product => {
      productsListExcluded.push(product.id);
    });

    if ((productsList.length > 0) && (context.state.discountsList[index].productsList == undefined)) {
      await axios.post('products/information', {
        products: productsList
      }).then((response) => {
        context.state.discountsList[index].productsList = response.data;
      }).catch( error => {} );
    }

    if ((productsListExcluded.length > 0) && (context.state.discountsList[index].productsListExcluded == undefined)) {
      await axios.post('products/information', {
        products: productsListExcluded
      }).then((response) => {
        context.state.discountsList[index].productsListExcluded = response.data;
      }).catch( error => {} );
    }

    context.commit('changeLoading', {
      discountList: false,
    });
  },

  async fnApiCreateDiscountCoupon(context, discount) {

    let tempCouponDiscount = {
      store_id: discount.storeId,
      name: discount.name,
      unlimit: discount.isUnlimited,
      coupon: discount.coupon,
      available: discount.available,
      is_percent: discount.isPercent,
      amount: discount.amount,
      // min_amount: discount.minPurchase,
      currency: discount.currency,
    }

    if(discount.minPurchase){
      tempCouponDiscount.min_amount = discount.minPurchase;
    }
    if (discount.applyDates) {
      tempCouponDiscount.date_from = discount.dates.initial;
      tempCouponDiscount.date_to = discount.dates.finish;
    }
    if (discount.maxPurchase) {
      tempCouponDiscount.amount_cap = discount.maxPurchase;
    }

    context.commit('changeLoading', {
      newDiscount: true,
    });

    if (discount.id) {
      await axios.put(`discounts/coupon/${discount.id}`, tempCouponDiscount).then((response) => {
        context.commit('updateDiscount', response.data)
        context.commit('changeModal', {
          coupons: false,
        });
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.discountUpdated'));
      }).catch((error) => {});

    } else {
      await axios.post('discounts/coupon', tempCouponDiscount).then((response) => {
        context.commit('addDiscount', response.data)
        context.commit('changeModal', {
          coupons: false,
        });
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.discountCreate'));
      }).catch((error) => {});
    }

    context.commit('changeLoading', {
      newDiscount: false,
    });
  },

  async fnApiCreateShipmentDiscount(context, discount) {
    let tempShipmentDiscount = {
      store_id: discount.storeId,
      name: discount.name,
      unlimit: discount.isUnlimited,
      available: discount.available,
      is_percent: discount.isPercent,
      min_amount: discount.minPurchase,
      amount: discount.amount,
      currency: discount.currency,
      carriers: discount.carriers,
    }
    if (discount.applyDates) {
      tempShipmentDiscount.date_from = discount.dates.initial;
      tempShipmentDiscount.date_to = discount.dates.finish;
    }
    if (discount.isPercent) {
      tempShipmentDiscount.amount_cap = discount.maxPurchase;
    }
    context.commit('changeLoading', {
      newDiscount: true,
    });

    if (discount.id) {
      await axios.put(`discounts/shipment/${discount.id}`, tempShipmentDiscount).then((response) => {
        context.commit('updateDiscount', response.data);
        context.commit('changeModal', {
          coupons: false,
        });
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.discountUpdated'));
      }).catch( error => {} );
    } else {
      await axios.post('discounts/shipment', tempShipmentDiscount).then((response) => {
        context.commit('addDiscount', response.data)
        context.commit('changeModal', {
          coupons: false,
        });
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.discountCreate'));
      }).catch( error => {} );
    }
    context.commit('changeLoading', {
      newDiscount: false,
    });
  },


  async fnApiCreateProductsDiscount(context, discount) {
    discount.isPercent = true;

    let tempProductsDiscounts = {
      store_id: discount.storeId,
      name: discount.name,
      unlimit: discount.isUnlimited,
      disabledDates: discount.disabledDates,
      amount: discount.amount,
      available: discount.available,
      is_percent: discount.isPercent,
    }
    tempProductsDiscounts.products = discount.products;
    tempProductsDiscounts.exclude_products = discount.excludeProducts;
    if (discount.applyDates) {
      tempProductsDiscounts.date_from = discount.dates.initial;
      tempProductsDiscounts.date_to = discount.dates.finish;
    }
    context.commit('changeLoading', {
      newDiscount: true,
    });

    if (discount.id) {

      if (discount.productsList && (discount.productsList.length > 0)) {
        let tempDeletedProducts = [];
        discount.productsList.map(tempPrduct => {
          let tempIndex = discount.products.findIndex(product => product.id == tempPrduct.id);
          tempIndex === -1 ? tempDeletedProducts.push(tempPrduct.id) : null;
        });
        tempProductsDiscounts.deleted_products = tempDeletedProducts;
      }

      await axios.put(`discounts/products/${discount.id}`, tempProductsDiscounts).then((response) => {
        context.commit('updateDiscount', response.data)
        context.commit('changeModal', {
          products: false,
        });
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.discountUpdated'));
      }).catch( error => {} );

    } else {
      await axios.post('discounts/products', tempProductsDiscounts).then((response) => {
        context.commit('addDiscount', response.data)
        context.commit('changeModal', {
          products: false,
        });
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.discountCreate'));
      }).catch( error => {} );
    }

    context.commit('changeLoading', {
      newDiscount: false,
    });

  },

  async fnApiGetDiscountsList(context) {
    context.commit('changeLoading', {
      discountList: true,
    });
    await axios.get('discounts/list').then((response) => {
      context.commit('setDiscountsList', response.data);
    }).catch( error => {} );
    context.commit('changeLoading', {
      discountList: false,
    });
  },

  async fnApiGetStores(context) {
    context.commit('changeLoading', {
      stores: true,
    });
    await axios.get('stores').then((response) => {
      context.commit('setStoresList', response.data);
    }).catch( error => {} );
    context.commit('changeLoading', {
      stores: false,
    });
  },

  async fnApiGetProducts(context, storeId) {
    context.commit('changeLoading', {
      products: true,
    });
    if (storeId) {
      await axios.get(`products?store_id=${storeId}`).then((response) => {
        context.commit('setProductsList', response.data);
      }).catch( error => {} );
    } else {
      context.commit('setProductsList', []);
    }
    context.commit('changeLoading', {
      products: false,
    });
  },


  async fnApiGetCarriers(context) {
    context.commit('changeLoading', {
      carriers: true,
    });
    await axios.get('carriers').then(response => {
      for (let index in response.data) {
        response.data[index].showServices = false;
      }
      context.commit('setCarriersList', Vue.prototype.$lodash.sortBy(response.data, ['name']));
    }).catch(err => {});
    context.commit('changeLoading', {
      carriers: false,
    });
  },

}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}