// import Vue from 'vue';
import router from '@/router';

const defaultState = () => {
  return {
    loading: {
      newCart: false,
    },
    modals: {
      newCart: false,
    },
    newCart: {
      currency: null,
    }
  };
}

const state = defaultState();

// getters
const getters = {}
// mutations
const mutations = {
  changeModal(state, modal) {
    state.modals = {
      ...state.modals,
      ...modal
    };
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  resetNewCart(state) {
    state.modals = {
      ...state.modals,
      ...{ newCart: false }
    };
    state.newCart.currency = null;
  },
};
// actions
const actions = {

  async fnApiNewCart(context) {
    context.commit('changeLoading', {
      newCart: true
    });
    await axios.post(`carts`, {
      currency: context.state.newCart.currency
    }).then((response) => {
      router.push('/dashboard/new-cart/' + response.data.id);
      context.commit('resetNewCart');
    }).catch((error) => {});
    context.commit('changeLoading', {
      newCart: false
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}