import Vue from 'vue';
import i18n from '@/i18n';

import {
  EcNewPickup,
  EcOrderModel,
} from "@/plugins/beans.js"
import router from "@/router";
const defaultState = () => {
  return {
    pickupsList: [],
    carriersList: [],
    carriers: [],
    storesList: [],
    addressesList: [],
    modalCreatePickup: false,
    loading: {
      data: false,
      newPickup: false,
    },
    pickupsErrors: {
      newPickup: null,
    },
    newPickup: EcNewPickup(),
    pickupOrderInformation: EcOrderModel(),
  }
}
const state = defaultState();

// getters
const getters = {}

// mutations
const mutations = {
  setPickups(state, list) {
    state.pickupsList = list;
  },
  addPickup(state, pickup) {
    state.pickupsList.push(pickup);
  },
  setCarriersList(state, list) {
    state.carriersList = list;
  },
  setCarriers(state, carriers) {
    state.carriers = carriers;
  },
  setStores(state, list) {
    state.storesList = list;
  },
  addAdresses(state, store) {
    state.storesList[store.index].addresses = store.addresses;
    state.addressesList = store.addresses;
    state.newPickup.origin.company = store.name;
    state.newPickup.origin.email = store.email;
  },
  setModalPickup(state, status) {
    state.modalCreatePickup = status;
  },

  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },

  resetNewPickup(state) {
    state.modalCreatePickup = false;
    state.pickupOrderInformation = EcOrderModel();
    state.newPickup = EcNewPickup();
    state.pickupsErrors = {
      newPickup: null,
    };
  },

  setErrorPickup(state, error) {
    state.pickupsErrors = {
      ...state.pickupsErrors,
      ...error
    };
  },
  setOrderInformation(state, orderInformation) {
    state.pickupOrderInformation = orderInformation;
    state.newPickup.storeId = orderInformation.store_id;
    state.newPickup.shipment.carrier = orderInformation.shipping_items[0].carrier;
    state.newPickup.shipment.pickup.total_packages = orderInformation.shipping_items[0].packages[0].amount;
    state.newPickup.shipment.pickup.total_weight = orderInformation.shipping_items[0].packages[0].weight;
    state.newPickup.origin.address = {
      ...state.newPickup.origin.address,
      ...orderInformation.shipping_items[0].origin
    };
  },
  resetState(state) {
    Object.assign(state, defaultState())
  },

};

// actions
const actions = {

  async fnApiCreatePickup(context) {
    context.commit('changeLoading', {
      newPickup: true,
    });
    let {
      storeId,
      origin,
      shipment
    } = context.state.newPickup;

    let tempAddress = {
      first_name: origin.address.first_name,
      last_name: origin.address.last_name,
      address1: origin.address.address1,
      address2: origin.address.address2,
      country: {
        code: origin.address.country.code
      },
      city: origin.address.city,
      postal_code: origin.address.postal_code,
      phone: origin.address.phone,
      state: {},
    };
    if (origin.address.state.code) {
      tempAddress.state.code = origin.address.state.code;
    } else {
      tempAddress.state.name = origin.address.state.name;
    }
    origin.address = tempAddress;

    await axios.post('pickups', {
      store_id: storeId,
      origin,
      shipment
    }).then(response => {
      context.commit('addPickup', response.data);
      if (context.state.pickupOrderInformation.id != null) {
        router.push(`/dashboard/orders/${context.state.pickupOrderInformation.id}`);
      }
      context.commit('resetNewPickup');
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.pickupCreated'));
    }).catch(error => {
      switch (error.response.data.message) {
        case 'No tracking number today for this postal code':
          context.commit('setErrorPickup', {
            newPickup: 'trackingNumber',
          });
          break;
        case 'Pickup already scheduled today':
          context.commit('setErrorPickup', {
            newPickup: 'pickupToday',
          });
        case 'No shipment found for this postal code':
          context.commit('setErrorPickup', {
            newPickup: 'postalCode',
          });
          break;
      }
    });
    context.commit('changeLoading', {
      newPickup: false,
    });
  },

  async fnApiGetPickups(context) {
    context.commit('changeLoading', {
      data: true,
    });
    await axios.get('pickups').then(response => {
      this.pickupList = response.data;
      context.commit('setPickups', response.data);
    }).catch(error => {});
    context.commit('changeLoading', {
      data: false,
    });
  },

  // Load data
  async fnApiGetCarriersList(context) {
    await axios.get('carriers/list').then(response => {
      context.commit('setCarriersList', Vue.prototype.$lodash.sortBy(response.data, ['name']));
    }).catch(err => {});
  },
  async fnApiGetCarriers(context, country) {
    await axios.get('carriers', {
      params: {
        country
      }
    }).then(response => {
      context.commit('setCarriers', Vue.prototype.$lodash.sortBy(response.data, ['name']));
    }).catch(err => {});
  },

  async fnApiGetStores(context) {
    await axios.get('stores').then((response) => {
      context.commit('setStores', response.data);
    }).catch();
  },

  async fnApiGetStoreAddresses(context) {
    if (context.state.newPickup.storeId) {
      await axios.get(`stores/${context.state.newPickup.storeId}`).then((response) => {
        response.data.index = context.state.storesList.findIndex(store => store.id == context.state.newPickup.storeId);
        context.commit('addAdresses', response.data);
      }).catch();
    }
  },
  async fnApiGetOrderInformaiton(context, orderId) {
    await axios.get(`orders/${orderId}`).then(async (response) => {
      context.commit('setOrderInformation', response.data);
      context.commit('setModalPickup', true);
      await context.dispatch('fnApiGetStoreAddresses');
    }).catch();
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}