import Vue from 'vue'
import {
  required,
  max,
  max_value,
  min_value,
  min,
  email,
  regex
} from "vee-validate/dist/rules";
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import i18n from '@/i18n'

extend("required", {
  ...required,
  message: (field, val) => (`${field}, ${i18n.t('validations.required')}`),
});
extend("min_value", {
  ...min_value,
  message: (field, val) => (`${field}, ${i18n.t('validations.minValue')} (${val.min})`),
});
extend("max_value", {
  ...max_value,
  message: (field, val) => (`${field}, ${i18n.t('validations.maxValue')} (${val.max})`),
});
extend("max", {
  ...max,
  message: (field, val) => (`${i18n.t('validations.max')} ${val.length} ${i18n.t('validations.characters')}`),
});
extend("min", {
  ...min,
  message: (field, val) => (`${i18n.t('validations.min')} ${val.length} ${i18n.t('validations.characters')}`),
});

extend("email", {
  ...email,
  message: (field, val) => (`${i18n.t('validations.emailInvalid')}`),
});

extend("regex", {
  ...regex,
  message: (field, val) => (`${field}, ${i18n.t('validations.invalid')}`),
});

extend("validDate", {
  validate(value) {
    return !(Vue.prototype.$moment().format('x') > Vue.prototype.$moment(value, 'x').format('x')) ;
  },
  message: (field, val) => (`${i18n.t('validations.invalidDate')}`),
});

extend("validEmail", {
  validate(value) {
    const re = /^(([^<>()[\]\\.,;+:\s@"]+(\.[^<>()[\]\\.,;+:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  },
  message: (field, val) => (`${i18n.t('validations.emailInvalid')}`),
});


extend("validPass", {
  validate(value) {
    const re = /^.*(?=.{8,})(?=.*[a-z]{1})(?=.*[A-Z]{1})(?=.*\d).*$/;
    return re.test(value);
  },
  message: (field, val) => (`${i18n.t('validations.password')}`),
});



// Register it globally
Vue.component('v-validation', ValidationProvider);
Vue.component('v-observer', ValidationObserver);