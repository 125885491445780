export const find = function (array, value) {
  let key = Object.keys(value)[0];
  return array.find(item => item[key] == value[key]);
};
export const findIndex = function (array, values) {
  return array.findIndex((item) => {
    return item[values[0]] == values[1]
  });
};
export const sortBy = function (array, key) {
  return array.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
};
export const cloneDeep = function (object) {
  return JSON.parse(JSON.stringify(object))
};
export const clone = function (object) {
  return JSON.parse(JSON.stringify(object))
};