import Vue from 'vue';

const state = {
  loading: {
    data: false,
    chart: false,
    topSales: false,
  },
  storesStats: [],
  topsList: {
    stores: {
      total: null,
    },
    // productSales: [],
    productViews: [],
    productTips: [],
    productShippingCountries: [],
    totalSales: [],
    totalOrders: [],
    totalProductSold: 0,
    totalCountries: [],
    dashboardTotalSales: [],
    dashboardTotalOrders: [],
    totalSalesOrders: [],
    totalSalesReturns: [],
  },
  countOrderPending: 0,
  charData: [],
  productsTips: [],
  topSales: [],
}

// getters
const getters = {}

// mutations
const mutations = {
  setTops(state, tops) {
    state.topsList.stores = tops.stores;
    // state.topsList.productSales = tops.top_product_sales;
    state.topsList.productViews = tops.top_product_views;
    state.topsList.productShippingCountries = tops.top_shipping_countries;
    state.topsList.totalSales = tops.total_sales;
    state.topsList.totalOrders = tops.total_orders;
    state.topsList.totalProductSold = tops.total_product_sold;
    state.topsList.totalCountries = tops.total_countries;
    state.topsList.dashboardTotalSales = tops.dashboard_total_sales;
    state.topsList.dashboardTotalOrders = tops.dashboard_total_orders;
    state.topsList.totalSalesOrders = tops.total_sales_breakdown_orders;
    state.topsList.totalSalesReturns = tops.total_sales_breakdown_returns;
  },
  setTips(state, tips) {
    tips.map(product => (product.message = '', product.index = 0));
    state.productsTips = tips;
  },
  setChartData(state, data) {
    state.charData = data;
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  setOrderPending(state, count) {
    state.countOrderPending = count;
  },
  setTopSales(state, sales) {
    state.topSales = sales;
  },
  setStoresStats(state, storesStats) {
    state.storesStats = storesStats;
  },

};

// actions
const actions = {

  async fnApiGetData(context) {
    context.commit('changeLoading', {
      data: true,
    });
    context.dispatch('fnApiGetTopSales');
    await context.dispatch('fnApiGetCountOrders');
    await context.dispatch('fnApiGetProductsTips');
    await context.dispatch('fnApiGetStats');

    await axios.get(`dashboard/tops`).then(async (response) => {
      context.commit('setTops', response.data);
    }).catch(error => {});
    context.commit('changeLoading', {
      data: false,
    });
  },
  async fnApiGetStats(context) {
    await axios.get('dashboard/stadistics-time').then((response) => {
      context.commit('setStoresStats', response.data);
    }).catch((error) => {});
  },


  async fnApiGetProductsTips(context) {
    await axios.get('dashboard/top/product/tips').then((response) => {
      context.commit('setTips', response.data);
    }).catch((error) => {});
  },
  async fnApiGetCountOrders(context) {
    await axios.get('orders/count', {
      params: {
        status: 'shipment_pending',
      }
    }).then((response) => {
      context.commit('setOrderPending', response.data.count);
    }).catch((error) => {});
  },
  async fnApiGetChart(context,daysRange) {

    let initDate = new Date();
    initDate = Vue.prototype.$moment(initDate).subtract(daysRange, 'day').format();

    context.commit('changeLoading', {
      chart: true,
    });
    await axios.get('dashboard/graphic1', {
      params: {
        init_date: initDate,
      }
    }).then((response) => {
      let tempData = [];
      if (response.data.length > 0) {
        let left = false;
        let right = false;
        // for (let day = 0; day < daysRange; day++) {
        //   let tempDay = response.data.find(data => data.id == Vue.prototype.$moment(initDate).add(day, 'days').format('DD-MM-YYYY'));
        //   if (tempDay) {
        //     tempData.push(tempDay);
        //     left = false;
        //     right = false;
        //   } else {
        //     if(!left){
        //       tempData.push({
        //         id: Vue.prototype.$moment(initDate).add(day, 'days').format('DD-MM-YYYY'),
        //         sales: 0,
        //       });
        //       left = true;
        //       right = false;
        //     }
        //     let next = response.data.find(data => data.id == Vue.prototype.$moment(initDate).add((day + 1), 'days').format('DD-MM-YYYY'));
        //     if(left && !right && (next != undefined)){
        //       tempData.push({
        //         id: Vue.prototype.$moment(initDate).add(day, 'days').format('DD-MM-YYYY'),
        //         sales: 0,
        //       });
        //     }
            
        //   }
        // }
        tempData.sort((a, b) => {
          return (Number(Vue.prototype.$moment(a.id, 'DD-MM-YYYY').format('x')) > Number(Vue.prototype.$moment(b.id, 'DD-MM-YYYY').format('x'))) ? 1 : -1;
        })
      }
      response.data.sort((a, b) => {
        return (Number(Vue.prototype.$moment(a.id, 'DD-MM-YYYY').format('x')) > Number(Vue.prototype.$moment(b.id, 'DD-MM-YYYY').format('x'))) ? 1 : -1;
      })
      context.commit('setChartData', response.data);
    }).catch((error) => {
      console.log(error);
    });
    context.commit('changeLoading', {
      chart: false,
    });
  },
  async fnApiGetTopSales(context) {
    context.commit('changeLoading', {
      topSales: true,
    });
    await axios.get('dashboard/top/product/sales').then((response) => {
      context.commit('setTopSales', response.data);
    }).catch((error) => {});
    context.commit('changeLoading', {
      topSales: false,
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}