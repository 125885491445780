import Vue from 'vue';
import router from "@/router";
import i18n from '@/i18n';

import {
    EcStoreModel,
    EcWeBanner,
} from "@/plugins/beans.js"

const defaultState = () => {
    return {
        storeInformation: EcStoreModel(),
        loading: {
            storeData: false,
        },
        modals: {
            questions: false,
        },
        some: null,
        builderList: [{
                id: 'ar12422',
                name: 'Banner',
                type: 'banner',
                image: null,
                image_url: '@/assets/images/example/banner.jpeg',
                whidt: 0,
                height: 200,
                bgColor: '#fff',
                bannerType: 'contain',
            },
            {
                id: 'ar1264',
                name: 'Products',
                type: 'products',
                productList: ['']
            }
        ],
        selectedBuilder: null,
        pageList: [],
    }
}

const state = defaultState();

const getters = {
    getSome(state) {
        return state.some;
    },

}

const mutations = {
    changeModal(state, status) {
        state.modals = {
            ...state.modals,
            ...status
        };
    },
    changeLoading(state, status) {
        state.loading = {
            ...state.loading,
            ...status
        };
    },
    setStoreInformation(state, storeInformation) {
        state.storeInformation = {
            ...storeInformation
        };
    },
    addBuilder(state, type) {
        switch (type) {
            case 'banner':
                state.builderList.push(EcWeBanner('WE' + Math.random().toFixed(10).substring(2)));
                break;
            case 'productSlider':
                state.builderList.push(EcWeBanner('WE' + Math.random().toFixed(10).substring(2)));
                break;
        }
    },
    deleteBuilder(state, index) {
        state.builderList.splice(index, 1);
        state.selectedBuilder = null;
    },
    setSelectedBuilder(state, builderId) {
        state.selectedBuilder = builderId;
    },
    setBuildersList(state, newList) {
        state.buildersList = [...newList];
    },
    setPages(state, pageList) {
        state.pageList = pageList.pages;
    }
};

// actions
const actions = {
    async fnApiGetStore(context, storeId) {
        context.commit('changeLoading', {
            storeData: true
        });
        await axios.get(`stores/${storeId}`).then((response) => {
            context.commit('setStoreInformation', response.data);
        }).catch(error => {});
        context.commit('changeLoading', {
            storeData: false
        });
    },

    async fnApiGetPages(context, storeId) {
        context.commit('changeLoading', {
            pages: true
        });
        await axios.get(`website/${storeId}`).then((response) => {
            response.data.pages.map(page => {
                page.components = response.data.components.filter(comp => comp.page_id == page.id);
            })
            delete response.data.components
            context.commit('setPages', response.data);
        }).catch(error => {
            console.log(error);
        });
        context.commit('changeLoading', {
            pages: false
        });
    }

}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}