import Vue from 'vue';
import {
  EcOrderModel,
  EcTrackingInformationModel,
  EcShippingInformationModel,
} from "@/plugins/beans.js"

const state = {
  modalCancelOrder: false,
  modalUpdateBOL: false,
  sendingData: false,
  showtrackingNumber: false,
  loadingData: false,
  showTracking: false,
  orderInformation: EcOrderModel(),
  trackingInformation: EcTrackingInformationModel(),
  reasons: [{
      name: 'Inventory',
      description: 'Items in the order were not in inventory.'
    },
    {
      name: 'Discontinued',
      description: 'Items in the order are discontinued.'
    },
    {
      name: 'Fees',
      description: 'The commission fee is too high.'
    },
    {
      name: 'Price',
      description: 'The price of the item is not correct.'
    },
    {
      name: 'Shipping',
      description: 'I had trouble with shipping the items.'
    },
    {
      name: 'Customer',
      description: 'Customer asked to cancel the order.'
    },
    {
      name: 'Other',
      description: 'A reason not in this list.'
    }
  ],
  cancelReason: {
    reason: null,
    comments: null,
    message: null,
    charge: false,
  },
  loading: {
    generatingLabel: false,
    cancelOrder: false,
    updateBOL: false,
    charge: false,
  },
  store: {},
  originAddress: {
    id: null
  },
  shippingInformation: EcShippingInformationModel(),
  orderCharge: {
    id: null,
  },
  addressesList: [],
  addressId: null,
}

// getters
const getters = {
  getSyncStores(state) {
    return state.syncStoresList;
  }
}

// mutations
const mutations = {
  setOrderInformation(state, order) {
    state.orderInformation = order;
    let tempIndex = state.orderInformation.charge.findIndex(charge => charge.charge_type == 'orders');
    if (tempIndex >= 0) {
      state.orderCharge = state.orderInformation.charge[tempIndex];
    }
  },
  setTrackingInformation(state, tracking) {
    state.trackingInformation = tracking;
  },
  changeLoadingData(state, status) {
    state.loadingData = status;
  },
  changeShowTracking(state, status) {
    state.showTracking = status;
  },
  setTotal(state, total) {
    state.pagination.total = total;
  },
  setModalCancelOrder(state, status) {
    state.modalCancelOrder = status;
  },
  setModalBOL(state, status) {
    state.modalUpdateBOL = status;
  },
  resetCancelOrder(state) {
    state.cancelReason.reason = null;
    state.cancelReason.comments = null;
    state.cancelReason.message = false;
    state.cancelReason.charge = false;
    state.modalCancelOrder = false;
  },
  setCancelReason(state, reason) {
    state.cancelReason.reason = reason;
  },
  setOrderStatus(state, status) {
    state.orderInformation.order_status = status;
  },
  updateTrackingNumber(state, order) {
    state.orderInformation.shipping_items[0].label = order.label;
    if (!order.own_shipping) {
      state.orderInformation.tracking_url = order.tracking_url;
    }
    state.orderInformation.own_shipping = order.own_shipping;
    state.orderInformation.tracking_number = order.tracking_number;
    state.orderInformation.shipment_status = 'ready to ship';
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  // Store
  setStoreInformation(state, store) {
    state.store = store;
  },

  setShippingInformation(state, information) {
    state.shippingInformation = information;
  },
  setCancelMessage(state, status) {
    state.cancelReason.message = status;
  },
  setOrderCharge(state, charge) {
    state.orderCharge = charge;
  },
  setAddressesList(state, list) {
    state.addressesList = [];
    for (let adress of list) {
      state.addressesList.push({
        label: adress.address_name ? adress.address_name : adress.id,
        value: adress.id,
      })
    }
  },
  setOriginAddress(state, originAddress) {
    state.originAddress = {id:null};
    state.originAddress = originAddress;
  },
};

// actions
const actions = {

  async fnApiGetOrder(context, orderId) {
    context.commit('changeLoadingData', true);
    context.commit('setOrderCharge', {
      id: null
    });

    await axiosShopify.get(`orders/${orderId}`).then(async (response) => {
      context.commit('setShippingInformation', response.data.shipping_items[0]);

      context.commit('setOriginAddress', response.data.shipping_items[0].origin);

      context.commit('setOrderInformation', response.data);
      if ((response.data.shipping_items.length > 0) && (response.data.shipping_items[0].tracking_number)) {
        await context.dispatch('fnGetTraking');
      } else {
        context.commit('setTrackingInformation', EcTrackingInformationModel());
        context.commit('changeShowTracking', false);
      }
      context.dispatch('fnGetStoreInformation', response.data.store_id);
    }).catch();
    context.commit('changeLoadingData', false);
  },
  async fnGetStoreInformation(context, storeId) {
    axiosShopify.get(`stores/${storeId}`).then((response) => {
      context.commit('setStoreInformation', response.data);
      context.commit('setAddressesList', response.data.addresses);
    }).catch((error) => {});
  },
  async fnGetTraking(context) {
    await axiosShopify.get('orders/tracking?id=' + context.state.shippingInformation.tracking_number).then((response) => {
      context.commit('setTrackingInformation', response.data[0]);
      context.commit('changeShowTracking', true);
    }).catch();
  },

  async fnApiCancelOrder(context) {
    context.commit('changeLoading', {
      cancelOrder: true
    });
    let tempCancel = {
      cancel_reason: context.state.cancelReason.reason.toLowerCase(),
      comments: context.state.cancelReason.comments,
    }
    if (context.state.cancelReason.message) {
      tempCancel.charge = context.state.cancelReason.charge;
    }
    await axiosShopify.post(`orders/${ context.state.orderInformation.id }/cancel`, tempCancel).then((response) => {
      context.commit('setOrderInformation', response.data);
      context.commit('resetCancelOrder');
    }).catch((error) => {
      if (error.response.status == 405) {
        context.commit('setCancelMessage', true);
      }
    });
    context.commit('changeLoading', {
      cancelOrder: false
    });
  },
  async fnApiGenerateShippingLabel(context) {
    context.commit('changeLoading', {
      generatingLabel: true
    })
    const {
      carrier,
      service
    } = context.state.shippingInformation;
    const origin_id = context.state.originAddress.id;
    await axiosShopify.post(`orders/${context.state.orderInformation.id}/shippings/generate`, {
      carrier,
      service,
      origin_id
    }).then(async (response) => {
      context.commit('setShippingInformation', {
        ...context.state.shippingInformation,
        ...response.data
      });
      context.commit('setOrderInformation', {
        ...context.state.orderInformation,
        ...{
          shipment_status: 'ready to ship'
        }
      });
      await context.dispatch('fnGetTraking');
    }).catch((error) => {});
    context.commit('changeLoading', {
      generatingLabel: false
    })

  },

  async fnUpdateOrderBOL(context, itemsList) {
    let tempItemsList = [];
    for (let item of itemsList) {
      tempItemsList.push({
        id: item.item_id,
        title: item.name
      });
    }
    context.commit('changeLoading', {
      updateBOL: true
    })

    await axiosShopify.post(`orders/${context.state.orderInformation.id}/shippings/${context.state.shippingInformation.id}/billoflading`,
      tempItemsList).then((response) => {

      window.open(context.state.shippingInformation.bill_of_landing)
      // this.$emit('update-bol', response.data.shipping_items[0] );
      // this.shipmentDetails = response.data.shipping_items[0];
      // this.showform = false;
    }).catch(error => {});
    context.commit('changeLoading', {
      updateBOL: false
    })
  },
  async fnApiUpdateCharge(context, chargeId) {
    context.commit('changeLoading', {
      charge: true,
    });
    await axiosShopify.put(`charges/appeal/${chargeId}`, {
      status: "review"
    }).then((response) => {
      context.commit('setOrderCharge', response.data);
    }).catch((error) => {});
    context.commit('changeLoading', {
      charge: false,
    });
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}