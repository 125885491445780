import Vue from 'vue';
import i18n from '@/i18n';
import router from "@/router";

import {
  EcStoreModel,
  EcStoreAddressModel,
} from '@/plugins/beans';

const state = {
  storeInformation: EcStoreModel(),
  storeInformationView: EcStoreModel(),
  storeAlreadyIntegrated: false,
  integration: false,
  addressesList: [],
  storeDomains: [],
  loading: {
    data: false,
    store: false,
    address: false,
    countries: false,
    creatingDomain: false,
    refresDomains: false,
    createWebsite: false,
    deletingDomain: null,
  },
  modal: {
    data: false,
    address: false,
  },
  storeAddress: EcStoreAddressModel(),
  updateAddressId: null,
  deleteAddresId: null,
  newDomain: {
    domain: null,
  },
  syncStoresList: [],
}
// getters
const getters = {}
// mutations
const mutations = {
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  changeModal(state, modal) {
    state.modal = {
      ...state.modal,
      ...modal
    };
  },
  setStoreInformation(state, storeInformation) {
    if (storeInformation.complete == false) {
      state.integration = true;
    }
    state.storeInformation.storeId = storeInformation.store_id;
    state.storeInformation.search_store_id = storeInformation.search_store_id;
    state.storeInformation.productsActive = storeInformation.active_products;
    state.storeInformation.productsInactive = storeInformation.inactive_products;
    state.storeInformation.productsIncompleted = storeInformation.incomplete_products;
    state.storeInformation.orderSales = storeInformation.orders_sales;
    state.storeInformation.image = storeInformation.image || null;
    state.storeInformation.currency = storeInformation.currency || null;
    state.storeInformation.active = storeInformation.active || null;
    state.storeInformation.id = storeInformation.id || null;
    state.storeInformation.ecommerce = storeInformation.ecommerce || null;
    state.storeInformation.url = storeInformation.url || null;
    state.storeInformation.name = storeInformation.name || null;
    state.storeInformation.email = storeInformation.email || null;
    state.storeInformation.phone = storeInformation.phone || null;
    state.storeInformation.description = storeInformation.description || null;
    state.storeInformation.image_url = storeInformation.image_url || null;
    state.storeInformation.website = storeInformation.website || null;
    if (storeInformation.settings) {
      state.storeInformation.settings = {
        commission: storeInformation.settings.commission,
        language: storeInformation.settings.language || null,
      }
      if (state.storeInformation.settings.language == 'en') {
        state.storeInformation.settings.language = 'en-US'
      }
    } else {
      state.storeInformation.settings = {
        commission: null,
        language: null,
      }
    }
    state.addressesList = storeInformation.addresses;
    state.storeInformationView = {
      ...state.storeInformation
    };
  },
  setIntegration(state, status) {
    state.integration = status;
  },
  setErrorStore(state, status) {
    state.storeAlreadyIntegrated = status;
  },
  storeStatus(state, status) {
    state.storeInformation.active = status;
  },
  deleteAddress(state, index) {
    state.addressesList.splice(index, 1);
  },
  setAddresDefault(state, index) {
    for (let tempIndex in state.addressesList) {
      if (tempIndex == index) {
        state.addressesList[tempIndex].default = true;
      } else {
        state.addressesList[tempIndex].default = false;
      }
    }
  },
  resetAddress(state, userAddress) {
    state.storeAddress = EcStoreAddressModel();
    if (userAddress) {
      state.storeAddress.phone = address.phone || null;
      state.storeAddress.first_name = address.first_name || null;
      state.storeAddress.last_name = address.last_name || null;
      state.storeAddress.country.code = address.country || null;
    }
  },
  setUpdateAddressId(state, addressId) {
    state.updateAddressId = addressId;
  },
  setAddressInfo(state, {
    address,
    userInfo
  }) {

    state.storeAddress = {
      id: address.id,
      address_name: address.address_name,
      first_name: address.first_name || userInfo.first_name,
      last_name: address.last_name || userInfo.last_name,
      address1: address.address1,
      address2: address.address2,
      country: {
        code: address.country.code || userInfo.country,
      },
      city: address.city,
      postal_code: address.postal_code,
      phone: address.phone,
      state: {},
    };

    if (address.state.code) {
      state.storeAddress.state.code = address.state.code;
    } else {
      state.storeAddress.state.name = address.state.name;
    }
  },
  resetState(state) {
    state.integration = false;
    state.storeInformation = EcStoreModel();
    state.storeInformationView = EcStoreModel();
    state.storeAlreadyIntegrated = false;
  },
  updateAddress(state, newAddress) {
    state.addressesList.splice(state.addressesList.findIndex(address => address.id == newAddress.id), 1, newAddress);
  },
  addAddress(state, newAddress) {
    state.addressesList.push(newAddress);
  },
  setDeleteAddressId(state, addressId) {
    state.deleteAddresId = addressId;
  },
  // Sync stores
  setSyncStore(state, store) {
    state.syncStoresList.push(store);
  },
  deleteSyncStore(state, storeId) {
    for (let index in state.syncStoresList) {
      if (state.syncStoresList[index].id == storeId) {
        clearInterval(state.syncStoresList[index].interval);
        state.syncStoresList.splice(index, 1);
        Vue.toasted.global.successMessage(i18n.t('toasted.success.syncStore'));
        break;
      }
    }
  },

  // Domains
  setStoreDomains(state, domains) {
    state.storeDomains = domains;
  },
  deleteDomain(state, index) {
    state.storeDomains.splice(index, 1);
  },
  setNewStoreDomain(state,domain){
    state.newDomain.domain = domain;
  }
};

// actions
const actions = {

  // Store Information 
  async fnApiUpdateSellerStore(context, storeId) {
    context.commit('changeLoading', {
      store: true,
    });
    await axios.put(`stores/${storeId}/seller`).then(async (response) => {
      context.commit('setStoreInformation', response.data);
      context.commit('setStoreDomains', response.data.domains || []);
    }).catch(error => {
      context.commit('setErrorStore', true);
    });
    context.commit('changeLoading', {
      store: false,
    });
  },

  async fnApiUpdateStore({
    commit,
    state
  }) {
    commit('changeLoading', {
      data: true,
    });
    let {
      name,
      email,
      description,
      phone,
      settings,
      image,
      currency
    } = state.storeInformation;

    let tempStoreInfo = {};
    if (name != null) {
      tempStoreInfo.name = name
    };
    if (email != null) {
      tempStoreInfo.email = email
    };
    if (description != null) {
      tempStoreInfo.description = description
    };
    if (phone != null) {
      tempStoreInfo.phone = phone
    };
    if (settings != null) {
      tempStoreInfo.settings = settings
    };
    if (image != null) {
      tempStoreInfo.image = image
    };
    if (currency != null) {
      tempStoreInfo.currency = currency
    };
    await axios.put(`stores/${state.storeInformation.id}`, tempStoreInfo).then((response) => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.storeUpdated'));
      if (state.integration) {
        if (state.addressesList.length > 0) {
          commit('setUpdateAddressId', state.addressesList[0].id);
        }
        commit('changeModal', {
          address: true
        });
      }
    }).catch(error => {});
    commit('changeLoading', {
      data: false,
    });
  },

  async fnApiChangeStatusStore({
    state,
    commit
  }) {
    axios.put(`stores/${ state.storeInformation.id }`, {
      active: !state.storeInformation.active
    }).then((response) => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
    }).catch(error => {
      commit('storeStatus', !state.storeInformation.active);
    });
  },

  // Store Addresses
  fnApiUpdateDefault({
    state,
    commit
  }, index) {
    axios.put(`stores/${state.storeInformation.id}/addresses/${ state.addressesList[index].id }/default`).then((response) => {
      commit('setAddresDefault', index);
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.storeDefaultAddress'));
    }).catch(error => {});
  },

  fnApiDeleteAddress({
    state,
    commit
  }, index) {
    commit('setDeleteAddressId', state.addressesList[index].id);
    axios.delete(`stores/${state.storeInformation.id}/addresses/${state.addressesList[index].id}`).then((response) => {
      commit('deleteAddress', index);
      commit('setDeleteAddressId', null);
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.storeWarehouseDelete'));
    }).catch(error => {});
  },

  async fnApiAddStoreAddress({
    state,
    commit,
    dispatch,
    rootState
  }, useCode) {
    let save = false;
    let tempAddress = {
      address_name: state.storeAddress.address_name,
      first_name: state.storeAddress.first_name,
      last_name: state.storeAddress.last_name,
      address1: state.storeAddress.address1,
      address2: state.storeAddress.address2,
      country: {
        code: state.storeAddress.country.code
      },
      city: state.storeAddress.city,
      postal_code: state.storeAddress.postal_code,
      phone: state.storeAddress.phone,
      state: {},
    };
    if (useCode) {
      tempAddress.state.code = state.storeAddress.state.code;
    } else {
      tempAddress.state.name = state.storeAddress.state.name;
    }
    commit('changeLoading', {
      address: true,
    });

    if (state.storeAddress.id != null) {
      await axios.put(`stores/${state.storeInformation.id}/addresses/${ state.storeAddress.id }`, tempAddress).then(
        response => {
          save = true;
          commit('updateAddress', response.data);
          Vue.toasted.global.infoMessage(i18n.t('toasted.info.storeAddressUpdated'));
        }).catch(error => {});
    } else {
      await axios.post(`stores/${state.storeInformation.id}/addresses`, tempAddress).then((response) => {
        save = true;
        commit('addAddress', response.data);
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.sotreAddressAdded'));
      }).catch(error => {});
    }

    if (save) {
      commit('changeModal', {
        address: false,
      });
      commit('changeLoading', {
        address: false,
      });

      if (state.integration) {
        await axios.put(`stores/${state.storeInformation.id}`, {
          complete: true
        }).then((response) => {}).catch(error => {});

        setTimeout(() => {
          if (rootState.userSession.complete == false) {
            if (rootState.userSession.country == 'MX') {
              router.push('/dashboard/payments?integration=true');
            } else {
              axios.put('me/complete').then(async response => {
                localStorage.setItem("user", JSON.stringify(response.data));
                window.location.replace('/dashboard/welcome');
              }).catch(error => {});
            }
          } else {
            if (state.storeInformation.search_store_id) {
              router.push(`/dashboard/products?store_id=${state.storeInformation.search_store_id}`);
            } else {
              router.push(`/dashboard/products`);
            }
          }
        }, 800);
        if (state.storeInformation.ecommerce != 'Ecart') {
          dispatch('fnSyncStore', state.storeInformation);
        }
      }
    }
  },

  fnApiGetStoreStats({
    state
  }) {
    axios.get(`stores/stadistics/${ state.storeInformation.id }`, {}).then((response) => {
      state.storeInformation.stadistics = response.data.stadistics ? response.data.stadistics : state.storeInformation.stadistics;
    }).catch(error => {});
  },

  // Sync store
  fnSyncStore(context, store) {
    const item = context.state.syncStoresList.find(tempStore => tempStore.id == store.id);
    if (item == undefined) {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.syncStore') + ": " + (store.name ? store.name : store.store_id));
      context.dispatch('fnApiSyncStore', store);
    }
  },
  fnApiSyncStore(context, store) {
    axios.post('products/process?store_id=' + store.id).then(async (response) => {
      store.interval = setInterval(() => {
        context.dispatch('fnApiSyncProcess', {
          process_id: response.data.process_id,
          storeId: store.id
        });
      }, 1000);
      context.commit('setSyncStore', store);
    }).catch(error => {});
  },
  fnApiSyncProcess(context, {
    process_id,
    storeId
  }) {
    axios.get('../process/' + process_id).then(async (response) => {
      if (response.data.status != 'in process') {
        context.commit('deleteSyncStore', storeId);
      }
    }).catch(error => {});
  },

  async fnApiConvertStore({
    state,
    commit
  }) {
    commit('changeLoading', {
      store: true,
    });
    await axios.put(`stores/ecart/${ state.storeInformation.id }`).then((response) => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
      commit('setStoreInformation', response.data);
    }).catch(error => error);
    commit('changeLoading', {
      store: false,
    });
  },

  // Domains
  async fnApiRefresStoreDomains(context) {
    context.commit('changeLoading', {
      refresDomains: true,
    });
    await axios.get(`stores/${context.state.storeInformation.id}/domains/refresh`).then(async (response) => {
      context.commit('setStoreDomains', response.data);
    }).catch(error => {});
    context.commit('changeLoading', {
      refresDomains: false,
    });
  },

  async fnApiDeleteStoreDomain(context, index) {
    context.commit('changeLoading', {
      deletingDomain: index,
    });
    await axios.delete(`stores/${context.state.storeInformation.id}/domains/${context.state.storeDomains[index].id}`).then(async (response) => {
      context.commit('deleteDomain', index);
    }).catch(error => {});
    context.commit('changeLoading', {
      deletingDomain: null,
    });
  },

  async fnApiCreateStoreDomain(context) {
    context.commit('changeLoading', {
      creatingDomain: true,
    });
    await axios.post(`stores/${context.state.storeInformation.id}/domains`, {
      domain: context.state.newDomain.domain
    }).then(async (response) => {
      context.commit('setStoreDomains', [...context.state.storeDomains, response.data]);
      context.commit('setNewStoreDomain', null );
    }).catch(error => {});
    context.commit('changeLoading', {
      creatingDomain: false,
    });
  },

  async fnApiCreateWebsite(context) {
    context.commit('changeLoading', {
      createWebsite: true,
    });
    await axios.post(`website/${context.state.storeInformation.id}`).then((response) => {
        router.push('/web-editor/' + context.state.storeInformation.id );
    }).catch((error) => {});
    context.commit('changeLoading', {
      createWebsite: false,
    });
  },

  async fnApiDeleteWebsite(context) {
    context.commit('changeLoading', {
      createWebsite: true,
    });
    await axios.delete(`website/${context.state.storeInformation.id}`).then((response) => {
        // router.push('/web-editor/' + context.state.storeInformation.id );
    }).catch((error) => {});
    context.commit('changeLoading', {
      createWebsite: false,
    });
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}