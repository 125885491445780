 import data from './constants'
 import methods from './methods'

 const mixin = {
     data,
     methods,
     updated() {
         let device = localStorage.getItem('device');
         if (device) {
             let linksList = document.getElementsByTagName('a');
             for (const link of linksList) {
                 if (link.target == '_blank') {
                     link.onclick = (event) => {
                         NewLink.postMessage(JSON.stringify({
                             url: link.href
                         }));
                         event.preventDefault();
                     }
                 }
             }

         }
     }
 }

 export default mixin;