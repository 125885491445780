import Vue from 'vue';
import i18n from '@/i18n'

const state = {
  storeInformation: {},
  syncStoresList: [],
  intervalList: [],
  modal: {
    integrationExpired: false,
  },
}

// getters
const getters = {}

// mutations
const mutations = {
  changeModal(state, modal) {
    state.modal = {
      ...state.modal,
      ...modal
    };
  },
  setStore(state, store) {
    state.storeInformation = store;
  },
  setSyncStore(state, store) {
    state.syncStoresList.push(store);
  },
  deleteSyncStore(state, storeId) {
    for (let index in state.syncStoresList) {
      if (state.syncStoresList[index].id == storeId) {
        clearInterval(state.syncStoresList[index].interval);
        state.syncStoresList.splice(index, 1);
        Vue.toasted.global.successMessage(i18n.t('toasted.success.syncStore'));
        break;
      }
    }
  },
};

// actions
const actions = {

  fnSyncStore(context, store) {
    const items = context.state.syncStoresList.find(tempStore => tempStore.id == store.id);
    if (items == undefined) {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.syncStore') + ": " + (store.name ? store.name : store.store_id));
      context.dispatch('fnApiSyncStore', store);
    }
  },

  fnApiSyncStore(context, store) {
    axios.post('products/process?store_id=' + store.id).then(async (response) => {
      store.interval = setInterval(() => {
        context.dispatch('fnApiSyncProcess', {
          process_id: response.data.process_id,
          storeId: store.id
        });
      }, 1000);
      context.commit('setSyncStore', store);
    }).catch(error => {
      if (error.response.status == 422 || true) {
        context.commit('setStore', store);
        context.commit('changeModal', {
          integrationExpired: true
        });
      }
    });
  },

  fnApiSyncProcess(context, {
    process_id,
    storeId
  }) {
    axios.get('../process/' + process_id).then(async (response) => {
      if (response.data.status != 'in process') {
        context.commit('deleteSyncStore', storeId);
      }
    }).catch(error => {});
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}