import {
    EcProductModel
} from "@/plugins/beans.js"
import Vue from 'vue'

const state = {
    productInformation: EcProductModel(),
    productError: false,
    modalSellOutOfStock: false,
}

// getters
const getters = {
    getSellOutOfStock(state){
        return state.productInformation.sell_out_stock;
    }
}

// mutations
const mutations = {
    setProduct: (state, payload) => {
        state.productInformation = payload;
    },
    setProductError: (state, payload) => {
        state.productInformationError = payload;
    },
    setModalSellOutOfStock: (state, payload) => {
        state.modalSellOutOfStock = payload;
    },
    updateProductVariants( state, payload ){
        state.productInformation.dimensions =  payload.dimensions;
        state.productInformation.variants =  payload.variants;
    },
    updateProductDimensions(state, payload){
        state.productInformation.dimensions =  payload.dimensions;
    },
    updateProductVariantsDimensions( state, payload ){
        for( let id of payload.variantList ){
            let tempIndex = state.productInformation.variants.findIndex( (variant) => { return variant.id == id }  );
            if( tempIndex >= 0 ){
                state.productInformation.variants[tempIndex].dimensions = payload.dimensions;
            }
        }
    },
    updateProductVariantsDiscounts( state, payload ){
        if( payload.variantList ){
            for( let index in state.productInformation.variants  ){
                state.productInformation.variants[index].discount_percent = 0;
            }
            for( let id of payload.variantList ){
                let tempIndex = state.productInformation.variants.findIndex( (variant) => { return variant.id == id }  );
                if (tempIndex >= 0) {
                    state.productInformation.variants[tempIndex].discount_percent = payload.discount.percent;
                }
            }
        }
        state.productInformation.discounts.unshift(payload.discount);
    },
};

// actions
const actions = {
    fnApiGetProduct: async (context, productId) => {
        await axiosShopify.get(`products/${productId}`).then((response) => {
            let tempVariants = [];
            if (response.data.variants.length > 0) {
                for (let variant of response.data.variants) {
                    if (variant.name != "Default Title") {
                        tempVariants.push(variant);
                    }
                }
            } else {
                tempVariants = [];
            }
            response.data.variants = tempVariants;
            context.commit('setProduct', response.data);
        }).catch(error => {
            context.commit('setProductError', true);
        });
    },
    fnApiChangeStatusProduct(context, fnSuccess) {
        return axiosShopify.put(`products/${ context.state.productInformation.id }`, {
            active: !context.state.productInformation.active
        }).then( ressponse => { if (fnSuccess){fnSuccess()} }).catch(error => {
            if (error.response.data.message = 'The product is out stock.') {
                context.commit('setModalSellOutOfStock', true);
            }
            context.state.productInformation.active = !context.state.productInformation.active;
        });
    },

    fnApiSellOutOfStock: async (context, fnSuccess) => {
        let sell_out_stock = !context.getters.getSellOutOfStock;
        axiosShopify.put(`products/${ context.state.productInformation.id }`, {
            sell_out_stock ,
        }).then((response) => {

            context.state.modalSellOutOfStock = false;
            context.state.productInformation.sell_out_stock = sell_out_stock;
            context.state.productInformation.active = sell_out_stock;
            
            if( context.state.productInformation.sell_out_stock){
                for (let index in context.state.productInformation.variants) {
                    context.state.productInformation.variants[index].active = true;
                }
            }
            if(fnSuccess){
                fnSuccess();
            }
        }).catch((error) => {
            context.state.productInformation.sell_out_stock = !context.state.productInformation.sell_out_stock;
        });
    },

    fnApiChangeStatusVariant(context, {
        index,
        fnSuccess
    }) {
        axiosShopify.put(`products/${ context.state.productInformation.id }/variants/${ context.state.productInformation.variants[index].id }`, {
            active: !context.state.productInformation.variants[index].active
        }).then((response) => {
            fnSuccess();
        }).catch((error) => {
            context.state.productInformation.variants[index].active = !context.state.productInformation.variants[index].active;
            if (error.response.data.message = 'The product is out stock.') {
                context.commit('setModalSellOutOfStock', true);
            }
        });
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}