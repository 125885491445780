import Vue from 'vue';
import i18n from '@/i18n';

import {
    EcCartModel,
    EcStoresPagination,
    EcNewOrderModel,
    EcStoreAddressModel,
} from "@/plugins/beans.js"

const defaultState = () => {
    return {
        newCart: EcCartModel(),
        orderInformation: EcNewOrderModel(),
        storeList: [],
        shippingRates: [],
        storeId: null,
        pagination: EcStoresPagination(),
        showRatesBtn: false,
        showShippingRates: false,
        extras: {
            shippingAddress: false,
            shippingRates: false,
        },
        loading: {
            billingAddress: false,
            shippingAddress: false,
            shippingRates: false,
            deleteAddress: null,
            save: false,
            data: true,
        },
        modals: {
            billingAddress: false,
            shippingAddress: false,
            shippingRates: false,
        },
        show: {
            billingAddress: false,
            shippingAddress: false,
            shippingRates: false,
        },
        addresses: {
            shippingAddress: EcStoreAddressModel(),
            billingAddress: EcStoreAddressModel(),
        }
    };
}

const state = defaultState();

// getters
const getters = {}

// mutations
const mutations = {
    changeLoading(state, status) {
        state.loading = {
            ...state.loading,
            ...status
        };
    },
    changeExtras(state, status) {
        state.extras = {
            ...state.extras,
            ...status
        };
    },
    changeModal(state, modal) {
        state.modals = {
            ...state.modals,
            ...modal
        };
    },
    deleteItem(state, index) {
        state.newCart.totals.subtotal = state.newCart.totals.subtotal - state.newCart.items[index].total;
        state.newCart.totals.total = state.newCart.totals.total - state.newCart.items[index].total;
        state.newCart.items.splice(index, 1);
    },
    setCart(state, newCart) {
        if (newCart.shipping_address && newCart.shipping_address.address1) {
            state.show.shippingAddress = true;
        } else {
            state.show.shippingAddress = false;
        }
        if (newCart.billing_address && newCart.billing_address.address1) {
            state.show.billingAddress = true;
        } else {
            state.show.billingAddress = false;
        }
        if (newCart.shipping_items && newCart.shipping_items[0]) {
            let tmepItem = newCart.shipping_items.find(store => store.rates.find(rate => rate.selected));
            if (tmepItem !== undefined) {
                state.show.shippingRates = true;
                newCart.shipping_items.map(store => {
                    store.showRate = false;
                    store.rateSelected = store.rates.find(rate => rate.selected);
                    delete store.rates;
                });
            }
        } else {
            state.show.shippingRates = false;
        }
        if (newCart.items) {
            newCart.items.map(item => {
                item.loading = {
                    deleting: false,
                    updating: false,
                };
            });
        }
        state.newCart = {
            ...state.newCart,
            ...newCart,
        };
    },

    setOrderInformation(state, orderInformation) {
        state.orderInformation = {
            ...state.orderInformation,
            ...orderInformation,
        };
        document.getElementById('dashboardTItle').innerHTML = `${i18n.t('general.order') + ' : '} <span class='text-success'> ${state.orderInformation.number} </span> `;
    },


    // STORES
    setStoreList(state, list) {
        state.storeList = list;
    },
    setStoreId(state, storeId) {
        state.storeId = storeId;
    },
    // Set Addresses
    setAddresss(state, [address, type]) {
        state.newCart[type] = {
            ...state.newCart[type],
            ...address
        };
    },
    editAddress(state, type) {
        if (type == 'shippingAddress') {
            state.addresses.shippingAddress = state.newCart.shipping_address;
            state.modals.shippingAddress = true;
        } else {
            state.addresses.billingAddress = state.newCart.billing_address;
            state.modals.billingAddress = true;
        }
    },
    setShippingRates(state, shippingRates) {
        state.shippingRates = shippingRates;
    },
    deleteShippingItem(state, storeId) {
        let tempIndex = state.newCart.shipping_items.findIndex(item => item.store_id == storeId);
        state.newCart.shipping_items.splice(tempIndex, 1);
    },
    resetState(state) {
        Object.assign(state, defaultState())
    },
};

// actions
const actions = {
    // STORES
    async fnApiGetStores(context) {
        let {
            name,
            ecommerce,
            active,
            limit,
            page
        } = {
            ...context.state.pagination
        };
        let tempPagination = {
            page,
            limit
        };
        if (name != null && name != '') {
            tempPagination.name = name
        };
        if (ecommerce != null && ecommerce != '') {
            tempPagination.ecommerce = ecommerce
        };
        if (active !== null && active !== '') {
            tempPagination.active = active
        };
        context.commit('setStoreList', []);
        await axios.get('stores', {
            params: tempPagination
        }).then((response) => {
            context.commit('setStoreList', response.data);
        }).catch();
    },

    // CART
    fnApiCreateCart(context) {
        axios.post(`carts`, {
            currency: "MXN"
        }).then((response) => {
            context.commit('setCart', response.data);
        }).catch((error) => {
            // console.log(error);
        });
    },

    async fnApiGetCart(context, cartId) {
        context.commit('changeLoading', {
            data: true
        });
        await axios.get(`carts/${cartId}`).then(async (response) => {
            context.commit('setCart', response.data);
            if (response.data.order_id) {
                await context.dispatch('fnApiGetOrderInformation', response.data.order_id);
            }
        }).catch((error) => {});
        context.commit('changeLoading', {
            data: false
        });
    },

    async fnApiCreateOrder(context) {
        context.commit('changeLoading', {
            order: true
        });
        await axios.post(`owner/orders/${context.state.newCart.id}`).then(async (response) => {
            setTimeout( async () => {
                await context.dispatch('fnApiGetCart',context.state.newCart.id);
            }, 500);
        }).catch((error) => {});
        context.commit('changeLoading', {
            order: false
        });
    },

    // Udpate cart 
    async fnApiUpdateAddress(context, [address, type]) {
        context.commit('changeLoading', {
            [type]: true
        });
        await axios.put(`carts/${context.state.newCart.id}`, {
            [type == 'shippingAddress' ? 'shipping_address' : 'billing_address']: address,
        }).then((response) => {
            context.commit('setCart', response.data);
            context.commit('changeModal', {
                [type]: false
            });
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
        }).catch(error => false);
        context.commit('changeLoading', {
            [type]: false
        });
    },
    async fnApiUpdateEmail(context, create) {
        if (!create) {
            context.commit('changeLoading', {
                save: true
            });
        } else {
            context.commit('changeLoading', {
                order: true
            });
        }
        await axios.put(`carts/${context.state.newCart.id}`, {
            email: context.state.newCart.email,
        }).then((response) => {
            context.commit('setCart', response.data);
            if (!create) {
                Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
            }
        }).catch(error => false);
        if (!create) {
            context.commit('changeLoading', {
                save: false
            });
        }
    },

    // Shipping rates
    async fnApiCreateRates(context) {
        context.commit('changeLoading', {
            rates: true
        });
        context.commit('changeModal', {
            shippingRates: true
        });
        context.commit('setShippingRates', []);
        context.commit('setCart', {
            ...context.state.newCart,
            shipping_items: [],
            totals: {
                ...context.state.newCart.totals,
                total: context.state.newCart.totals.total - context.state.newCart.totals.shipping,
                shipping: 0,
            }
        });
        let shippingRates = [];
        for await (let storeId of context.state.newCart.store_ids) {
            await axios.post(`carts/${context.state.newCart.id}/shipping/rates?store_id=${storeId}`).then((response) => {
                shippingRates.push({
                    store: context.state.storeList.find(store => store.id == storeId),
                    rates: response.data,
                    option: null,
                });
            }).catch(error => false);
        }
        context.commit('setShippingRates', shippingRates);
        context.commit('changeLoading', {
            rates: false
        });
    },

    async fnApiUpdateRates(context) {
        context.commit('changeLoading', {
            updatignRates: true
        });

        let lastRate = {};
        for await (let rate of context.state.shippingRates) {
            await axios.put(`carts/${context.state.newCart.id}/shipping/rates/${rate.option}`, {
                store_id: rate.store.id
            }).then((response) => {
                lastRate = response.data;
            }).catch(error => false);
        }
        context.commit('setCart', lastRate);
        context.commit('changeModal', {
            shippingRates: false
        });
        context.commit('changeLoading', {
            updatignRates: false
        });
    },

    async fnApiDeleteRates(context, showAlert) {
        let ratesDeleted = 0;
        let ratesLength = Number(context.state.newCart.shipping_items.length);
        if (ratesLength == 0) {
            return false;
        }

        context.commit('changeLoading', {
            updatignRates: true
        });

        for await (let storeId of context.state.newCart.store_ids) {
            await axios.delete(`carts/${context.state.newCart.id}/shipping-item/${storeId}`).then((response) => {
                ratesDeleted++;
                context.commit('setCart', response.data);
            }).catch(error => {});
        }
        if (showAlert) {
            if (ratesDeleted == ratesLength) {
                Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
            } else {
                Vue.toasted.global.infoMessage(i18n.t('toasted.info.update') + 'Algo salio mal');
            }
        }

        context.commit('changeLoading', {
            updatignRates: false
        });
    },

    // ITEMS
    async fnApiUpdateItem(context, index) {
        context.dispatch('fnApiDeleteRates');
        let itemData = context.state.newCart.items[index];
        itemData.loading.updating = true;
        if (itemData.quantity <= 0) {
            await context.dispatch('fnApiDeleteItem', index);
        } else {
            await axios.put(`carts/${context.state.newCart.id}/items/${itemData.id}`, {
                quantity: itemData.quantity,
            }).then((response) => {
                context.commit('setCart', response.data);
                Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
            }).catch(error => false);

        }
        itemData.loading.updating = false;
    },

    async fnApiDeleteItem(context, index) {
        context.dispatch('fnApiDeleteRates');
        let itemData = context.state.newCart.items[index];
        itemData.loading.deleting = true;
        await axios.delete(`carts/${context.state.newCart.id}/items/${itemData.id}`).then((response) => {
            context.commit('deleteItem', index);
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.productDelete'));
        }).catch(error => false);
        itemData.loading.deleting = false;
    },

    async fnApiDeleteAddress(context, type) {
        // let itemData = context.state.newCart.items[index];
        context.commit('changeLoading', {
            deleteAddress: type
        });
        if (type == 'shipping') {
            context.dispatch('fnApiDeleteRates');
        }
        await axios.delete(`carts/${context.state.newCart.id}/sb-address?option=${type}`).then((response) => {
            context.commit('setCart', response.data);
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
        }).catch(error => false);
        context.commit('changeLoading', {
            deleteAddress: null
        });
    },

    async fnApiGetOrderInformation(context, orderId) {
        await axios.get(`owner/orders/${orderId}`).then((response) => {
            context.commit('setOrderInformation', response.data);
        }).catch((error) => {});
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}