 
 import Vue from 'vue';
 import i18n from '@/i18n';
 
 const state = {
     questionInformation: {
         answer: null,
     },
     loading: {
         data: true,
         sendingData: false,
     }
 }
 // getters
 const getters = {}
 // mutations
 const mutations = {
     changeLoading(state, status) {
         state.loading = {
             ...state.loading,
             ...status
         };
     },

     setQuestionInformation(state, question) {
         state.questionInformation = {...state.questionInformation,...question};
     },
 };

 // actions
 const actions = {
     // Quesiton 
     async fnApiGetQuestion(context, quesitonId) {
         context.commit('changeLoading', {
             data: true,
         });
         await axios.get(`products/questions/${quesitonId}`).then(async (response) => {
             context.commit('setQuestionInformation', response.data);
         }).catch(error => {});
         context.commit('changeLoading', {
             data: false,
         });
     },

     async fnApiAnswerQuestion(context, answer) {
         context.commit('changeLoading', {
             sendingData: true,
         });
         await axios.put(`products/questions/${context.state.questionInformation.id}/answer`, {
             answer
         }).then(async (response) => {
             context.commit('setQuestionInformation', {
                 ...context.state.questionInformation,
                 ...response.data
                });
                Vue.toasted.global.infoMessage(i18n.t('toasted.info.informationUpdated'));
         }).catch(error => {});
         context.commit('changeLoading', {
             sendingData: false,
         });
     },

 }

 export default {
     namespaced: true,
     state,
     getters,
     actions,
     mutations
 }