 
const state = {
  searchData: [],
  stepNum: 0,
  showLoader: true,
}

// getters
const getters = {
  getLanguage(state) {
    return state.language;
  }
}

// mutations
const mutations = {
  setStepNumber(state, num) {
    state.stepNum = num;
  },
  setLoader(state, status) {
    state.showLoader = status;
  }
};

// actions
const actions = {
  searchTerm: (context, term) => {
    context.commit('searchTerm', term)
  },
  setNavActive: (context, item) => {
    context.commit('setNavActive', item)
  },
  setActiveRoute: (context, item) => {
    context.commit('setActiveRoute', item)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}