import Vue from 'vue';
import i18n from '@/i18n'

const state = {
  carriersList: [],
  carriersPagination: {
    country: 'MX',
    storeId: null,
  },
  loading: {
    data: false,
  },
}

const getters = {}

const mutations = {
  setCarriersList(state, carriersList) {
    carriersList = Vue.prototype.$lodash.sortBy(carriersList, ['name'])
    for (let index in carriersList) {
      carriersList[index].showServices = false;
    }
    state.carriersList = carriersList;
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  changeCarrierStatus(state, index) {
    state.carriersList[index].active = !state.carriersList[index].active;
  },
  revertServiceStatus(state, [indexCarrier, indexService]) {
    state.carriersList[indexCarrier].services[indexService].active = !state.carriersList[indexCarrier].services[indexService].active;
  }
};

// actions
const actions = {

  async fnApiGetCarriers(context) {
    context.commit('changeLoading', {
      data: true,
    });
    let {
      country,
    } =  context.state.carriersPagination ;
    await axios.get('carriers', {
      params: {
        country
      }
    }).then(response => {
      context.commit('setCarriersList', response.data);
    }).catch(err => {});
    context.commit('changeLoading', {
      data: false,
    });
  },

  async fnApiUpdateService({
    state,
    commit,
  }, [indexCarrier, indexService]) {
    let {
      id
    } = state.carriersList[indexCarrier];
    let active = !state.carriersList[indexCarrier].services[indexService].active;
    let idService = state.carriersList[indexCarrier].services[indexService].id;

    await axios.put(`carriers/${id}/services/${idService}`, {
      active
    }).then(response => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.carrierUpdated'));
    }).catch(err => {
      commit('revertServiceStatus',[indexCarrier, indexService]);
    });

  },

  async fnApiUpdateCarrier({
    state,
    commit
  }, indexCarrier) {
    let {
      active,
      id
    } = state.carriersList[indexCarrier];
    await axios.put('carriers/' + id, {
      active: !active,
    }).then(response => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.carrierUpdated'));
      commit('changeCarrierStatus', indexCarrier);
    }).catch(err => { });
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}