import {
  EcProductModel,
  EcStoreModel
} from "@/plugins/beans.js"
import Vue from 'vue';
import router from "@/router";
import i18n from '@/i18n';

const defaultState = () => {
  return {
    productInformation: EcProductModel(),
    product: EcProductModel(),
    storeInformation: EcStoreModel(),
    productTranslations: [],
    availableLanguages: [],
    productError: false,
    modalSellOutOfStock: false,
    loading: {
      newImage: false,
      deleteImage: false,
      sync: false,
      data: true,
      variants: false,
      storeData: false,
      attributes: false,
    },
    productLanguage: null,
    questionsList: {
      questions: null,
      total: null,
    },
    modals: {
      questions: false,
      reviews: false,
      newVariant: false,
      images: false,
      attributes: false,
    },
    newProduct: null,
    newVariant: {
      sku: null,
      name: null,
      quantity: null,
      price: null,
      option1: null,
      option2: null,
      option3: null,
      dimensions: {
        width: null,
        height: null,
        length: null,
        weight: null,
      },
      units: {
        width: 'cm',
        height: 'cm',
        length: 'cm',
        weight: 'kg',
      },
    },
    optionsSelectedList: [],
    newVariants: [],
    newOptions: [],
    optionsNameList: [{
      name: 'color',
      index: null,
    }, {
      name: 'size',
      index: null,
    }, {
      name: 'material',
      index: null,
    }, {
      name: 'style',
      index: null,
    }],
    variantId: null,
    attributesList: [],
  }
}

const state = defaultState();

const getters = {
  getSellOutOfStock(state) {
    return state.product.sell_out_stock;
  },
  getVariantsList(state) {
    return state.productInformation.variants;
  },
  getAvailableLanguages(state) {
    let tempLanguages = [...state.availableLanguages];
    state.productTranslations.map(translation => {
      let tempIndex = tempLanguages.findIndex(language => (language.language == translation.language));
      if (tempIndex >= 0) {
        tempLanguages.splice(tempIndex, 1);
      }
    });
    return tempLanguages;
  },
}

const mutations = {
  changeModal(state, status) {
    state.modals = {
      ...state.modals,
      ...status
    };
  },
  setProduct: async (state, productInformation) => {
    let tempVariants = [];
    if (productInformation.variants.length > 0) {
      for await (let variant of productInformation.variants) {
        if (variant.image_id == undefined) {
          variant.image_id = productInformation.imageUrl || null;
        }
        if (variant.name != "Default Title") {
          tempVariants.push(variant);
        }
      }
    } else {
      tempVariants = [];
    }
    productInformation.variants = tempVariants;
    state.productInformation = {
      ...state.productInformation,
      ...productInformation
    };

    state.product = {
      ...state.product,
      ...productInformation
    };
  },
  setNewVariants(state, [optionsList, variantsList]) {
    let tempVariants = [];
    for (let variant of variantsList) {
      if (variant.image_id == undefined) {
        variant.image_id = state.productInformation.imageUrl;
      }
      if (variant.name != "Default Title") {
        tempVariants.push(variant);
      }
    }
    state.productInformation.variants = tempVariants;
    state.product.variants = tempVariants;
    state.productInformation.options = optionsList;
    state.product.options = optionsList;
  },
  setProductTranslations: (state, translations) => {
    state.productTranslations = translations;
  },
  setProductLanguage(state, language) {
    state.productLanguage = language;
  },
  deleteTransalation(state, language) {
    let tempData = state.productTranslations.filter(translation => (translation.language != language));
    state.productTranslations = [...tempData];
  },
  setAvailableLanguages: (state, languages) => {
    languages = languages.filter(language => (language.language != state.product.language));
    state.availableLanguages = Vue.prototype.$lodash.sortBy(languages, ['language']);
  },

  setProductError: (state, payload) => {
    state.productError = payload;
  },
  setModalSellOutOfStock: (state, payload) => {
    state.modalSellOutOfStock = payload;
  },
  updateProductVariants(state, payload) {
    state.product.dimensions = payload.dimensions;
    state.product.variants = payload.variants;
  },
  updateProductDimensions(state, payload) {
    state.product.dimensions = payload.dimensions;
  },

  updateProductVariantsDimensions(state, payload) {
    for (let id of payload.variantList) {
      let tempIndex = state.product.variants.findIndex((variant) => {
        return variant.id == id
      });
      if (tempIndex >= 0) {
        state.product.variants[tempIndex].dimensions = payload.dimensions;
      }
    }
  },

  updateProductVariantsDiscounts(state, payload) {
    if (payload.variantList) {
      for (let index in state.product.variants) {
        state.product.variants[index].discount_percent = 0;
      }
      for (let id of payload.variantList) {
        let tempIndex = state.product.variants.findIndex((variant) => {
          return variant.id == id
        });
        if (tempIndex >= 0) {
          state.product.variants[tempIndex].discount_percent = payload.discount.percent;
        }
      }
    }
    state.product.discounts.unshift(payload.discount);
  },

  deleteProductImage(state, index) {
    state.productInformation.images.splice(index, 1);
    state.product.images.splice(index, 1);
  },

  addProductImage(state, images) {
    state.productInformation.images = state.productInformation.images.concat(images);
    state.product.images = state.product.images.concat(images);
  },

  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },

  updateGeneralInformation(state, productInformation) {
    state.productInformation.name = productInformation.name;
    state.productInformation.sku = productInformation.sku;
    state.productInformation.price = productInformation.price;
    state.productInformation.quantity = productInformation.quantity;
    state.productInformation.description = productInformation.description;
    state.productInformation.category = productInformation.category;
    state.productInformation.dimensions = productInformation.dimensions;
    state.productInformation.completed = productInformation.completed;
    state.productInformation.tips = productInformation.tips;
  },
  setNewProduct(state, status) {
    state.newProduct = status;
  },
  setStoreInformation(state, storeInformation) {
    state.storeInformation = {
      ...storeInformation
    };
  },
  resetState(state) {
    Object.assign(state, defaultState())
  },

  // VARIANTS
  setVariants(state, variants) {
    state.newVariants = variants;
  },
  setOptions(state, options) {
    state.newOptions = options;
  },
  variantsAddNewOption(state) {
    for (let option of state.optionsNameList) {
      if (option.index === null) {
        state.optionsSelectedList.push({
          ...option,
          options: []
        })
        if (state.optionsSelectedList.length > 0) {
          option.index = state.optionsSelectedList.length - 1;
        }
        break;
      }
    }
  },
  deleteNewVariant(state, index) {
    state.newVariants.splice(index, 1);
  },
  variantsChangeOption(state, [index, optionName]) {
    state.optionsNameList.map(option => {
      if (option.index == index) {
        option.index = null;
      }
      if (option.name == optionName) {
        option.index = index;
      }
    });
  },
  resetVariants(state) {
    state.optionsSelectedList = [];
    state.newVariants = [];
    state.newOptions = [];
    state.optionsNameList = [{
      name: 'color',
      index: null,
    }, {
      name: 'size',
      index: null,
    }, {
      name: 'material',
      index: null,
    }, {
      name: 'style',
      index: null,
    }];

  },
  editVariants(state) {
    state.productInformation.options.map(productOption => {
      for (let option of state.optionsNameList) {
        if (option.name === productOption.name) {
          state.optionsSelectedList.push({
            ...option,
            options: productOption.values,
          })
          if (state.optionsSelectedList.length > 0) {
            option.index = state.optionsSelectedList.length - 1;
          }
          break;
        }
      }
    });
    setTimeout(() => {
      state.newVariants = [];
      state.productInformation.variants.map(variant => state.newVariants.push({
        ...variant
      }));
      state.modals.newVariant = true;
    }, 100);
  },
  removeOption(state, index) {
    for (let option of state.optionsNameList) {
      if (option.name === state.optionsSelectedList[index].name) {
        option.index = null;
        break;
      }
    }
    state.optionsSelectedList.splice(index, 1);
  },

  deleteVariant(state, index) {
    state.product.variants.splice(index, 1);
    state.productInformation.variants.splice(index, 1);
  },

  updateVariant(state, variantInfo) {
    let index = state.productInformation.variants.findIndex(variant => variant.id == state.variantId);
    state.productInformation.variants.splice(index, 1, {
      ...state.productInformation.variants[index],
      ...variantInfo
    });
    state.modals.images = false;
    state.variantId = null;
  },

  setVariantId(state, variantId) {
    if (variantId) {
      state.modals.images = true;
    }
    state.variantId = variantId;
  }
};

// actions
const actions = {

  fnApiGetProduct: async (context, productId) => {
    context.commit('setProductTranslations', []);
    context.commit('setProductError', false);

    await axios.get(`products/${productId}`).then( async (response) => {
      context.commit('setProduct', response.data);
      context.commit('setProductTranslations', response.data.translations);
      context.dispatch('fnApiGetStore', response.data.store_id);
      context.dispatch('fnApiGetLanguages');
      if (response.data.category.category_id) {
        await context.dispatch('fnApiGetAttributes', response.data.category.category_id);
      }
    }).catch(error => {
      context.commit('setProductError', true);
    });
  },

  async fnApiSyncProduct(context) {
    context.commit('changeLoading', {
      sync: true
    });
    context.commit('setProductLanguage', null);
    await axios.get(`products/synchronize/ecommerce/${state.product.id}`).then((response) => {
      context.commit('setProduct', response.data);
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.productSync'));
    }).catch(error => {});
    context.commit('changeLoading', {
      sync: false
    });
  },

  async fnApiGetLanguages(context) {
    await axios.get(`languages`).then((response) => {
      context.commit('setAvailableLanguages', response.data);
    }).catch(error => {});
  },

  fnApiChangeStatusProduct(context) {
    return axios.put(`products/${ context.state.productInformation.id }`, {
      active: !context.state.productInformation.active
    }).then(ressponse => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
    }).catch(error => {
      if (error.response.data.message = 'The product is out stock.') {
        context.commit('setModalSellOutOfStock', true);
      }
      context.state.productInformation.active = !context.state.productInformation.active;
    });
  },

  fnApiSellOutOfStock: async (context) => {
    let sell_out_stock = !context.getters.getSellOutOfStock;
    axios.put(`products/${ context.state.product.id }`, {
      sell_out_stock,
    }).then((response) => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));

      context.state.modalSellOutOfStock = false;

      context.state.product.sell_out_stock = sell_out_stock;
      context.state.product.active = sell_out_stock;

      context.state.productInformation.sell_out_stock = sell_out_stock;
      context.state.productInformation.active = sell_out_stock;

      if (context.state.product.sell_out_stock) {
        for (let index in context.state.product.variants) {
          context.state.product.variants[index].active = true;
        }
      }

      if (context.state.productInformation.sell_out_stock) {
        for (let index in context.state.productInformation.variants) {
          context.state.productInformation.variants[index].active = true;
        }
      }
    }).catch((error) => {
      context.state.product.sell_out_stock = !context.state.product.sell_out_stock;
    });
  },

  fnApiChangeStatusVariant(context, index) {
    axios.put(`products/${ context.state.product.id }/variants/${ context.state.product.variants[index].id }`, {
      active: !context.state.product.variants[index].active
    }).then((response) => {
      context.state.product.variants[index].active = !context.state.product.variants[index].active;
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
    }).catch((error) => {
      if (error.response.data.message = 'The product is out stock.') {
        context.commit('setModalSellOutOfStock', true);
      }
    });
  },

  async fnApiUpdateImage(context, imagesList) {
    context.commit('changeLoading', {
      newImage: true
    });
    await axios.put(`products/images/${ context.state.product.id }`, {
      images: imagesList
    }).then(response => {
      context.commit('addProductImage', response.data);
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.productImagesUpdated'));
    }).catch(error => {});
    context.commit('changeLoading', {
      newImage: false,
    });
  },

  async fnApiDeleteImage(context, index) {
    context.commit('changeLoading', {
      deleteImage: true
    });
    await axios.delete(`products/${ context.state.product.id }/images/${ context.state.product.images[index].id }`).then(response => {
      context.commit('deleteProductImage', index);
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.productImagesUpdated'));
    }).catch(error => {});
    context.commit('changeLoading', {
      deleteImage: false
    });
  },

  async fnApiDeleteProduct(context) {

    context.commit('changeLoading', {
      deleteProduct: true
    });

    await axios.delete(`products/delete/${ context.state.product.id }`).then(response => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.productDelete'));
      router.push('/dashboard/products');
    }).catch(error => {});

    context.commit('changeLoading', {
      deleteProduct: false
    });

  },

  async fnApiGetStore(context, storeId) {
    context.commit('changeLoading', {
      storeData: true
    });
    await axios.get(`stores/${storeId}`).then((response) => {
      context.commit('setStoreInformation', response.data);
    }).catch(error => {});
    context.commit('changeLoading', {
      storeData: false
    });
  },

  // New variants
  async fnApiCreateVaraint({
    state,
    commit
  }) {
    commit('changeLoading', {
      variants: true,
    });

    state.newVariants.map(variant => {
      variant.image_id == variant.image_id || state.productInformation.imageUrl;
    })

    let tempVairants = [...state.newVariants];
    tempVairants.map(variant => {
      for (let index in variant.options) {
        variant['option' + (Number(index) + 1)] = variant.options[index];
      }
      delete variant.options;
    })
    let options = [];
    state.optionsSelectedList.map(opt => {
      if (opt.options.length > 0) {
        options.push({
          name: opt.name,
          values: opt.options
        });
      }
    })

    if (state.productInformation.variants.length > 0) {
      await axios.put(`products/variants/${state.productInformation.id}`, {
        options: options,
        variants: tempVairants,
      }).then(response => {
        commit('setNewVariants', [response.data.options, response.data.variants]);
        commit('resetVariants');
        commit('changeModal', {
          newVariant: false
        });
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.variantsUpdated'));
      }).catch((error) => error);
    } else {
      await axios.post(`products/variants/${state.productInformation.id}`, {
        options: options,
        variants: tempVairants,
      }).then(response => {
        commit('setNewVariants', [response.data.options, response.data.variants]);
        commit('resetVariants');
        commit('changeModal', {
          newVariant: false
        });
      }).catch((error) => error);
    }
    commit('changeLoading', {
      variants: false,
    });
  },

  async fnApiDeleteVariant(context, index) {
    await axios.delete(`products/variants/${context.state.product.id}`, {
      data: {
        variant_id: context.state.product.variants[index].id
      }
    }).then(() => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.variantDeleted'));
      context.commit('deleteVariant', index);
    }).catch(error => error);
  },

  async fnApiUpdateVariant(context, variant) {
    context.commit('changeLoading', {
      variants: true
    });
    let tempVariant = {};
    ['name', 'price', 'dimensions', 'sku', 'image_id', 'quantity'].map(attr => {
      tempVariant[attr] = variant[attr];
    })
    await axios.put(`products/${context.state.productInformation.id}/variants/${context.state.variantId}`, tempVariant).then((response) => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.variantUpdated'));
      context.commit('updateVariant', response.data);
    }).catch(error => error);
    context.commit('changeLoading', {
      variants: false
    });
  },

  async fnApiUpdateDefaultImage(context, index) {
    context.commit('changeLoading', {
      deleteImage: true
    });
    await axios.put(`products/${ context.state.productInformation.id }`, {
      imageUrl: context.state.productInformation.images[index].url
    }).then(response => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
      context.commit('setProduct', response.data);
    }).catch(error => error);
    context.commit('changeLoading', {
      deleteImage: false
    });
  },

  async fnApiGetAttributes(context, categoryId) {
    let language = ''
    switch (context.state.productInformation.language) {
      case 'spanish':
        language = 'es-ES';
        break;
      default:
        language = 'en-US';
        break;
    }
    if (categoryId == undefined) {
      return null;
    }
    await axios.get('categories/attributes', {
      params: {
        lang: language,
        category_id: categoryId,
      }
    }).then((response) => {
      response.data.map(attribute => {
        attribute.value = false;
        attribute.attribSelected = null;
        if (attribute.values) {
          attribute.values.map(attrVal => {
            attrVal.optionsSelected = [];
            if (attrVal.options && attrVal.options.length > 0) {
              let tempOptions = [];
              attrVal.options.map(attrOpt => {
                tempOptions.push({
                  name: attrOpt,
                })
              });
              attrVal.options = tempOptions;
            }
          })
        }
      });
      context.state.attributesList = response.data;

      if (context.state.productInformation.category.category_id) {
        if (context.state.productInformation.attributes.length > 0) {
          context.state.productInformation.attributes.map(productAttrib => {
            let attribIndex = context.state.attributesList.findIndex(itemAttrib => itemAttrib.id == productAttrib.attribute_id);
            context.state.attributesList[attribIndex].value = true;
            productAttrib.values.map(value => {
              context.state.attributesList[attribIndex].attribSelected = value.id;
              let valueIndex = context.state.attributesList[attribIndex].values.findIndex(itemVal => itemVal.id == value.id);
              if (context.state.attributesList[attribIndex].values[valueIndex].options) {
                context.state.attributesList[attribIndex].values[valueIndex].optionsSelected.push({name: value.value});
              }
            });
          });
        }
      }
    }).catch(error => {});
  },

  async fnApiUpdateAttributes(context, attributes) {
    context.commit('changeLoading', {
      attributes: true
    });
    await axios.put(`products/${ context.state.productInformation.id }`, {
      attributes
    }).then(ressponse => {
      context.commit('changeModal', {
        attributes: false
      });
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
    }).catch(error => {});

    context.commit('changeLoading', {
      attributes: false
    });

  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}