const state = {
    reviewInformation: {
        answer: null,
    },
    loading: {
        data: true,
        sendingData: false,
    }
}
// getters
const getters = {}
// mutations
const mutations = {
    changeLoading(state, status) {
        state.loading = {
            ...state.loading,
            ...status
        };
    },

    setReviewInformation(state, review) {
        state.reviewInformation = {
            ...state.reviewInformation,
            ...review
        };
    },
};

// actions
const actions = {
    // Review 
    async fnApiGetReview(context, reviewId) {
        context.commit('changeLoading', {
            data: true,
        });
        await axios.get(`products/reviews/${reviewId}`).then(async (response) => {
            context.commit('setReviewInformation', response.data);
        }).catch(error => {});
        context.commit('changeLoading', {
            data: false,
        });
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}