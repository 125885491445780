import Vue from "vue";
import i18n from '@/i18n';
import {
  locale
} from "moment";

const state = {
  // Notificaitons page
  notificationsList: [],
  notificationsCount: null,
  showNotifications: false,

  notificationsPagination: {
    read: false,
    page: 1,
    limit: 50,
    total: null,
  },
  loading: {
    data: false,
  },

  // Pending Notifications bar
  pendingNotificationsList: [],
  pendingNotificationsCount: null,

  
}

const getters = {
  // getLanguage(state) {
  //   return state.language;
  // }
}

const mutations = {


  // SOCKETS
  SOCKET_DISCONNECT(state) {
    // console.log('SOCKET_DISCONNECT');
  },

  SOCKET_products(state, data) {
    // Notification
    switch (data.event_type) {
      case 'question':
        Vue.toasted.global.notification(i18n.t('toasted.notifications.question'));
        break;
      case 'review':
        Vue.toasted.global.notification(i18n.t('toasted.notifications.review'));
        break;
    }
    // Set empty product
    data.product = null;

    // Notifications Page
    if (state.notificationsPagination.read === null || state.notificationsPagination.read === false) {
      state.notificationsList.unshift(data);
    }

    // Pending Notifications bar
    state.pendingNotificationsCount = state.pendingNotificationsCount + 1;
    state.pendingNotificationsList.unshift(data);

    // Notificastions sound
    var audio = new Audio(require('@/assets/sounds/notification-sound.mp3'));
    audio.play();
  },

  SOCKET_orders(state, data) {
    // Notification
    switch (data.event_type) {
      case 'delivered':
        Vue.toasted.global.notification(i18n.t('toasted.notifications.delivered'));
        break;
      case 'pending':
        Vue.toasted.global.notification(i18n.t('toasted.notifications.pending'));
        break;
    }
    // Set empty product
    data.product = null;
    // Notifications Page
    if (state.notificationsPagination.read === null || state.notificationsPagination.read === false) {
      state.notificationsList.unshift(data);
    }

    // Pending Notifications bar
    state.pendingNotificationsCount = state.pendingNotificationsCount + 1;
    state.pendingNotificationsList.unshift(data);

    // Notificastions sound
    var audio = new Audio(require('@/assets/sounds/notification-sound.mp3'));
    audio.play();
  },

  // Notifications Page
  setNotificationsList(state, notificationsList) {
    state.notificationsList = notificationsList;
  },
  setNotificationsTotal(state, count) {
    state.notificationsPagination.total = count;
  },
  changeNotifications(state, status) {
    state.showNotifications = status;
  },
  resetPagination(state, nextPage) {
    state.notificationsPagination.read = nextPage ? state.notificationsPagination.read : false;
    state.notificationsPagination.total = nextPage ? state.notificationsPagination.total : null;
    state.notificationsPagination.page = 1;
  },
  setNotificationReaded(state, notificationId) {
    state.pendingNotificationsList.map(notification => {
      notification.id == notificationId ? notification.read = true : null;
    })
  },

  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },

  // Pending Notifications bar
  setPendingNotificationsCount(state, count) {
    state.pendingNotificationsCount = count;
  },
  setPendingNotificationsList(state, pendingNotificationsList) {
    state.pendingNotificationsList = pendingNotificationsList;
  },
  resetNotifications(state) {
    state.pendingNotificationsList = [];
    state.showNotifications = false;
    state.pendingNotificationsCount = null;
  },
  
};

// actions
const actions = {

  async fnFirstPagination(context) {
    context.commit('resetPagination', true);
    context.commit('setNotificationsList', []);
    context.dispatch('fnApiGetNotificationsCount');
    context.dispatch('fnApiGetNotifications');
  },
  async fnResetPagination(context) {
    context.commit('resetPagination', false);
    context.dispatch('fnApiGetNotificationsCount');
    context.dispatch('fnApiGetNotifications');
  },

  async fnApiGetNotifications(context) {
    let {
      read,
      limit,
      page
    } = {
      ...context.state.notificationsPagination
    };
    let tempPagination = {
      page,
      limit
    };
    (read !== null && read !== '') ? tempPagination.read = read: null;
    
    context.commit('changeLoading', {
      data: true
    });
    // ?module=products
    await axios.get('me/notifications', {
      params: tempPagination
    }).then((response) => {
      response.data.map(notification => notification.product = null);
      context.commit('setNotificationsList', response.data);
      setTimeout(() => {
        document.getElementById("app").scrollIntoView({
          block: 'start',
          behavior: "smooth"
        });
      }, 500);
    }).catch();
    context.commit('changeLoading', {
      data: false
    });
  },

  async fnApiGetNotificationsCount(context) {
    let {
      read,
    } = {
      ...context.state.notificationsPagination
    };
    let tempPagination = {};
    (read !== null && read !== '') ? tempPagination.read = read: null;
    await axios.get('me/notifications/count', {
      params: tempPagination
    }).then((response) => {
      context.commit('setNotificationsTotal', response.data.count);
    }).catch();
  },

  async fnApiReadNotification(context, notificationId) {

    let tempNotification = context.state.pendingNotificationsList.find(notification => notification.id == notificationId);

    if (tempNotification == undefined) {
      tempNotification = context.state.notificationsList.find(notification => notification.id == notificationId);
    }

    if (tempNotification && (tempNotification.read == undefined || tempNotification.read == false)) {
      await axios.put(`me/notifications/read/${notificationId}`).then((response) => {
        context.commit('setNotificationReaded', notificationId);
        context.commit('setPendingNotificationsCount', context.state.pendingNotificationsCount - 1);
      }).catch();
    }
  },

  // Pending Notifications bar
  async fnApiGetPendingNotifications(context) {
    await axios.get('me/notifications?limit=20').then((response) => {
      response.data.map(notification => notification.product = null);
      context.commit('setPendingNotificationsList', response.data);
    }).catch();
  },
  async fnApiGetPendingNotificationsCount(context) {
    context.dispatch('fnApiGetCountOrdersPending');
    await axios.get('me/notifications/count?read=false').then((response) => {
      context.commit('setPendingNotificationsCount', response.data.count);
    }).catch();
  },

  // Orders
  fnApiGetCountOrdersPending(context) {
    axios.get('orders/count', {
      params: {
        status: 'shipment_pending'
      }
    }).then((response) => {
      context.commit('setOrdersCount', response.data.count,{ root: true });
    }).catch((error) => {});
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}