"use strict";
import Vue from 'vue';
import axios from "axios";
import router from '@/router'
import i18n from '@/i18n';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  baseURL: process.env.VUE_APP_ECART_API,
  timeout: 10 * 1000, // Timeout 
};

const _axios = axios.create(config);
_axios.interceptors.request.use(
  function (config) {
    config.headers.common['Authorization'] = localStorage.getItem('token') || '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.code == "ECONNABORTED") {
      Vue.toasted.global.errorMessage('Connection time exceeded, try later');
    }
    if (error.response.data.redirect_uri) {
      router.push({
        path: `${error.response.data.redirect_uri}`,
      }, () => {});
      return Promise.reject(error);
    }
    //Reset session if acces token is expired or unauthorized
    if (error.response.status == 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      if (window.location.pathname != '/login' && window.location.pathname != '/session') {
        // TODO: Remove this line if is necessary => window.location.replace("/login?session_expired=true");
        window.location.href = '/';
      } else {
        Vue.toasted.global.errorMessage(error.response.data.message);
      }
    } else if (error.response.status == 422 && router.history.current.path == "/signup") {
      Vue.toasted.global.errorMessage(i18n.t('signup.errorEmail'));
    } else {
      let message = '';
      if (error.response.status == 500) {
        message = 'Something is wrong, try later';
      } else {
        message = error.response.data.message;
      }
      Vue.toasted.global.errorMessage(message);
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

// Shopify Axios
const _axiosShopify = axios.create(config);
_axiosShopify.interceptors.request.use(
  function (config) {
    config.headers.common['Authorization'] = localStorage.getItem('tokenShopify') || '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axiosShopify.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.code == "ECONNABORTED") {
      Vue.toasted.global.errorMessage('Connection time exceeded, try later');
    }
    if (error.response.data.redirect_uri) {
      router.push({
        path: `${error.response.data.redirect_uri}`,
      }, () => {});
      return Promise.reject(error);
    }
    //Reset session if acces token is expired or unauthorized
    if (error.response.status == 401) {
      localStorage.removeItem('tokenShopify');
      localStorage.removeItem('userShopify');
      if (window.location.pathname != '/shopify') {
        router.push({
          path: "/shopify",
        }, () => {});
      } else {
        Vue.toasted.global.errorMessage(error.response.data.message);
      }
    } else if (error.response.status == 422 && (router.history.current.path == "/shopify" || router.history.current.path == "/signup")) {
      Vue.toasted.global.errorMessage('The email has already been registered');
    } else {
      let message = '';
      if (error.response.status == 500) {
        message = 'Something is wrong, try later';
      } else {
        message = error.response.data.message;
      }
      Vue.toasted.global.errorMessage(message);
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

// Shopify Oauth
const _axiosOauth = axios.create({
  baseURL: process.env.VUE_APP_ECART_API_OAUTH,
  timeout: 10 * 1000, // Timeout 
});
_axiosOauth.interceptors.request.use(
  function (config) {
    config.headers.common['Authorization'] = localStorage.getItem('token') || '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axiosOauth.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

// AXIOS ACCOUNT
const _axiosAccount = axios.create({
  baseURL: process.env.VUE_APP_ACCOUNTS_API,
  timeout: 10 * 1000, // Timeout 
});
_axiosAccount.interceptors.request.use(
  function (config) {
    config.headers.common['Authorization'] = localStorage.getItem('accountToken') || '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axiosAccount.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);


Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });

  // Shopify
  Vue.axiosShopify = _axiosShopify;
  window.axiosShopify = _axiosShopify;
  Object.defineProperties(Vue.prototype, {
    axiosShopify: {
      get() {
        return _axiosShopify;
      }
    },
    $axiosShopify: {
      get() {
        return _axiosShopify;
      }
    },
  });

  // Oauth
  Vue.axiosOauth = _axiosOauth;
  window.axiosOauth = _axiosOauth;
  Object.defineProperties(Vue.prototype, {
    axiosOauth: {
      get() {
        return _axiosOauth;
      }
    },
    $axiosOauth: {
      get() {
        return _axiosOauth;
      }
    },
  });

  // Account
  Vue.axiosAccount = _axiosAccount;
  window.axiosAccount = _axiosAccount;
  Object.defineProperties(Vue.prototype, {
    axiosAccount: {
      get() {
        return _axiosAccount;
      }
    },
    $axiosAccount: {
      get() {
        return _axiosAccount;
      }
    },
  });

};

Vue.use(Plugin)
export default Plugin;