 const state = {
   productsList: [],
 }

 // getters
 const getters = {
   // getLanguage(state) {
   //   return state.language;
   // }
 }

 // mutations

 const mutations = {
   addProducts(state, productsList) {
     state.productsList = [...state.productsList, ...productsList];
   },
 };

 // actions
 const actions = {
   async fnApiGetProductsInformation(context, productsList) {

     let tempProductsList = [];
     productsList.map(productId => {
       if (!tempProductsList.includes(productId) &&
         (context.state.productsList.findIndex(product => product.id == productId) === -1)) {
          productId ? tempProductsList.push(productId) : null;
       }
     });
     if (tempProductsList.length > 0) {
       await axios.post('products/information', {
         products: tempProductsList
       }).then((response) => {
        tempProductsList.map( productId =>{
          if( response.data.findIndex( product =>product.id == productId) === -1 ){
            response.data.push( {
              id: productId,
              name: 'notFound',
            } )
          }
        } )
         context.commit('addProducts', response.data);
       }).catch();
     }
   }
 }

 export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
 }