import Vue from 'vue';
import i18n from '@/i18n';

const state = {
  methodList: [],
  banksList: [],
  balancesList: [],
  balancesFilter: [],
  paymentList: [],
  balancesSummary: {
    summary: {},
    available: {
      mxn: null,
      usd: null,
    },
    cancelled: {
      mxn: null,
      usd: null,
    },
    paid: {
      mxn: null,
      usd: null,
    },
  },
  loading: {
    sendingNewMethod: false,
    sendingNewPayment: false,
    paymentsList: false,
  },
  paymentMethods: ['paypal', 'debit', 'clabe'],
  newMethod: {
    method: 'paypal',
    name: null,
    email: null,
    clabe: null,
    number: null,
    bank: null,
    business_name: null,
  },
  newPayment: {
    currency: null,
    type: null,
    method: null,
    balances_id: [],
    bank: null,
    total: 0,
  },
  integration: false,
  showBanksSelect: false,
  showCardName: false,
  customPayment: false,
  paymentStep: 0,
  exchangeCurrency: 'MXN',
  exchangesAvailable: [],
  exchangesList: null,
  modals:{
    paymentMethod: false,
    createPayment: false,
    extraData: false,
  }
}

// getters
const getters = {
  getBalancesFilter(state) {
    if (state.balancesList.length > 0) {
      return state.balancesList.filter(balance => balance.currency == state.newPayment.currency);
    }
    return [];
  },
  getTotalBalances(state, getters) {
    let total = 0;
    for (let balanceId of state.newPayment.balances_id) {
      let tempIndex = getters.getBalancesFilter.findIndex((balance) => (balance.id == balanceId));
      total = total + getters.getBalancesFilter[tempIndex].amount;
    }
    return total;
  },
  getMethodsPaypal(state) {
    if (state.methodList.length == 0) {
      return [];
    }
    return state.methodList.filter(method => method.method == 'paypal');
  },
  getMethodsDebit(state) {
    if (state.methodList.length > 0) {
      return state.methodList.filter(method => method.method == 'debit');
    }
    return [];
  },
  getMethodsClabe(state) {
    if (state.methodList.length > 0) {
      return state.methodList.filter(method => method.method == 'clabe');
    }
    return [];
  },

  getExchangeCurrency(state){
    if( state.exchangesList ){
      return state.exchangesList[state.exchangeCurrency] ;
    }
    return null;
  }

}

// mutations
const mutations = {
  setIntegration(state, staus) {
    state.integration = true;
  },
  setMethodList(state, list) {
    state.methodList = list;
  },
  editMethod(state, methodId) {
    let index = state.methodList.findIndex(element => element.id == methodId);
    state.newMethod = {
      ...state.newMethod,
      ...state.methodList[index],
    };
    state.modals.paymentMethod = true;
  },
  updateMtehod(state, method) {
    let index = state.methodList.findIndex(element => element.id == method.id)
    state.methodList.splice(index, 1);
    state.methodList.unshift(method);
  },
  setPaymentList(state, list) {
    state.paymentList = list;
  },
  addNewPaymentList(state, payment) {
    state.paymentList.unshift(payment);
  },
  setBalancesList(state, list) {
    state.balancesList = list;
  },
  deleteBalancesIds(state, list) {
    for (let balanceId of list) {
      state.balancesList.splice(Vue.prototype.$lodash.findIndex(state.balancesList, ['id', balanceId]), 1);
    }
  },
  setBalancesSummary(state, list) {
    state.balancesSummary = list;
  },
  setNewPaymentCurrency(state, currency) {
    state.newPayment.currency = currency;
    state.newPayment.balances_id = [];
  },
  addNewMethodList(state, method) {
    state.methodList.push(method);
  },
  deleteMethodList(state, methodId) {
    let index = state.methodList.findIndex(element => element.id == methodId);
    state.methodList.splice(index, 1);
  },
  changeModal(state, modal) {
    state.modals = {...state.modals , ...modal };
  },
  resetPaymentMethod(state, payload) {
    state.modals.paymentMethod = false;
    state.newMethod = {
      method: 'paypal',
      name: null,
      email: null,
      clabe: null,
      number: null,
      bank: null,
      business_name: null,
    };
  },

  resetNewPayment(state) {
    state.modals.createPayment = false;
    state.showBanksSelect = false;
    state.showCardName = false;
    state.customPayment = false;
    state.paymentStep = 0;
    state.newPayment = {
      currency: null,
      type: null,
      method: null,
      balances_id: [],
      bank: null,
      total: 0,
    }
  },

  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  setPaymentStep(state, step) {
    state.paymentStep = step;
  },
  setBanks(state, banksList) {
    state.banksList = banksList;
  },
  setBanksSelect(state, status) {
    state.showBanksSelect = status;
  },
  setCardname(state, status) {
    state.showCardName = status;
  },
  setCustomPayment(state, status) {
    state.customPayment = status;
  },
  setExchangesAvailable(state, exchanges) {
    state.exchangesAvailable = exchanges;
  },
  setExchangesList(state, exchanges) {
    state.exchangesList = exchanges;
  },
};


// actions
const actions = {
  fnApiDeleteMethod(context, methodId) {
    axiosShopify.delete(`me/payments/method/${methodId}`).then(response => {
      context.commit('deleteMethodList', methodId);
    }).catch(error => {});
  },
  async fnApiCloseOrders() {
    await axiosShopify.post(`orders/close`).then(response => {
    }).catch(error => {});
  },
  async fnApiAddNewMethod(context, newMethod) {
    context.commit('changeLoading', {
      sendingNewMethod: true,
    });
    const {
      id,
      method,
      name,
      email,
      number,
      bank,
      business_name
    } = newMethod;
    let tempMethod = {
      method
    };
    if (name != null) {
      tempMethod.name = name;
    };
    switch (method) {
      case 'paypal':
        tempMethod.email = email;
        break;
      case 'debit':
        tempMethod.number = number;
        tempMethod.bank = bank;
        tempMethod.business_name = business_name;
        break;
      case 'clabe':
        tempMethod.number = number;
        tempMethod.business_name = business_name;
        tempMethod.bank = bank;
        break;
    }
    let success = false;
    if (id) {
      success = await axiosShopify.put('me/payments/method/' + id, tempMethod).then(response => {
        context.commit('updateMtehod', response.data);
        context.commit('resetPaymentMethod');
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.paymentMethodUpdated'));
        return true
      }).catch(error => {
        return false
      });
    } else {
      success = await axiosShopify.post('me/payments/method', tempMethod).then(response => {
        context.commit('addNewMethodList', response.data);
        context.commit('resetPaymentMethod');
        Vue.toasted.global.infoMessage(i18n.t('toasted.info.paymentMethodCreated'));
        return true
      }).catch(error => {
        return false
      });
    }
    context.commit('changeLoading', {
      sendingNewMethod: false,
    });

    return success;
  },
  async fnApiCreatePayment(context) {
    let {
      currency,
      payment,
      method,
      balances_id,
      bank,
      business_name,
    } = context.state.newPayment;
    let tempPayment = {
      currency,
      balances_id,
      payment_method_id: method
    }
    context.commit('changeLoading', {
      sendingNewPayment: true,
    });
    if (bank) {
      let tempAccount = context.state.methodList.find(item => item.id == method);
      tempAccount.bank = bank;
      await context.dispatch('fnApiAddNewMethod', tempAccount);
    }
    if (business_name) {
      let tempAccount = context.state.methodList.find(item => item.id == method);
      tempAccount.business_name = business_name;
      await context.dispatch('fnApiAddNewMethod', tempAccount);
    }
    await axiosShopify.post('payouts', tempPayment).then(response => {
      context.commit('addNewPaymentList', response.data);
      context.commit('deleteBalancesIds', balances_id);
      context.commit('resetNewPayment');
      context.dispatch('fnApiGetBalancesSummary');
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.paymentCreated'));
    }).catch(error => {});
    context.commit('changeLoading', {
      sendingNewPayment: false,
    });
  },
  
  // GET DATA
  fnApiGetMthods(context, store) {
    axiosShopify.get('me/payments/method').then(response => {
      context.commit('setMethodList', response.data);
    }).catch(error => {});
  },
  fnApiGetBanks(context, store) {
    axiosShopify.get('banks').then(response => {
      context.commit('setBanks', Vue.prototype.$lodash.sortBy(response.data, ['name']));
    }).catch(error => {});
  },
  async fnApiGetBalances(context) {
    axiosShopify.get('balances').then(response => {
      context.commit('setBalancesList', response.data);
    }).catch(error => {});
  },
  async fnApiGetPayouts(context) {
    context.commit('changeLoading', {
      paymentsList: true
    });
    await axiosShopify.get('payouts').then(response => {
      context.commit('setPaymentList', response.data);
    }).catch(error => {});
    context.commit('changeLoading', {
      paymentsList: false
    });
  },
  fnApiGetBalancesSummary(context) {
    axiosShopify.get('balances/summary').then(response => {
      context.commit('setBalancesSummary', response.data);
    }).catch(error => {});
    context.dispatch('fnApiGetExchangesAvailable');
  },
  fnApiGetExchangesAvailable(context) {
    axiosShopify.get('payouts/exchanges/available').then(response => {
      context.commit('setExchangesAvailable', response.data);
      context.dispatch('fnApiGetExchange');
    }).catch(error => {});
  },
  fnApiGetExchange(context) {
    if( context.state.newPayment.currency  != null){
      axiosShopify.get(`payouts/exchanges?base=${context.state.newPayment.currency}`).then(response => {
        context.commit('setExchangesList', response.data.exchanges);
      }).catch(error => {});
    }
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}