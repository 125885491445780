<template>
  <div id="app" class="h-100">
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N6FGXLV" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
    </noscript>

    <transition name="fadeIn" enter-active-class="animated fadeIn h-100">
      <router-view class="h-100"></router-view>
    </transition>

  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
  } from "vuex";
  export default {
    name: 'app',
    data() {
      return {
        show: true
      }
    },
    computed: {
      ...mapState(['showLoader','language']),
    },
    watch: {
      language() {
        this.$i18n.locale = this.language;
      },
      $route(to, from) {
        this.handleResize();
      }
    },
    methods: {
      ...mapMutations(['handleResize', 'setIntegration','setLanguage']),
      ...mapMutations('menu', ['setLoader']),
      ...mapMutations('EcartNotifications', ['resetNotifications']),
      ...mapActions('EcartNotifications', ['fnApiGetPendingNotificationsCount', 'fnApiGetPendingNotifications']),

      ...mapMutations('session', ['readLocalSession']),
      ...mapActions('session', ['fnGetUserInfo']),

      timeOut() {
        setTimeout(() => {
          this.setLoader(false);
        }, 500);
      },

      fnGetLanguage() {
        let tempLanguage = localStorage.getItem('language');
        if (tempLanguage == null) {
          localStorage.setItem('language', 'en');
        } else {
          this.setLanguage(tempLanguage);
        }
      }
    },
    sockets: {
      connect() {
        this.fnApiGetPendingNotificationsCount();
        this.fnApiGetPendingNotifications();
      },
      disconnect() {
        this.resetNotifications();
      },
    },
    async beforeCreate() {
      
    },
    async created() {
      this.readLocalSession();
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      await this.fnGetUserInfo();
    },
    async beforeMount() {
      if (this.$route.query.integration) {
        this.setIntegration(true);
      }
    },
    mounted() {
      this.fnGetLanguage();
      this.timeOut();
    },


  }
</script>