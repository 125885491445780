import Vue from 'vue';
import router from '@/router'

import {
  EcUserModel
} from "@/plugins/beans.js"

const state = {
  userShopify: EcUserModel(),
  loading: {
    data: false,
  },
  showError: {
    email: false,
    activation: false,
  }
}

// getters
const getters = {}
// mutations
const mutations = {
  setShopifySession(state, session) {
    state.userShopify = session;
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
};
// actions
const actions = {

  async fnApiShopifyLogin(context, userData) {
    let {
      email,
      password,
      storeId,
      redirectTo
    } = userData;
    let tempUser = {
      email,
      password,
      store_id: storeId,
    }
    context.commit('changeLoading', {
      data: true,
    });
    await axiosShopify.post('login', tempUser).then(async response => {
      localStorage.setItem('tokenShopify', response.data.token);
      if (redirectTo) {
        await context.dispatch('fnShopifyGetUserInfo');
        router.push({
          path: redirectTo,
        }, () => {});
      }
    }).catch(error => {
      switch (error.response.status) {
        case 401:
          this.showAlert = true;
          break;
        case 403:
          this.showAlertActivation = true;
          break;
      }
    });
    context.commit('changeLoading', {
      data: false,
    });
  },

  async fnApiShopifySignup(context, userData) {
    context.commit('changeLoading', {
      data: true,
    });
    let tempUser = {
      email: userData.email,
      first_name: userData.name,
      last_name: userData.lastName,
      phone: userData.phone,
      code: userData.code,
      country: userData.country,
      password: userData.password,
      store_id: userData.storeId,
    }
    await axiosShopify.post('register/account', tempUser).then(async response => {
      await context.dispatch('fnApiShopifyLogin', userData);
    }).catch(error => {
      if (error.response.data.statusCode == 422) {

      }
    });
    context.commit('changeLoading', {
      data: false,
    });
  },

  async fnShopifyGetUserInfo(context) {
    await axiosShopify.get('me').then(response => {
      localStorage.setItem('userShopify', JSON.stringify(response.data));
      context.commit('setShopifySession', response.data);
    });
  },
  fnShopifyLogout(context, link) {
    localStorage.removeItem('userShopify');
    localStorage.removeItem('tokenShopify');
    context.commit('setShopifySession', )
    sessionStorage.removeItem('hideMenu');
    if (link) {
      router.push(link, () => {});
    } else {
      router.push('/shopify', () => {});
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}