import Vue from "vue";
import Vuex from "vuex";
import {
  EcUserModel
} from "@/plugins/beans.js";
// Ecart
import layout from './modules/layout'
import menu from './modules/menu'
import session from './modules/session'
import syncStore from './modules/syncStore';
import discounts from './modules/discounts';
import product from './modules/product';
import products from './modules/products';
import payments from './modules/payments';
import stores from './modules/stores';
import order from './modules/order';
import pickups from './modules/pickups';
import dashboard from './modules/dashboard';
import productReviews from './modules/productReviews';
import productQuestions from './modules/productQuestions';
import EcartNotifications from './modules/EcartNotifications';
import EcartProducts from './modules/EcartProducts';
import EcartCarriers from './modules/EcartCarriers';
import EcartStore from './modules/EcartStore';
import EcartQuestion from './modules/EcartQuestion';
import EcartReview from './modules/EcartReview';
import EcartProduct from './modules/EcartProduct';
import EcartNewCart from './modules/EcartNewCart';
import EcartOrders from './modules/EcartOrders';
import EcartWebSiteEditor from './modules/EcartWebSiteEditor';


import AccountLogin from './accounts/AccountLogin';



// Shopify
import ShopifyProducts from './shopify/shopify_products_store';
import ShopifyProduct from './shopify/shopify_product_store';
import ShopifyOrders from './shopify/shopify_orders_store';
import ShopifyOrder from './shopify/shopify_order_store';
import ShopifyStore from './shopify/shopify_store_store';
import ShopifySession from './shopify/shopify_session_store';
import ShopifyPayments from './shopify/shopify_payments_store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuList: [{
        title: "dashboard",
        path: "/dashboard/dashboard",
        icon: "tachometer-alt",
      },
      {
        title: 'stores',
        path: "/dashboard/stores",
        icon: "store",
      },
      {
        title: 'products',
        path: "/dashboard/products",
        icon: "archive",
      },
      {
        title: 'orders',
        path: "/dashboard/orders",
        icon: "file-alt",
      },
      {
        title: 'reviews',
        path: "/dashboard/questions",
        icon: "comments",
      },
      {
        title: 'discounts',
        path: "/dashboard/discounts",
        icon: "percent",
      },
      {
        title: 'pickups',
        path: "/dashboard/pickups",
        icon: "cube",
      },
      {
        title: 'shipping',
        path: "/dashboard/shipping",
        icon: "truck",
      },
      {
        title: 'payments',
        path: "/dashboard/payments",
        icon: "credit-card",
      },

      // {
      //   title: "Account",
      //   icon: "user",
      //   path: "/dashboard/account"
      // },
      // {
      //   title: "Logout",
      //   path: "/logout",
      //   icon: "log-out",
      // }
    ],
    ordersPendingCount: null,
    userSession: EcUserModel(),
    device: null,
    integration: false,
    mobileVersion: false,
    deviceToken: null,
    newDevice: true,
    language: 'en',
    deviceInfomration: {
      id: null,
      token: null,
    },
  },
  modules: {
    // ECART
    layout,
    menu,
    session,
    syncStore,
    product,
    products,
    discounts,
    payments,
    stores,
    order,
    pickups,
    dashboard,
    productReviews,
    productQuestions,
    EcartNotifications,
    EcartProducts,
    EcartCarriers,
    EcartStore,
    EcartQuestion,
    EcartReview,
    EcartProduct,
    EcartNewCart,
    EcartOrders,
    EcartWebSiteEditor,

    // Accounts
    AccountLogin,

    // SHOPIFY
    ShopifyProducts,
    ShopifyProduct,
    ShopifyOrders,
    ShopifyOrder,
    ShopifyStore,
    ShopifySession,
    ShopifyPayments,
  },
  mutations: {
    setOrdersCount(state, count) {
      state.ordersPendingCount = count;
    },
    setUserSession(state, userSession) {
      state.userSession = userSession;
    },
    setDevice(state, device) {
      state.device = device;
    },
    setDeviceToken(state, deviceToken) {
      state.deviceToken = deviceToken;
    },

    setNewDevice(state, deviceInfomration) {
      if (deviceInfomration.id != null) {
        state.deviceInfomration = {
          ...deviceInfomration
        };
        state.newDevice = false;
      } else {
        state.deviceInfomration = {
          id: null,
          token: null
        };
        state.newDevice = true;
      }

      try {
        UserSession.postMessage(JSON.stringify({
          token: localStorage.getItem('token'),
          user: state.userSession,
        }));
      } catch (err) {
        console.log(err);
      }

    },
    handleResize(state) {
      if (window.innerWidth < 767) {
        state.mobileVersion = true;
      } else {
        state.mobileVersion = false;
      }
    },
    setIntegration(state, integration) {
      state.integration = integration;
    },
    setLanguage(state, lang) {
      state.language = lang;
    },

    setLanguage(state, lang) {
      if (lang) {
        localStorage.setItem('language', lang);
        state.language = lang;
      } else {
        localStorage.setItem('language', 'en');
        state.language = 'en';
      }
    }

  },
  actions: {

    async fnApiDeviceToken({
      state,
      commit
    }) {
      if (state.newDevice && state.device && state.deviceToken) {
        await axios.post(`devices/device`, {
          token: state.deviceToken,
          os: state.device,
        }).then((response) => {
          commit('setNewDevice', response.data);
        }).catch(error => {});
      }
    },

    async globalAction(context) {},

    async fnWSConnect(context) {
      if (context.state.session.user.id && !Vue.prototype.$socket.connected) {
        Vue.prototype.$socket.io.opts.query = {
          seller_id: context.state.session.user.id,
          token: context.state.session.token,
        }
        await Vue.prototype.$socket.open();
      }
    },
    async fnWSDisconnect() {
      await Vue.prototype.$socket.close();
    },
  },
  // strict: process.env.NODE_ENV !== 'production',
});