function data() {
  return {
    CONST: {
      CAPTCHACODE: process.env.VUE_APP_RECAPTCHA,
      ACCOUNTSURL: process.env.VUE_APP_ACCOUNTS,
      TODAY: this.$moment().format(),
      UNITS: {
        LENGTH: 'cm',
        WEIGHT: 'kg',
      },
      ECART: {
        STORE: process.env.VUE_APP_ECART_STORE,
        PRODUCT: process.env.VUE_APP_ECART_STORE + 'p/',
        LINK: process.env.VUE_APP_ECART_LINK,
      },
      TOAST_VARIANTS: {
        INFO: 'info',
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        DANGER: 'danger',
        WARNING: 'warning',
        SUCCESS: 'success',
      },
      MODAL_VARIANTS: {
        INFO: 'info',
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        DANGER: 'danger',
        WARNING: 'warning',
        SUCCESS: 'success',
      },
      MESSAGES: {
        OPTION_DEFAULT: 'Select'
      },
      DATE_FORMATS: {
        DD_MM_YYYY: 'DD-MM-YYYY',
        YYYY_MM_DD: 'YYYY-MM-DD',
        HH_MM_SS: 'HH:mm:ss',
        HH_mm: 'HH:mm',
        HH: 'HH',
      },
      // STATUS
      STATUS: {
        pending: {
          name: "pending",
          class: "text-warning",
        },
        'ready to ship': {
          name: "ready to ship",
          class: "text-info",
        },
        paid: {
          name: "paid",
          class: "text-info",
        },
        shipped: {
          name: "shipped",
          class: "text-success",
        },
        delivered: {
          name: "delivered",
          class: "text-success",
        },
        information: {
          name: "information",
          class: "text-info",
        },
        cancelled: {
          name: "cancelled",
          class: "text-danger",
        },
        'N/A': {
          name: "N/A",
          class: "text-info",
        },
        'picked up': {
          name: "picked up",
          class: "text-info",
        },
        'out for delivery': {
          name: "out for delivery",
          class: "text-info",
        },
        'in process': {
          name: "in process",
          class: "text-info",
        },
        open: {
          name: "open",
          class: "text-info",
        },
        available: {
          name: "available",
          class: "text-success",
        },
        ORDER: [{
          name: 'Pending',
          value: 'pending'
        }, {
          name: 'Paid',
          value: 'paid'
        }, {
          name: 'Cancelled',
          value: 'cancelled'
        }],
      },
      LANGUAGES: [{
          "value": "en",
          "text": "English",
          ico: 'us'
        },
        {
          "value": "es",
          "text": "Español",
          ico: 'mx'
        },
      ],
      // PAGINATION
      PAGINATION: [50],
      //  VUE-EDITOR 
      CUSTOMTOOLBAR: [
        ["bold", "italic", "underline"],
        [{
          list: "ordered"
        }, {
          list: "bullet"
        }]
      ],
      // REGEX
      REGEX: {
        URL: /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/,
      },

      BANKS: [
        'ABC CAPITAL',
        'AFIRME',
        'AMERICAN EXPRESS',
        'ANÁHUAC',
        'ATLÁNTICO',
        'AZTECA',
        'BAJÍO',
        'BANAMEX',
        'BANCEN',
        'BANCO FAMSA',
        'BANCO MULTIVA',
        'BANCOPPEL',
        'BANCREA',
        'BANK NEW YORK',
        'BANK OF CHINA',
        'BANK ONE',
        'BANKAOOL',
        'BANORTE/IXE',
        'BANPAÍS',
        'BANREGIO',
        'BANSI',
        'BARCLAYS',
        'BBVA BANCOMER',
        'BBVA SERVICIOS',
        'BICENTENARIO',
        'BM ACTINVER',
        'BNP',
        'BOSTON',
        'CAPITAL',
        'CHIHUAHUA',
        'CIBANCO',
        'CITIBANK',
        'COMPARTAMOS',
        'CONSUBANCO',
        'CREMI',
        'DEUTSCHE',
        'DONDÉ',
        'FINTERRA',
        'FORJADORES',
        'FUJI',
        'GE MONEY',
        'HSBC',
        'INBURSA',
        'INDUSTRIAL',
        'ING',
        'INMOBILIARIO',
        'INTERACCIONES',
        'INTERBANCO',
        'INTERCAM BANCO',
        'INVESTA BANK',
        'INVEX',
        'JP MORGAN',
        'MIFEL',
        'MONEX',
        'NATIONSBANK',
        'ORIENTE',
        'PAGATODO',
        'PROMEX',
        'QUADRUM',
        'SANTANDER',
        'SCOTIABANK INVERLAT',
        'SERFIN',
        'SOCIÉTÉ',
        'SURESTE',
        'TOKYO',
        'UBS BANK',
        'UNIÓN',
        'VE POR MÁS',
        'VOLKSWAGEN',
        'WAL-MART'
      ],
      CURRENCIES: [
        'USD',
        'MXN',
        'COP',
        'AED',
        'AFN',
        'ALL',
        'AMD',
        'ANG',
        'AOA',
        'ARS',
        'AUD',
        'AWG',
        'AZN',
        'BAM',
        'BBD',
        'BDT',
        'BGN',
        'BHD',
        'BIF',
        'BMD',
        'BND',
        'BOB',
        'BRL',
        'BSD',
        'BTN',
        'BWP',
        'BYN',
        'BZD',
        'CAD',
        'CDF',
        'CHF',
        'CLP',
        'CNY',
        'CRC',
        'CUC',
        'CUP',
        'CVE',
        'CZK',
        'DJF',
        'DKK',
        'DOP',
        'DZD',
        'EGP',
        'ERN',
        'ETB',
        'EUR',
        'FJD',
        'FKP',
        'GBP',
        'GEL',
        'GGP',
        'GHS',
        'GIP',
        'GMD',
        'GNF',
        'GTQ',
        'GYD',
        'HKD',
        'HNL',
        'HRK',
        'HTG',
        'HUF',
        'IDR',
        'ILS',
        'IMP',
        'INR',
        'IQD',
        'IRR',
        'ISK',
        'JEP',
        'JMD',
        'JOD',
        'JPY',
        'KES',
        'KGS',
        'KHR',
        'KMF',
        'KPW',
        'KRW',
        'KWD',
        'KYD',
        'KZT',
        'LAK',
        'LBP',
        'LKR',
        'LRD',
        'LSL',
        'LYD',
        'MAD',
        'MDL',
        'MGA',
        'MKD',
        'MMK',
        'MNT',
        'MOP',
        'MRU',
        'MUR',
        'MVR',
        'MWK',
        'MYR',
        'MZN',
        'NAD',
        'NGN',
        'NIO',
        'NOK',
        'NPR',
        'NZD',
        'OMR',
        'PAB',
        'PEN',
        'PGK',
        'PHP',
        'PKR',
        'PLN',
        'PYG',
        'QAR',
        'RON',
        'RSD',
        'RUB',
        'RWF',
        'SAR',
        'SBD',
        'SCR',
        'SDG',
        'SEK',
        'SGD',
        'SHP',
        'SLL',
        'SOS',
        'SPL',
        'SRD',
        'STN',
        'SVC',
        'SYP',
        'SZL',
        'THB',
        'TJS',
        'TMT',
        'TND',
        'TOP',
        'TRY',
        'TTD',
        'TVD',
        'TWD',
        'TZS',
        'UAH',
        'UGX',
        'UYU',
        'UZS',
        'VEF',
        'VND',
        'VUV',
        'WST',
        'XAF',
        'XCD',
        'XDR',
        'XOF',
        'XPF',
        'YER',
        'ZAR',
        'ZMW',
        'ZWD',
      ],
      CURRENCIES_LIST: {
        MXN: {
          flag: 'flag-icon-mx'
        },
        USD: {
          flag: 'flag-icon-us'
        },
        COP: {
          flag: 'flag-icon-co'
        },
      }

    }

  }
}


export default data;