import Vue from 'vue';
import i18n from '@/i18n'
import router from "@/router";
import {
  EcStoresPagination
} from "@/plugins/beans.js"

const state = {
  storeList: [],
  logoList: [],
  ecommerceList: [],
  loadingData: true,
  showStoresList: false,
  integration: false,
  pagination: EcStoresPagination(),
  modal: {
    newStore: false,
  },
  loading:{
    createStore: false,
  }
}

// getters
const getters = {
  getSyncStores(state) {
    return state.syncStoresList;
  },
  getStoresList(state) {
    state.storeList.map(store => {
      if (store.image == null) {
        if (store.ecommerce == 'Ecart') {
          store.image = require('@/assets/images/ecart-ecommerce.png');
        } else {
          let tempLogo = state.logoList.find(logo => logo.name == store.ecommerce);
          store.image = tempLogo.image;
        }
      }
    });
    return state.storeList;
  }

}

// mutations
const mutations = {
  setStoreList(state, list) {
    state.storeList = list;
  },
  setLogoList(state, list) {
    state.logoList = list;
  },
  setEcommerceList(state, list) {
    state.ecommerceList = list;
  },
  resetPagination(state) {
    state.pagination = EcStoresPagination();
  },
  changeLoadingData(state, status) {
    state.loadingData = status;
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  setPage(state, page) {
    state.pagination.page = page;
  },
  setTotal(state, total) {
    state.pagination.total = total;
  },
  setIntegration(state, status) {
    state.integration = status;
  },
  setShowStoresList(state) {
    state.showStoresList = !state.showStoresList;
  },
  changeModal(state, modal) {
    state.modal = {
      ...state.modal,
      ...modal
    };
  },
};

// actions
const actions = {

  fnFirstPagination(context) {
    context.dispatch('fnApiGetLogos');
    context.dispatch('fnApiGetStoreEcommerces');
    context.commit('resetPagination');
    context.dispatch('fnApiGetCountStores');
    context.dispatch('fnApiGetStores');
  },

  fnResetPagination(context) {
    context.commit('setPage', 1);
    context.dispatch('fnApiGetCountStores');
    context.dispatch('fnApiGetStores');
  },

  async fnApiGetStores(context) {
    let {
      name,
      ecommerce,
      active,
      limit,
      page
    } = {
      ...context.state.pagination
    };
    let tempPagination = {
      page,
      limit
    };
    if (name != null && name != '') {
      tempPagination.name = name
    };
    if (ecommerce != null && ecommerce != '') {
      tempPagination.ecommerce = ecommerce
    };
    if (active !== null && active !== '') {
      tempPagination.active = active
    };
    router.replace({
      query: {
        ...router.currentRoute.query,
        ...tempPagination,
      }
    }).catch(err => {})
    context.commit('changeLoadingData', true);
    context.commit('setStoreList', []);
    await axios.get('stores', {
      params: tempPagination
    }).then((response) => {
      context.commit('setStoreList', response.data);
    }).catch();
    context.commit('changeLoadingData', false);
  },

  fnApiGetCountStores(context) {
    let {
      name,
      ecommerce,
      active
    } = {
      ...context.state.pagination
    };
    let tempPagination = {};
    if (name != null && name != '') {
      tempPagination.name = name
    };
    if (ecommerce != null && ecommerce != '') {
      tempPagination.ecommerce = ecommerce
    };
    if (active !== null && active !== '') {
      tempPagination.active = active
    };
    axios.get('stores/count', {
      params: tempPagination
    }).then((response) => {
      context.commit('setTotal', response.data.count);
    }).catch((error) => {});
  },

  fnApiGetStoreEcommerces(context) {
    axios.get('stores/ecommerces').then((response) => {
      context.commit('setEcommerceList', Vue.prototype.$lodash.sortBy(response.data, ['id']));
    }).catch();
  },

  async fnApiGetLogos(context) {
    let tempLogoList = localStorage.getItem('ecommerces');
    if (tempLogoList == undefined) {
      await axios.get('../ecommerces').then((response) => {
        tempLogoList = Vue.prototype.$lodash.sortBy(response.data, ['name']);
        context.commit('setLogoList', tempLogoList);
        localStorage.setItem('ecommerces', JSON.stringify(tempLogoList));
      }).catch();
    } else {
      context.commit('setLogoList', JSON.parse(tempLogoList));
    }
  },

  async fnApiChangeStatusStore(context, index) {
    return await axios.put(`stores/${ context.state.storeList[index].id }`, {
      active: !context.state.storeList[index].active
    }).then((response) => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
      context.state.storeList[index].active = !context.state.storeList[index].active;
    }).catch(error => error);
  },

  async fnApiCreateStore(context) {
    context.commit('changeLoading', {
      createStore: true,
    });
    await axios.get(`stores/integration/Ecart?seller_id=${context.rootState.userSession.id}`).then((response) => {
      if (response.request.responseURL.split('stores/')[1]) {
        router.push('/dashboard/stores/' + response.request.responseURL.split('stores/')[1]);
      }
    }).catch((error) => {});
    context.commit('changeLoading', {
      createStore: false,
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}