import Vue from 'vue';

const state = {
  productsList: [],
  loading: {
    productList: false,
  },
  pagination: {
    storeId: null,
    name: null,
    active: null,
    page: 1,
    limit: 255,
    total: null,
  },
}

// getters
const getters = {
  getSyncStores(state) {
    return state.syncStoresList;
  }
}

// mutations
const mutations = {
  changeLoadingProductList(state, status) {
    state.loading.productList = status;
  },
  setProductsList(state, productsList) {
    state.productsList = productsList;
  },
};

// actions
const actions = {

  async fnApiGetProducts(context) {
    context.commit('changeLoadingProductList', true);
    let {
      name,
      storeId,
      active,
      limit,
      page
    } = {
      ...context.state.pagination
    };
    let tempPagination = {
      page,
      limit
    };
    if (name != null && name != '') {
      tempPagination.name = name
    };
    if (storeId != null && storeId != '') {
      tempPagination.store_id = storeId
    };
    if (active !== null && active !== '') {
      tempPagination.active = active
    };
    await axios.get('products', {
      params: tempPagination
    }).then(async (response) => {
      if (response.data && response.data.length > 0) {
        for (let index in response.data) {
          response.data[index].show = false;
          response.data[index].selected = false;
          if (!response.data[index].category) {
            response.data[index].category = {
              id: null,
            }
          }
          if (response.data[index].variants.length > 0) {
            let tempVariants = [];
            for (let variant of response.data[index].variants) {
              if (variant.name != 'Default Title') {
                tempVariants.push(variant);
              }
            }
            response.data[index].variants = tempVariants;
          }
        }
      }
      context.commit('setProductsList', response.data);
    }).catch(error => {});
    context.commit('changeLoadingProductList', false);
  },

  fnApiChangeStatusProduct(context, index) {
    axios.put(`products/${ context.state.productList[index].id }`, {
      active: !context.state.productList[index].active
    }).then((response) => {
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
    }).catch((error) => {
      this.productList[index].active = !this.productList[index].active;
      if (error.response.data.message = 'The product is out stock.') {
        this.modalSellOutOfStock = true;
        this.tempProductIndex = index;
      }
    });
  },
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}