import Vue from 'vue';
import i18n from '@/i18n';

// import {  EcOrderModel } from "@/plugins/beans.js"

const state = {
  reviewsPagination: {
    productId: null,
    qualification: null,
    page: 1,
    limit: 10,
    total: null,
  },
  loading: {
    data: false,
  },
  reviewsList: [],
}
// getters
const getters = {

}

// mutations
const mutations = {
  
  SOCKET_products(state, data) {
    if (data.event_type == 'review' ) {
      data.review.product = null;
      if (state.reviewsPagination.productId == null) {
        state.reviewsList.unshift(data.review);
      } else if (state.reviewsPagination.productId == data.reference_id) {
        state.reviewsList.unshift(data.review);
      } else {
      }
    }
  },

  setReviewsList(state, reviewsList) {
    state.reviewsList = reviewsList;
  },

  setProductId(state, productId) {
    state.reviewsPagination.productId = productId;
  },
  resetPagination(state) {
    state.reviewsPagination.productId = null;
    state.reviewsPagination.qualification = null;
    state.reviewsPagination.page = 1;
    state.reviewsPagination.total = null;
  },
  firstPagination(state) {
    state.reviewsPagination.page = 1;
    state.reviewsPagination.total = null;
  },
  setTotal(state,total) {
    state.reviewsPagination.total = total;
  },

  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },

};

// actions
const actions = {

  async fnFirstPagination(context) {
    context.commit('firstPagination');
    context.dispatch('fnApiGetReviews');
    context.dispatch('fnApiGetCountReviews');
  },
  async fnResetPagination(context, productId) {
    context.commit('setReviewsList', []);
    context.commit('resetPagination');
    productId != null ? context.commit('setProductId', productId) : null;
    context.dispatch('fnApiGetReviews');
    context.dispatch('fnApiGetCountReviews');
  },

  async fnApiGetReviews(context) {
    let {
      productId,
      qualification,
      limit,
      page
    } = {
      ...context.state.reviewsPagination
    };
    let tempPagination = {
      page,
      limit
    };
    (productId != null && productId != '') ? tempPagination.product_id = productId: null;
    (qualification !== null && qualification !== '') ? tempPagination.qualification = qualification: null;
    context.commit('changeLoading', {
      data: true
    });
    
    context.commit('setReviewsList', []);
    
    await axios.get(`products/reviews`, {
      params: tempPagination
    }).then((response) => {
      response.data.map(review => {
        review.product = null;
      })
      context.commit('setReviewsList', response.data);
      setTimeout(() => {
        document.getElementById("app").scrollIntoView({
          block: 'start',
          behavior: "smooth"
        });
      }, 500);
    }).catch((error) => {});
    context.commit('changeLoading', {
      data: false
    });
  },

  fnApiGetCountReviews(context) {
    let {
      productId,
      qualification,
    } = {
      ...context.state.reviewsPagination
    };
    let tempPagination = { };
    (productId != null && productId != '') ? tempPagination.product_id = productId: null;
    (qualification !== null && qualification !== '') ? tempPagination.qualification = qualification: null;
    axios.get('products/reviews/count', {
      params: tempPagination
    }).then((response) => {
      context.commit('setTotal', response.data.count);
    }).catch((error) => {});
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}