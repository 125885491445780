import Vue from 'vue'
import Toasted from 'vue-toasted';
import i18n from '@/i18n';



Vue.use(Toasted, {
  iconPack: 'fontawesome',
  icon: {
    name: 'info-circle',
    after: false,
  },
  theme: 'bubble',
  position: "top-right",
  fullWidth: false,
  // type: 'info',
  duration: 3000,
});

// Info
Vue.toasted.register('infoMessage', message => message, {
  icon: {
    name: 'info-circle',
    after: false,
  },
  type: 'info',
});
// Success
Vue.toasted.register('successMessage', message => message, {
  icon: {
    name: 'check-circle',
    after: false,
  },
  type: 'success',
});

// Error
Vue.toasted.register('errorMessage', message => message, {
  icon: {
    name: 'exclamation-triangle',
    after: false,
  },
  type: 'error',
});

// Error
Vue.toasted.register('notification', message => message, {
  icon: {
    name: 'bell',
    after: false,
  },
});