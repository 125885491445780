export const EcUserModel = function () {
    return {
        id: null,
        active: false,
        confirmated: false,
        complete: false,
        email: null,
        country: null,
        settings: {},
        seller_id: null,
        first_name: null,
        last_name: null,
        phone: null,
        store_id: null,
    }
};

export const EcStoreModel = function () {
    return {
        active: false,
        name: ' ',
        phone: null,
        email: null,
        description: ' ',
        image: null,
        image_url: null,
        settings: {
            commission: null,
            language: null,
        },
        currency: null,
        storeId: null,
        id: null,
        ecommerce: null,
        url: null,
        website: false,
        stadistics: {
            products: {},
            orders: {},
        }
    }
};
export const EcStoreAddressModel = function () {
    return {
        address_name: null,
        first_name: null,
        last_name: null,
        address1: null,
        address2: null,
        country: {
            code: null,
        },
        state: {
            code: null,
            name: null,
        },
        city: null,
        postal_code: null,
        phone: null,
    }
};
export const EcProductModel = function () {
    return {
        name: null,
        description: null,
        preparation_time: null,
        sales: null,
        views: null,
        language: null,
        sku: null,
        quantity: null,
        tags: [],
        dimensions: {
            width: null,
            height: null,
            length: null,
            weight: null,
        },
        discounts: [],
        attributes: [],
        variants: [],
        category: {
            id: null,
        },
        units: {
            height: "cm",
            length: "cm",
            weight: "kg",
            width: "cm"
        },
        active: false,
        condition: true,
        sell_out_stock: false,
        currency: '',
        images: [],
        tempImages: [],
        imageUrl: null,
    }
};

export const EcVariantModel = function () {
    return {
        sku: null,
        name: null,
        quantity: null,
        price: null,
        option1: null,
        option2: null,
        option3: null,
        dimensions: {
            width: null,
            height: null,
            length: null,
            weight: null,
        },
        units: {
            width: 'cm',
            height: 'cm',
            length: 'cm',
            weight: 'kg',
        },
    }
};


export const EcOrderModel = function () {
    return {
        id: null,
        totals: {},
        shipping_address: {
            country: {},
            state: {},
        },
        shipping_items: [{
            origin: {
                country: {
                    code: null
                },
            }
        }],
        items: [],
        charge: [],
    }
};
export const EcTrackingInformationModel = function () {
    return {
        content: {},
        status: '',
        tracking_number: null,
        event_history: [],
    }
};
export const EcStoresPagination = function () {
    return {
        name: null,
        ecommerce: null,
        active: null,
        page: 1,
        limit: 25,
        total: null,
    }
};

export const EcNewPickup = function () {
    return {
        storeId: null,
        addressId: null,
        origin: {
            company: null,
            email: null,
            address: {
                first_name: null,
                last_name: null,
                address1: null,
                address2: null,
                country: {
                    code: null,
                },
                state: {
                    code: null,
                    name: null,
                },
                city: null,
                postal_code: null,
                phone: null,
                reference: null,
            }
        },
        shipment: {
            carrier: null,
            pickup: {
                time_from: null,
                time_to: null,
                date: null,
                instructions: null,
                total_packages: null,
                total_weight: null,
            }
        }
    }
};

export const ShopifyStore = function () {
    return {
        active: false,
        name: ' ',
        phone: null,
        email: null,
        description: ' ',
        image: null,
        image_url: null,
        settings: {
            commission: null,
            language: null,
        },
        currency: null,
    }
}

// Orders


export const EcShippingInformationModel = function () {
    return {
        amount: null,
        base_amount: null,
        carrier: null,
        discount: {
            base: null,
            amount: null,
            percent: null
        },
        id: null,
        label: null,
        name: null,
        origin: {
            address1: null,
            address2: null,
            city: null,
            country: {
                code: null
            },
            first_name: null,
            last_name: null,
            phone: null,
            postal_code: null,
            state: {
                code: null
            },
        },
        own_shipping: false,
        packages: [{
            amount: null,
            content: null,
            declaredValue: null,
            dimensions: {
                length: null,
                width: null,
                height: null,
            },
            insurance: null,
            lengthUnit: null,
            type: null,
            weight: null,
            weightUnit: null,
        }],
        service: null,
        tracking_number: null,
        tracking_url: null,
    }
}

// Discounts


export const EcDiscountModel = () => {
    return {
        storeId: null,
        productsList: null,
        productsListExcluded: null,
        products: [],
        excludeProducts: [],
        carriers: [],
        type: 'coupons',
        amount: null,
        toAllStore: null,
        exclude: null,
        isPercent: false,
        isUnlimited: false,
        applyDates: false,
        name: null,
        coupon: null,
        available: 100,
        active: false,
        maxPurchase: null,
        minPurchase: null,
        currency: null,
        disabledDates: false,
        dates: {
            initial: null,
            finish: null,
        }
    }
}

// PAYEMNTS
export const EcPaymentBalancesSummary = () => {
    return {
        summary: {},
        available: {
            MXN: null,
            USD: null,
        },
        cancelled: {
            MXN: null,
            USD: null,
        },
        paid: {
            MXN: null,
            USD: null,
        },
    }
}
export const EcPaymentNewMethod = () => {
    return {
        method: 'paypal',
        name: null,
        email: null,
        clabe: null,
        number: null,
        bank: null,
        business_name: null,
    }
}

export const EcPaymentNewPayment = () => {
    return {
        currency: null,
        type: null,
        method: null,
        balances_id: [],
        bank: null,
        invoice: null,
        total: 0,
    }
}

export const EcPaymentNewInvoice = () => {
    return {
        id: null,
        key: null,
        business_name: null,
        reason: null,
        file: null,
    }
}

// Promotions

export const EcDiscountModelHotSale = () => {
    return {
        storeId: null,
        productsList: null,
        productsListExcluded: null,
        products: [],
        excludeProducts: [],
        carriers: [],
        type: 'products',
        amount: 50,
        toAllStore: true,
        exclude: false,
        isPercent: false,
        isUnlimited: true,
        applyDates: true,
        name: 'EcartHotSale',
        coupon: null,
        available: 100,
        active: false,
        maxPurchase: null,
        minPurchase: null,
        currency: null,
        disabledDates: true,
        dates: {
            initial: '1621746000000',
            finish: '1622437200000',
        }
    }
}


export const EcInfoLinkis = (vueApp) => {
    return [{
            title: 'policy',
            link: "/dashboard/privacy-policy",
            target: '_self'
        },
        {
            title: 'terms',
            link: "/dashboard/terms-&-conditions",
            target: '_self'
        },
        {
            title: 'faqs',
            link: "/dashboard/faqs",
            target: '_self'
        },
        {
            title: 'returns',
            link: "/dashboard/returns-and-cancellations",
            target: '_self'
        },
        {
            title: 'ecart',
            link: "https://ecart.com",
            target: '_blank'
        },
        {
            title: 'promotions',
            link: "/dashboard/promotions",
            target: '_self'
        },
    ];
}

export const EcCartModel = () => {
    return {
        created_at: null,
        currency: null,
        discounts: [],
        id: null,
        items: [],
        items_removed: [],
        reference_seller_id: null,
        seller_ids: [],
        shipping_items: [],
        status: null,
        store_ids: [],
        totals: {
            subtotal: 0,
            shipping: 0,
            tax: 0,
            discount: 0,
            total: 0
        },
        updated_at: null,
        user_id: null,
        email: null,
        currency: "USD",
        billing_address: EcStoreAddressModel(),
        shipping_address: EcStoreAddressModel(),
    }
};

export const EcNewOrderModel = () => {
    return {
        "id": null,
        "suborders": [],
        "status": null,
        "order_status": null,
        "notes": [],
        "purchase": false,
        "is_from_seller": true,
        "payments": [],
        "currency": null,
        "user_id": null,
        "items": [],
        "shipping_items": [],
        "discounts": [],
        "billing_address": EcStoreAddressModel(),
        "email": null,
        "shipping_address": EcStoreAddressModel(),
        "totals": {
            "tax": 0,
            "discount": 0,
            "shipping": 0,
            "subtotal": 0,
            "total": 0
        },
        "cart_id": null,
        "number": null,
        "first_name": null,
        "last_name": null,
        "phone": null,
    }
}

// ECAR Web Editor Models

export const EcWeBanner = (id) => {
    return {
        id: id,
        name: 'Banner',
        type: 'banner',
        image: null,
        image_url: null,
        width: 0,
        height: 200,
        bgColor: '#fff',
        bannerType: 'cover',
    }
}