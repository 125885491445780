import Vue from 'vue';
import i18n from '@/i18n';
import printJS from 'print-js';

import {
  EcOrderModel,
  EcStoreModel,
  EcTrackingInformationModel,
  EcShippingInformationModel,
} from "@/plugins/beans.js"

const defaultState = () => {
  return {
    modalCancelOrder: false,
    showtrackingNumber: false,
    sendingData: false,
    loadingData: false,
    showTraking: false,
    storeInformation: EcStoreModel(),
    orderInformation: EcOrderModel(),
    trackingInformation: EcTrackingInformationModel(),
    cancelReason: {
      comments: null,
      reason: null,
      charge: false,
      message: false,
    },
    shippingInformation: EcShippingInformationModel(),
    newShipping: EcShippingInformationModel(),
    addressesList: [],
    loading: {
      data: false,
      createTracking: false,
      charge: false,
    },
    orderCharge: {
      id: null,
    },
    carriersList: [],
    newTrackingType: null,
    orderInsurance: null,
    isComponent: false,
    pickupList: [],
    exchangesList: [],
  }
}
const state = defaultState();

// getters
const getters = {

}

// mutations
const mutations = {
  setOrderInformation(state, order) {
    state.orderInformation = {
      ...state.orderInformation,
      ...order
    };

    let tempIndex = state.orderInformation.charge.findIndex(charge => charge.charge_type == 'orders');
    if (tempIndex >= 0) {
      state.orderCharge = state.orderInformation.charge[tempIndex];
    }
  },

  setTrackingInformation(state, tracking) {
    state.trackingInformation = tracking;
  },
  changeLoadingData(state, status) {
    state.loadingData = status;
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  changeSendingData(state, status) {
    state.sendingData = status;
  },
  changeShowTracking(state, status) {
    state.showTraking = status;
  },
  setTotal(state, total) {
    state.pagination.total = total;
  },
  setModalCancelOrder(state, status) {
    state.modalCancelOrder = status;
    if (status) {
      setTimeout(() => {
        document.getElementById("OrderInformation").scrollIntoView({
          block: 'end',
          behavior: "smooth"
        });
      }, 200);
    }
  },
  setModalTracking(state, status) {
    state.showtrackingNumber = status;
  },
  resetCancelOrder(state, scroll) {
    state.cancelReason.reason = null;
    state.cancelReason.comments = null;
    state.cancelReason.message = false;
    state.cancelReason.charge = false;

    state.modalCancelOrder = false;
    if (scroll) {
      setTimeout(() => {
        document.getElementById("OrderInformation").scrollIntoView({
          alignToTop: false,
          behavior: "smooth"
        });
      }, 100);
    }
  },
  setCancelReason(state, reason) {
    state.cancelReason.reason = reason;
  },
  setOrderStatus(state, status) {
    state.orderInformation.order_status = status;
  },
  updateTrackingNumber(state, order) {
    state.orderInformation.shipping_items[0].label = order.label;
    if (!order.own_shipping) {
      state.orderInformation.tracking_url = order.tracking_url;
    }
    state.orderInformation.own_shipping = order.own_shipping;
    state.orderInformation.tracking_number = order.tracking_number;
    state.orderInformation.shipment_status = 'ready to ship';
    state.showtrackingNumber = !state.showtrackingNumber;
  },
  setShippingInformation(state, information) {
    state.shippingInformation = information;
    state.newShipping.id = information.id;
  },
  // Inprovements
  setStoreInformation(state, store) {
    state.storeInformation = store;
  },
  setAddressesList(state, list) {
    state.addressesList = list;
  },
  setCancelMessage(state, status) {
    state.cancelReason.message = status;
  },
  setOrderCharge(state, charge) {
    state.orderCharge = charge;
  },
  setCarriersList(state, carriers) {
    state.carriersList = carriers;
  },
  setPickupList(state, pickupList) {
    state.pickupList = pickupList;
  },
  setNewTrackingType(state, type) {
    state.newTrackingType = type;
    if (type) {
      document.getElementById("OrderInformation").scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
      });
    }
  },
  setExchangesList(state, exchanges) {
    state.exchangesList = exchanges;
  },
  setOrderInsurance(state, orderInsurance) {
    state.orderInsurance = orderInsurance;
  },

  resetState(state) {
    Object.assign(state, defaultState())
  },
};

// actions
const actions = {

  async fnApiGetOrder(context, orderId) {
    context.commit('changeLoading', {
      data: true,
    });
    context.commit('setOrderCharge', {
      id: null
    });
    context.commit('resetCancelOrder');
    context.commit('setNewTrackingType', null);

    await axios.get(`orders/${orderId}`).then(async (response) => {
      context.commit('setShippingInformation', response.data.shipping_items[0]);
      context.commit('setOrderInformation', response.data);
      context.dispatch('fnApiGetStoreInformation');
      context.dispatch('fnApiGetCarriers');

      if ((response.data.shipping_items.length > 0) && (response.data.shipping_items[0].tracking_number)) {
        if (!response.data.shipping_items[0].own_shipping) {
          context.dispatch('fnGetTraking');
          context.dispatch('fnApiGetPickupList');
        }
      } else {
        context.commit('setTrackingInformation', EcTrackingInformationModel());
      }

    }).catch();
    context.commit('changeShowTracking', false);
    setTimeout(() => {
      context.commit('changeLoading', {
        data: false,
      });
    }, 250)
  },

  async fnGetTraking(context) {
    await axios.get('orders/tracking?id=' + context.state.orderInformation.shipping_items[0].tracking_number).then((response) => {
      context.commit('setTrackingInformation', response.data[0]);
    }).catch();
  },

  async fnApiCancelOrder(context) {
    context.commit('changeLoading', {
      cancelOrder: true,
    });
    let tempCancel = {
      cancel_reason: context.state.cancelReason.reason.toLowerCase(),
      comments: context.state.cancelReason.comments,
    }
    if (state.cancelReason.message) {
      tempCancel.charge = context.state.cancelReason.charge;
    }
    await axios.post(`orders/${ context.state.orderInformation.id }/cancel`, tempCancel).then((response) => {
      context.commit('setOrderInformation', response.data);
      context.commit('resetCancelOrder');
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.orderCancelled'));
    }).catch((error) => {
      if (error.response.status == 405) {
        context.commit('setCancelMessage', true);
      }
    });
    context.commit('changeLoading', {
      cancelOrder: false,
    });
  },
  async fnUpdateOrderBOL(context, itemsList) {
    let tempItemsList = [];
    for (let item of itemsList) {
      tempItemsList.push({
        id: item.item_id,
        title: item.name
      });
    }
    context.commit('changeSendingData', true)
    await axios.post(`orders/${context.state.orderInformation.id}/shippings/${context.state.shippingInformation.id}/billoflading`,
      tempItemsList).then((response) => {
      if (process.env.NODE_ENV == 'development') {
        window.open(context.state.shippingInformation.bill_of_landing);
      } else {
        printJS(context.state.shippingInformation.bill_of_landing);
      }
    }).catch(error => {});
    context.commit('changeSendingData', false)
  },

  async fnGetOrderSummary(context) {
    await axios.get(`orders/summary/${context.state.orderInformation.id}`, {
      headers: this.headers,
      responseType: 'blob',
    }).then((response) => {
      var pdfFile = new Blob([response.data], {
        type: "application/pdf"
      });
      var pdfUrl = URL.createObjectURL(pdfFile);
      printJS(pdfUrl);
    }).catch(error => {});
  },

  fnApiGetStoreInformation(context) {
    axios.get(`stores/${context.state.orderInformation.store_id}`).then((response) => {
      context.commit('setStoreInformation', response.data);
      context.commit('setAddressesList', response.data.addresses);
    }).catch((error) => {});
  },

  async fnApiUpdateCharge(context, chargeId) {
    context.commit('changeLoading', {
      charge: true,
    });
    await axios.put(`charges/appeal/${chargeId}`, {
      status: "review"
    }).then((response) => {
      context.commit('setOrderCharge', response.data);
      Vue.toasted.global.infoMessage(i18n.t('toasted.info.orderAppeal'));
    }).catch((error) => {});
    context.commit('changeLoading', {
      charge: false,
    });
  },

  async fnApiCreateShippingLabel(context, addressesList) {

    let {
      carrier,
      service
    } = context.state.shippingInformation;
    let tempAddressesList = {
      carrier,
      service,
      origin_id: addressesList.origin_id,
    }
    if (addressesList.origin_address) {
      tempAddressesList.origin_address = addressesList.origin_address;
    }
    if (addressesList.shipping_address) {
      tempAddressesList.shipping_address = addressesList.shipping_address;
    }
    if (addressesList.insurance) {
      tempAddressesList.insurance = addressesList.insurance;
    }
    
    await axios.post(`orders/${context.state.orderInformation.id}/shippings/generate`, tempAddressesList).then((response) => {
      let tempOrder = {
        ...context.state.orderInformation,
        ...{
          shipment_status: 'ready to ship'
        }
      }
      if (addressesList.origin_address) {
        response.data.origin = addressesList.origin_address;
      }
      if (addressesList.shipping_address) {
        tempOrder.shipping_address = addressesList.shipping_address;
      }
      Vue.toasted.global.successMessage(i18n.t('toasted.success.trackingNumberCreate'));
      context.commit('setShippingInformation', response.data);
      context.commit('setOrderInformation', tempOrder);
      context.commit('setOrdersCount', context.rootState.ordersPendingCount - 1, {
        root: true
      });
      context.dispatch('fnApiGetPickupList');

    }).catch((error) => {});
    context.commit('changeLoading', {
      createTracking: false,
    });
  },

  async fnApiUpdateOwnTrackingNumber(context, trackingNumber) {
    context.commit('changeLoading', {
      createTracking: true,
    });
    await axios.put(`orders/${context.state.orderInformation.id}/shippings/${context.state.shippingInformation.id}`, trackingNumber).then((response) => {
      context.commit('setShippingInformation', response.data);
      context.commit('setNewTrackingType', null);
      let tempOrder = {
        ...context.state.orderInformation,
        ...{
          shipment_status: 'ready to ship'
        }
      }
      context.commit('setOrderInformation', tempOrder);
      Vue.toasted.global.successMessage(i18n.t('toasted.success.trackingNumberUpdated'));
    }).catch(error => {});
    setTimeout(() => {
      context.commit('changeLoading', {
        createTracking: false,
      });
    }, 2000);
  },
  //GETTERS
  fnApiGetCarriers(context) {
    axios.get(`carriers`, {
      params: {
        country: context.state.shippingInformation.origin.country.code
      }
    }).then((response) => {
      context.commit('setCarriersList', response.data);
    }).catch((error) => {});
  },

  fnApiGetPickupList(context) {
    axios.get(`pickups/pickup/${context.state.shippingInformation.carrier}`).then((response) => {
      context.commit('setPickupList', response.data);
    }).catch((error) => {});
  },
  async fnApiGetExchange(context) {
      await axios.get(`payouts/exchanges?base=MXN`).then(response => {
        context.commit('setExchangesList', response.data.exchanges);
      }).catch(error => {});
  },
  async fnApiGetInsurance(context) {
      await axios.post(`orders/${context.state.orderInformation.id}/shippings/insurance/rate`,{
        insurance: (context.state.orderInformation.totals.total / context.state.exchangesList[context.state.orderInformation.currency])
      }).then(response => {
        context.commit('setOrderInsurance', response.data[0]);
      }).catch(error => {});
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}