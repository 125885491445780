import {
    ShopifyStore
} from "@/plugins/beans.js";
import router from '@/router'

const state = {
    storeId: null,
    interval: null,
    loadingSync: false,
    storeInformation: ShopifyStore(),
    counts: {
        total: 0,
        online: 0,
        offline: 0,
    },
    loading: {
        data: false,
        disconnect: false,
    },
    modal: {
        disconnect: false,
    },
}

// getters
const getters = {}
// mutations
const mutations = {
    changeSyncStatus(state, status) {
        state.loadingSync = status;
    },
    setStoreInformation(state, information) {
        state.storeInformation = information;
    },
    changeLoading(state, status) {
        state.loading = {
            ...state.loading,
            ...status
        };
    },
    changeModal(state, status) {
        state.modal = {
            ...state.modal,
            ...status
        };
    },
    changeStoreStatus(state, status) {
        state.storeInformation.active = status
    },
    updateCount(state, status) {
        state.counts = {
            ...state.counts,
            ...status
        };
    },
    setStoreId(state, storeId) {
        state.storeId = storeId;
    }
};

// actions
const actions = {
    fnApiGetStore(context) {
        axiosShopify.get(`stores/${context.state.storeId}`).then(async (response) => {
            context.commit('setStoreInformation', response.data);
            context.dispatch('fnApiGetCount');
        }).catch(error => {});
    },
    fnApiSyncStore(context) {
        axiosShopify.post(`products/process?store_id=${context.state.storeId}`).then(async (response) => {
            context.commit('changeSyncStatus', true);
            context.state.interval = setInterval(() => {
                context.dispatch('fnApiSyncProcess', response.data.process_id);
            }, 1000);

        }).catch(error => {});
    },
    fnApiSyncProcess(context, process_id) {
        axiosShopify.get('../process/' + process_id).then(async (response) => {
            if (response.data.status != 'in process') {
                context.commit('changeSyncStatus', false);
                clearInterval(context.state.interval);
            }
        }).catch(error => {});
    },

    async fnApiStoreStatus(context) {
        context.commit('changeLoading', {
            disconnect: true,
        });
        await axiosShopify.put(`stores/${ context.state.storeInformation.id }`, {
            active: !context.state.storeInformation.active
        }).then((response) => {
            context.commit('changeModal', {
                disconnect: false,
            });
            context.commit('changeStoreStatus', !context.state.storeInformation.active);
        }).catch(error => {});
        context.commit('changeLoading', {
            disconnect: false,
        });
    },

    async fnApiGetCount(context) {
        // shopify/products/store_id=${ context.state.storeInformation.id }/count
        // shopify/products/{{store_id}}/count
        await axiosShopify.get(`shopify/products/${ context.state.storeInformation.id }/count`).then((response) => {
            context.commit('updateCount', {
                online: response.data.count,
            });
        }).catch(error => {});
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}