import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import VueSocketIO from 'vue-socket.io'
// App styles
Vue.use(() => import('@/assets/scss/after.scss'));

import i18n from './i18n'
import './plugins';


import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask)

Vue.use(new VueSocketIO({
  debug: (process.env.NODE_ENV == 'development') ? true : false,
  connection: process.env.VUE_APP_ECART_API_WS,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
  options: {
    autoConnect: false,
    query: {
      token: null,
      seller_id: null,
    }
  },
}));

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')